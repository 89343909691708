import { getBibleVersePending, getBibleVerseSuccess, getBibleVerseError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function getBibleVerse($chapter_id, $content_version, $locale, $isPrimaryContent) {

    let verseApi = APP_DOMAIN+"/getFullBibleVersesByChapterId/"+$chapter_id+"/"+$content_version+"/"+$locale;
    console.log(verseApi);

    return dispatch => {
        dispatch(getBibleVersePending());

        fetch(verseApi, SecurityHeader())
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(getBibleVerseSuccess(res, $content_version, $isPrimaryContent));
            return res;
        })
        .catch(error => {
            dispatch(getBibleVerseError(error));
        })
    }
}
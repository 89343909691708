import React, { Component } from 'react';
import cover1 from '../../images/bookCover1@2x.png'
import cover2 from '../../images/BookCover2@2x.png'
import cover3 from '../../images/BookCover3@2x.png'
import cover4 from '../../images/BookCover4@2x.png'
import cover5 from '../../images/BookCover5@2x.png'

import CloseIcon from '@material-ui/icons/Close';
import BookmarkBorderTwoToneIcon from '@material-ui/icons/BookmarkBorderTwoTone';
import BookmarkTwoToneIcon from '@material-ui/icons/BookmarkTwoTone';

class MagazineItem extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			isCollected: this.props.hasCollected
		};
		this.removeItem = this.removeItem.bind(this);
	}

	/*

 if( ( i >= 1 && i <= 5 ) || ( i == 66 ) ){ //type 1
      [colorTypes addObject:[NSNumber numberWithInt:1]];
    }
    else if( ( i >= 6 && i <= 17 ) || ( i >= 59 && i <= 65 )){ //type 2
      [colorTypes addObject:[NSNumber numberWithInt:2]];
    }
    else if( ( i >= 18 && i <= 22 ) || ( i >= 45 && i <= 58 ) ){ //type 3
      [colorTypes addObject:[NSNumber numberWithInt:3]];
    }
    else if( ( i >= 23 && i <= 27 ) || ( i == 44 ) ){ //type 4
      [colorTypes addObject:[NSNumber numberWithInt:4]];
    }
    else{ //type 5
      [colorTypes addObject:[NSNumber numberWithInt:5]];
    }


*/
	toggleCollection( shouldCollect ) {
		this.props.toggleCollection( this.props.id, shouldCollect );
		this.setState({ isCollected: shouldCollect });
	}

	removeItem() {
		this.props.cancelCollect(this.props.id);
	}

	render() {
		return (

			<div className="resourceItem" style={{ position: 'relative'}}>
				{( this.props.shouldShowCollectBtn && this.state.isCollected ) ? 
				 <BookmarkTwoToneIcon 
				 	className="collectBtn"
					style={{ position: 'absolute', top: '5px', right: '5px', color: '#155251'}} 
					fontSize="large" 
					onClick={() => this.toggleCollection(false)}
				  /> : 
				  ( this.props.shouldShowCollectBtn ) ?
				  <BookmarkBorderTwoToneIcon 
				  	className="collectBtn"
					style={{ position: 'absolute', top: '5px', right: '5px', color: '#155251'}} 
					fontSize="large" 
					onClick={() => this.toggleCollection(true)}
				  /> : ""
				}

				{( this.props.shouldShowCloseBtn ) ? 
					<CloseIcon 
						className="closeBtn"
						style={{ position: 'absolute', top: '5px', right: '5px', color: '#155251'}} 
						fontSize="large"  
						onClick={() => this.removeItem()}
					/> : ""
				} 

				<a target="_blank" href={this.props.url}>
					<div>
						<div className="cover">
							<img src={this.props.cover} /> 
						</div>
						<div className="desc" >
							<h1>{this.props.name}</h1>
							<p>{this.props.desc}</p>
						</div>
					</div>
				</a>
			</div>

			);


	}


}

export default MagazineItem;
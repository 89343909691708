
import React, { useState, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../images/logoWithoutText.png';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import './PlanPopup.css'; 

import { getValue, getLocalLocale } from "../../constant/Locale";

import cover from '../../images/BiblePlanCovers/5.jpg';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function StartPlanPopup( props ) {
  const classes = useStyles();

  const [selectedDuration, setSelectedDuration] = React.useState(0);
  const [planName, setPlanName] = React.useState("");
  const [planDesc, setPlanDesc] = React.useState("");

  const handleDateChange = (duration) => {
    setSelectedDuration(duration);
  };

  let menuItems = [];

  var totalNumberOfChapter = 0;

  for( var i=0; i<props.selectedBooks.length; i++ ) {
    var bookId = props.selectedBooks[i];
    var bookIndex = bookId - 1;

    if( bookIndex >= 0 )
      totalNumberOfChapter += props.bibleNames[bookIndex].chapterCnt;
  }

  if( totalNumberOfChapter == 0 )
    totalNumberOfChapter = 730;

  for( var i=0; i<totalNumberOfChapter; i++ ) {
    menuItems.push(
      <MenuItem value={i+1}>{i+1}</MenuItem>
    );  
  } 

  function handleDropdownChange(event) {
    handleDateChange(event.target.value);
  }

  return (
    <div className="customPlanRootDiv">
      <div className="bannerDiv customPlanDiv">
        <img style={{ minHeight: "240px" }} className="banner" src={cover} />
        <p>自選讀經計劃 (自選書卷)</p>
        <CloseIcon className="closeBtn" onClick={() => props.close()}/>
        <Grid container spacing={2} className="planNameGrid">
          <TextField 
            // error={(errors.includes("firstName")) ? true : false }
            autoComplete="fname"
            variant="outlined"
            required
            style={{marginLeft: '20px', marginBottom: "10px"}}
            label={getValue("planNameLabel")}
            className="planNameTextField"
            autoFocus
            // style={{ width: '80%' }}
            onChange={(event) => {
              setPlanName(event.target.value);
              // validateItem(event.target.value, "firstName");
            }
            }
            // helperText={(errors.includes("firstName")) ? errorMsgs["firstName"] : ""}
          />

          <TextField 
            // error={(errors.includes("firstName")) ? true : false }
            autoComplete="fdesc"
            variant="outlined"
            label={getValue("planDescLabel")}
            className="planDescTextField"
            style={{marginLeft: '20px'}}
            onChange={(event) => {
              setPlanDesc(event.target.value);
              // validateItem(event.target.value, "firstName");
            }
            }
            // helperText={(errors.includes("firstName")) ? errorMsgs["firstName"] : ""}
          />

        </Grid>
      </div>

      <Container component="main" maxWidth="xs" className="loginRegisterContainer">
          
          <div className={classes.paper} style={{ marginTop: '0px'}}>

            
            
            <Grid container spacing={2} style={{

                  display: 'flex',
                  'justify-content': 'center',
                  padding: '20px 0'                

            }}>
               <InputLabel id="demo-customized-select-label">{getValue("chooseDuration")}</InputLabel>
              <Select
               labelId="demo-customized-select-label"
               id="controlled-open-select"
               style={{ width: '100%', textAlign: 'center'}}
               onChange={(event) => handleDropdownChange(event, this)}
               >
                 {menuItems}
              </Select>
            </Grid>
            <Grid container spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ color: 'white', 'backgroundColor': '#0F514F', 'opacity': planName != "" && selectedDuration != 0 ? "1" : "0.6" }}
                className={classes.submit}

                onClick={() => {
                  if( planName != "" && selectedDuration != 0 ) 
                    props.createCustomPlanByBooks(planName, planDesc, selectedDuration, totalNumberOfChapter);
                }}

              >
                {getValue("startDraftPlan")}
              </Button>

            </Grid>
          </div>
         
        </Container>
    </div>
    
  );
}
import { getTopicSearchPending, getTopicSearchSuccess, getTopicSearchError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function getTopicsbyWords($word) {

    let api = APP_DOMAIN+"/getTopicsByWords";

    return dispatch => {
        dispatch(getTopicSearchPending());

        fetch(api, SecurityHeader({word: $word}))
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(getTopicSearchSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(getTopicSearchError(error));
        })
    }
}


import React, { Component } from 'react';
import bookCover1 from '../../images/BiblePlanBookCovers/btn_note_b1.png';
import bookCover2 from '../../images/BiblePlanBookCovers/btn_note_b2.png';
import bookCover3 from '../../images/BiblePlanBookCovers/btn_note_b3.png';
import bookCover4 from '../../images/BiblePlanBookCovers/btn_note_b4.png';
import bookCover5 from '../../images/BiblePlanBookCovers/btn_note_b5.png';
import tick from '../../images/tick.png';

import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import BookmarkBorderTwoToneIcon from '@material-ui/icons/BookmarkBorderTwoTone';
import BookmarkTwoToneIcon from '@material-ui/icons/BookmarkTwoTone';
import Checkbox from '@material-ui/core/Checkbox';

import CloseIcon from '@material-ui/icons/Close';

class BibleBookItem extends Component {

	constructor(props, context) {
		super(props, context);
	}

	handleCoverOnClick() {
		this.props.toggleChapter(this.props.chapterIndex);
	}

	render() {

		console.log(this.props.hasCollected);
		return (
			<div className="bookChapterDiv">
			  { (this.props.selected) ? 
			  <img src={tick} 
			  	   style={{
			  	   	 width: '20px',
			  	   	 position: 'absolute',
			  	   	 right: '0'

			  	   }}
			  /> : ""
			  }
	          {
				((this.props.bookId >= 1 && this.props.bookId <=5 ) || this.props.bookId == 66 ) ? 
				<img src={bookCover1} onClick={() => this.handleCoverOnClick() } /> : 
				((this.props.bookId >= 6 && this.props.bookId <=17 ) || ( this.props.bookId >= 59 && this.props.bookId <= 65 ))  ? 
				<img src={bookCover2} onClick={() => this.handleCoverOnClick() } /> :
				((this.props.bookId >= 18 && this.props.bookId <= 22 ) || ( this.props.bookId >= 45 && this.props.bookId <= 58 ))  ? 
				<img src={bookCover3} onClick={() => this.handleCoverOnClick() } /> :
				((this.props.bookId >= 23 && this.props.bookId <=27 ) || ( this.props.bookId == 44 ))  ? 
				<img src={bookCover4} onClick={() => this.handleCoverOnClick() } /> : 
				<img src={bookCover5} onClick={() => this.handleCoverOnClick() } /> 
			  }
	          <p onClick={() => this.handleCoverOnClick() }>{this.props.chapterIndex+1}</p>
	        </div>
		);


	}


}

export default BibleBookItem;

import React, { useState, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../images/logoWithoutText.png';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import './PlanPopup.css'; 

import { getValue, getLocalLocale } from "../../constant/Locale";

import cover from '../../images/BiblePlanCovers/5.jpg';
import BibleBookItem from './bibleBookItem';
import BibleBookChapterItem from './bibleBookChapterItem';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CustomPlanSelectBookChaptersPopup( props ) {
  const classes = useStyles();   

  const [selectedBook, setSelectedBook] = React.useState(0);
  const [chapters, setChapters] = React.useState([]);



  function generateBookNames() {
    var bookNames = [];

    props.bibleNames.map((bible, index) => (
      bookNames.push(
        <BibleBookItem 
          bookId={bible.bookId}
          bookName={bible.bookName}
          selected={ props.selectedChapters[index].length > 0 ? true : false  }
          opacity={ index == selectedBook ? false : true }
          toggleBook={(bookId) => toggleBook(bookId) }
        />
      )
    ));
    return bookNames;
  }

  function toggleBook(bookId) {
    if( selectedBook == bookId-1 ) {
      if( props.selectedChapters[bookId-1].length == props.bibleNames[bookId-1].chapterCnt ) {
        // select 0 chapter
        props.unsetAllChapters(bookId-1);
      }
      else if( props.selectedChapters[bookId-1].length >= 0 ) {
        // select all chapters
        props.setAllChapters(bookId-1);
      }
    }

    setSelectedBook(bookId-1);
  }

  function toggleChapter(chapterId) {
    props.toggleChapter(selectedBook, chapterId);
  }

  function isSelectedChaptersCntInRange() {
    var chapterCnt = 0;

    for( var i=0; i<66; i++ ) {
      chapterCnt += props.selectedChapters[i].length;
    }

    if( chapterCnt >= props.duration && chapterCnt <= props.duration * 130 ) {
      return true;
    }
    else {
      return false;
    }
  }

  function generateChapters() {
    var chapters = [];
    if( props.bibleNames[selectedBook] ) {
      for( var i=0; i<props.bibleNames[selectedBook].chapterCnt; i++ ) {
        chapters.push(
          <BibleBookChapterItem 
            bookId={props.bibleNames[selectedBook].bookId}
            chapterIndex={i} 
            toggleChapter={(chapterId) => toggleChapter(chapterId)}
            selected={ props.selectedChapters[props.bibleNames[selectedBook].bookId-1].includes(i) ? true : false }
          />
        );
      }
    }

    return chapters;
  }

  return (
    <div className="customPlanBookRootDiv">
      <div className="bannerDiv customPlanBookDiv">
        <img className="banner" src={cover} />
        <p>自選讀經計劃 (自選章節)</p>
        <CloseIcon className="closeBtn" onClick={() => props.close()}/>
        <h1 style={{
            position: 'absolute',
            color: 'white',
            bottom: '6px',
            fontSize: '12px',
            left: '0',
            margin: '0',
            backgroundColor: 'gray',
            textAlign: 'center'
        }}>請選擇{props.duration}～{props.duration*130}章以添加到您的閱讀計劃中</h1>
      </div>

      <Container component="main" maxWidth="xs" className="loginRegisterContainer" style={{ position: 'relative' }}>
           
          <div className={classes.paper} style={{ marginTop: '0px'}}>
            <h1>添加聖經</h1>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ width: '140px', maxHeight: '300px', 'overflow-y': 'scroll' }}>
                {generateBookNames()}
              </div>
              <div style={{ display: 'flex', 'flex-wrap': 'wrap', 
                            width: 'calc(100% - 140px)',
                            maxHeight: '300px', 'overflow-y': 'scroll',
                            alignContent: 'flex-start',
                            'justify-content': 'center' }}>
                {generateChapters()}
              </div>
            </div>
            <Grid className="bookGrid" container spacing={2} style={{
                  display: 'flex',
                  'justify-content': 'center',            
            }}>
              

            </Grid>
            <Grid container spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={ isSelectedChaptersCntInRange() ? { color: 'white', 'backgroundColor': '#0F514F' } : { color: 'white', 'backgroundColor': 'gray' }}
                className={classes.submit}
                onClick={() => {
                  if( isSelectedChaptersCntInRange() ) {
                    props.generatePlanByBookChapters();
                  }
                }}

              >
                {getValue("addBible")}
              </Button>

            </Grid>
          </div>
         
        </Container>
    </div>
    
  );
}
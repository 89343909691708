
import React, { useState, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../images/logoWithoutText.png';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import './PlanPopup.css'; 

import cover1 from '../../images/BiblePlanCovers/1.jpg'
import cover2 from '../../images/BiblePlanCovers/2.jpg'
import cover3 from '../../images/BiblePlanCovers/3.jpg'
import cover4 from '../../images/BiblePlanCovers/4.jpg'
import cover5 from '../../images/BiblePlanCovers/5.jpg'

import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import { getValue, getLocalLocale } from "../../constant/Locale";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PlanSettingPopup( props ) {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(16px)',
          color: theme.palette.common.white,
          '& + $track': {
            backgroundColor: '#52d869',
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          color: '#52d869',
          border: '6px solid #fff',
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
      },
      checked: {},
      focusVisible: {},
    }),
  )(({ classes, ...props }: Props) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  let cover;

    if( props.coverIdx == 1 ) {
      cover = cover1;      
    }
    else if( props.coverIdx == 2 ) {
      cover = cover2;
    }
    else if( props.coverIdx == 3 ) {
      cover = cover3;
    }
    else if( props.coverIdx == 4 ) {
      cover = cover4;
    }
    else {
      cover = cover5;
    }

  var dateStr;

  function zeroPad(num, numZeros) {
      var n = Math.abs(num);
      var zeros = Math.max(0, numZeros - Math.floor(n).toString().length );
      var zeroString = Math.pow(10,zeros).toString().substr(1);
      if( num < 0 ) {
          zeroString = '-' + zeroString;
      }

      return zeroString+n;
  }

  if( props.startDate ) {
    var d = new Date(Date.parse(props.startDate));
    d.setDate(d.getDate()+props.numOfDays);
    var localeDateStr = d.toLocaleDateString("en-US");
    dateStr = localeDateStr.split("/")[2] + "-" + zeroPad(localeDateStr.split("/")[0],2) + "-" + zeroPad(localeDateStr.split("/")[1],2);
  }

  function handleChange() {
    // alert("Handle change...");
    props.handleNotificationChange();
  }  

  return (
    <div>
      <div className="bannerDiv">
        <img className="banner" src={cover} />
        <p>{props.planName}</p>
        <CloseIcon className="closeBtn" onClick={() => props.close()}/>
      </div>

      <Container component="main" maxWidth="xs" className="loginRegisterContainer">
          
          <div className={classes.paper} style={{ marginTop: '10px', 'align-items': 'flex-start' }}>

            <div className="planDesc">  
                <p>{props.planDesc}</p>
                <p>{getValue("startDate")}: {props.startDate}</p>
                <p>{getValue("endDate")}: {dateStr}</p>
                <p>{getValue("notify")}: 
                  <IOSSwitch 
                    checked={props.notificationStatus} 
                    onChange={handleChange} />
                </p>
            </div>

            <Grid container spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ color: 'white', 'backgroundColor': '#0F514F' }}
                className={classes.submit}
                onClick={() => {
                  props.createPlan(props.plan, selectedDate);
                }}

              >
                {getValue("stopPlan")}
              </Button>

            </Grid>
          </div>
         
        </Container>
    </div>
    
  );
}
import { getSdaDictionaryPending, getSdaDictionarySuccess, getSdaDictionaryError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function getAllSdaDictionaries($locale) {

    let api = APP_DOMAIN+"/getSdaDictionary/"+$locale;

    return dispatch => {
        dispatch(getSdaDictionaryPending());

        fetch(api, SecurityHeader())
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(getSdaDictionarySuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(getSdaDictionaryError(error));
        })
    }
}


import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getBookmarkedVersesAction from '../../redux/api/getBookmarkedVersesApi';
import bookmarkVerseAction from '../../redux/api/bookmarkVerseApi';
import { getMembershipPending, getBookmarkedVerses, getMembershipError } from '../../redux/reducer/membershipReducer';
import '../home/Home.css'; 

import BookItem from '../home/BookItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';

import { getValue, getLocalLocale } from "../../constant/Locale";

class BookmarkedVerses extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      isScrolling: false,
      isBookmarking: false
    };
    this._scroller = React.createRef();
  }

  componentWillMount() {
    const { getBookmarkedVerses } = this.props;
    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    if( user ) {
      this.props.getBookmarkedVerses();  
    }
    
  }

  cancelBookmark( verseId ) {
    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    if( user ) {
      this.props.bookmarkVerse( verseId, false );
    }
    this.setState({ isBookmarking: true });
  }

  componentDidMount() {
     
  }

  render() {

    const {error, pending} = this.props;

    if( !pending && this.state.isBookmarking ) {
      let user = JSON.parse(sessionStorage.getItem('userInfo'));
      if( user ) {
        this.props.getBookmarkedVerses();  
      }
    }

    return (
      <div>

        <Header />
        <div ref={this._scroller} onMouseDown={this.onScroll}
        onScroll={this.onMouseMove} >
          <ul className="categoryMenu"  >
            <li className="active" >
              {getValue("bookmark")}
            </li>
          </ul>
        </div>
        <div className="booksContainer">
          {
            /* book grid  */
            (this.props.verses).map((verse) => {
                return (
                  <BookItem 
                    bookId={verse['book_id']}
                    chapterId={verse['chapter_id']}
                    verseId={verse['verse_id']}
                    bookName={verse['bookName']}
                    verse={ verse['chapter_num'] + ":" + verse['verse_num'] + " " + verse['verse_content']}
                    shouldShowCloseBtn={true}
                    type={"bookmark"}
                    cancelBookmark={(verseId) => this.cancelBookmark(verseId)}
                  />
                )
              })
           
            
          }
        </div>
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {
      error: getMembershipError(state.bibleBookCategoryReducer),
      // book_categories: getBibleBookCats(state.bibleBookCategoryReducer),
      verses: getBookmarkedVerses(state.membershipReducer),
      pending: getMembershipPending(state.bibleBookCategoryReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getBookmarkedVerses: getBookmarkedVersesAction,
    bookmarkVerse: bookmarkVerseAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BookmarkedVerses));

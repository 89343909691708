import * as Action from '../action';

const initialState = {
    pending: false,
    search_results: [],
    error: null
}

export default function searchReducer(state = initialState, action) {
    switch(action.type) {
        case Action.SEARCH_PENDING: 
            return {
                ...state,
                pending: true
            }
        case Action.SEARCH_SUCCESS:
            return {
                ...state,
                pending: false,
                search_results: action.search_results
            }
        case Action.SEARCH_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default: 
            return state;
    }
}

export const searchSuccess = state => state.search_results;
export const searchPending = state => state.pending;
export const searchError = state => state.error;






import React, { Component } from 'react';
import cover1 from '../../images/bookCover1@2x.png'
import cover2 from '../../images/BookCover2@2x.png'
import cover3 from '../../images/BookCover3@2x.png'
import cover4 from '../../images/BookCover4@2x.png'
import cover5 from '../../images/BookCover5@2x.png'

import CloseIcon from '@material-ui/icons/Close';
import BookmarkBorderTwoToneIcon from '@material-ui/icons/BookmarkBorderTwoTone';
import BookmarkTwoToneIcon from '@material-ui/icons/BookmarkTwoTone';

class EgwItem extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			isCollected: this.props.hasCollected
		};
		this.removeItem = this.removeItem.bind(this);
	}

	toggleCollection( shouldCollect ) {
		this.props.toggleCollection( this.props.id, shouldCollect );
		this.setState({ isCollected: shouldCollect });
	}

	removeItem() {
		this.props.cancelCollect(this.props.id);
	}

	render() {
		return (

			<div className="resourceItem" style={{ position: 'relative'}}>
				{( this.props.shouldShowCollectBtn && this.state.isCollected ) ? 
				 <BookmarkTwoToneIcon 
				 	className="collectBtn"
					style={{ position: 'absolute', top: '5px', right: '5px', color: '#155251'}} 
					fontSize="large" 
					onClick={() => this.toggleCollection(false)}
				  /> : 
				  ( this.props.shouldShowCollectBtn ) ?
				  <BookmarkBorderTwoToneIcon 
				  	className="collectBtn"
					style={{ position: 'absolute', top: '5px', right: '5px', color: '#155251'}} 
					fontSize="large" 
					onClick={() => this.toggleCollection(true)}
				  /> : ""
				}

				{( this.props.shouldShowCloseBtn ) ? 
					<CloseIcon 
						className="closeBtn"
						style={{ position: 'absolute', top: '5px', right: '5px', color: '#155251'}} 
						fontSize="large"  
						onClick={() => this.removeItem()}
					/> : ""
				} 

				<a href={this.props.url}>
					<div>
						<div className="cover">
							<img src={this.props.cover} /> 
						</div>
						<div className="desc" >
							<h1>{this.props.name}</h1>
							<p dangerouslySetInnerHTML={{ __html: this.props.desc.replace(RegExp(this.props.highlight,'gi'), '<span className="highlightColor" style="background-color: #FFFF00;">'+this.props.highlight+'</span>') }}></p>
						</div>
					</div>
				</a>
			</div>

			);


	}


}

export default EgwItem;
import { registerPending, registerSuccess, registerError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import CryptoJS from 'crypto-js';

export default function register($firstName, $lastName, $email, $password) {

    var passphrase = "Gi4sb6Sq8bQexvmiT~2<g+{AvJ4M4M";
    var plain_text = $firstName + "|" + $lastName + "|" + $email + "|" + $password + "|" + new Date();

    var salt = CryptoJS.lib.WordArray.random(256);
    var iv = CryptoJS.lib.WordArray.random(16);
    //for more random entropy can use : https://github.com/wwwtyro/cryptico/blob/master/random.js instead CryptoJS random() or another js PRNG

    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999 });

    var encrypted = CryptoJS.AES.encrypt(plain_text, key, {iv: iv});

    let registerAPI = APP_DOMAIN+"/register";

    return dispatch => {
        dispatch(registerPending());

        fetch(registerAPI, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                userInfo : CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
                salt : CryptoJS.enc.Hex.stringify(salt),
                iv : CryptoJS.enc.Hex.stringify(iv)    
            })


        })
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }

            if( res.code ) {
                throw(res.message);
            }

            dispatch(registerSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(registerError(error));
        })
    }
}
import { updateInfoPending, updateInfoSuccess, updateInfoError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import CryptoJS from 'crypto-js';

export default function changeUserInfo($firstName, $lastName, $password) {

    var passphrase = "Gi4sb6Sq8bQexvmiT~2<g+{AvJ4M4M";
    var plain_text = $firstName + "|" + $lastName + "|"  + $password + "|" + new Date();

    var salt = CryptoJS.lib.WordArray.random(256);
    var iv = CryptoJS.lib.WordArray.random(16);

    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999 });

    var encrypted = CryptoJS.AES.encrypt(plain_text, key, {iv: iv});

    let API = APP_DOMAIN+"/updateUserInfo";

    let user = JSON.parse(sessionStorage.getItem('userInfo'));

    return dispatch => {
        dispatch(updateInfoPending());

        if( user ) {
            fetch(API, {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    userInfo : CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
                    salt : CryptoJS.enc.Hex.stringify(salt),
                    iv : CryptoJS.enc.Hex.stringify(iv),
                    token : user.token
                })


            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if( res.code == "500" && res.message == "Login information is invalid" ) {
                    throw("Token invalid");
                }
                if(res.error) {
                    throw(res.error);
                }

                if( res.code ) {
                    throw(res.message);
                }

                dispatch(updateInfoSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(updateInfoError(error));
            })
        }

        
    }
}
import React, { Component } from 'react';
import CryptoJS from 'crypto-js';

export function SecurityHeader(parameters) {

	var passphrase = "Gi4sb6Sq8bQexvmiT~2<g+{AvJ4M4M";
    var secret = "n01NkLmSh5PDEgVwVZIc";

    var salt = CryptoJS.lib.WordArray.random(256);
    var iv = CryptoJS.lib.WordArray.random(16);

    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999 });

    var encrypted = CryptoJS.AES.encrypt(secret, key, {iv: iv});

    var requestDict = {};
    requestDict.cipherText = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    requestDict.salt = CryptoJS.enc.Hex.stringify(salt);
    requestDict.iv = CryptoJS.enc.Hex.stringify(iv);

    for (var key in parameters) {
        requestDict[key] = parameters[key];
    }

    var request = {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(requestDict)
        };

    return request;

}
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default class BibleSearchMenu extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: null,
      hint: props.hint
    }
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })

    if (this.props.items.length == 0) {
      this.props.onItemClick(0);
    }
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  };

  handleSelect = (id, name) => {
    this.setState({ hint: name });
    this.props.onItemClick(id);
    this.handleClose();
  }

  render() {

    return (
      <div>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
          <span className={(this.props.alwaysOn) ? "selectedMenu" : ((this.props.selected) ? "selectedMenu" : "" )}>{this.props.placeholder || ""}</span>
          <span style={{ "color": "#acacac", "marginLeft": "12px" }}>{(this.props.selected) ? (this.state.hint) : (this.props.hint)}</span>
        </Button>
        {
          (this.props.items.length > 0) ? 
          <Menu
            style={{ maxHeight: 300 }}
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            {
              (this.props.items || []).map((item) => {
                return <MenuItem style={{ "fontSize": "0.875em" }} onClick={() => this.handleSelect(item.id, item.name)}>{item.name}</MenuItem>
              })
            }
          </Menu>
          : ""
        }
      </div>
    );

  }

}

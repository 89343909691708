import React, { Component } from 'react';

class MapItem extends Component {

	constructor(props, context) {
		super(props, context);
	}

	render() {
		return (

			<div className="videoItem" onClick={((e) => { this.props.onSelect(this.props.cover) })} >
				<div className="cover">
					<img src={this.props.cover} /> 
				</div>

				<div className="desc" >
					<h1>{this.props.name}</h1>
					<p>{this.props.desc}</p>
				</div>
			</div>

			);

	}


}

export default MapItem;
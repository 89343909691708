import React, { useState, useEffect } from 'react';
// import { useSpeechSynthesis } from 'react-speech-kit';

import playBtn from '../../images/play_button.png';
import pauseBtn from '../../images/pause_button.png';
import stopBtn from '../../images/stop_button.png';

const SpeechBox = (props) => {
  var isPlaying = false;
  const [playState, setPlayState] = useState(0);
  const { text } = props;
  const { isStopped } = props;
  const [pitch, setPitch] = useState(1);
  const [rate, setRate] = useState(0.9);
  const [voiceIndex, setVoiceIndex] = useState(64);
  const [lang, setLang] = useState('zh');
  var currentIdx = props.isStopped;
  var timer = [];
  var arrtext = [];
  var restoreArrtext = [];
  const [pageIdx, setPageIdx] = useState(0);

  const staticVoices = window.speechSynthesis.getVoices();

  useEffect(() => {
    console.log(props.isStopped);
    console.log(currentIdx);
  });

  const onEnd = idx => {
    // console.log(window.speechSynthesis);

      if (currentIdx < text.length) {
        currentIdx += 1;
        // console.log(1); 
        speak(currentIdx);
      }


    // if (window.speechSynthesis.speaking) {

    // }
  };

  function isAndroid() {
    var flag = false;
    flag = navigator.userAgent.match(/Android/i) && navigator.maxTouchPoints && window.innerWidth <= 800;
    return flag;
  }

  // const voice = voices[voiceIndex] || null;
  // const lang = "zh";

  const styleFlexRow = { display: 'flex', flexDirection: 'row' };
  const styleContainerRatePitch = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 12,
  };


  const speak = idx => {
    // var v = window.speechSynthesis.getVoices()[props.voice];
    // console.log(window.speechSynthesis.getVoices());
    var utterance = new window.SpeechSynthesisUtterance();
    // console.log(v);
    utterance.text = (arrtext[idx] != undefined) ? arrtext[idx] : "";
    //voices[props.voice];
    if (props.voice == 0) {
      utterance.voice = window.speechSynthesis.getVoices().filter(x => x.lang.includes('en-US') || x.lang.includes('en_US'))[0];
      utterance.lang = "en";
    } else {
      utterance.voice = window.speechSynthesis.getVoices().filter(x => x.lang.includes('zh-CN') || x.lang.includes('zh_CN'))[0];
      utterance.lang = "zh";
    }
    console.log(utterance);
    utterance.lang = (utterance.voice) ? utterance.voice.lang : utterance.lang;
    utterance.onstart = () => {

    };
    utterance.onboundary = function(e) {
      
    };
    utterance.onerror = function(e) {
      console.log(e);
    }
    utterance.onpause = function(e) {
      console.log("pause");
    };
    utterance.onend = (e) => {

      if (isAndroid()) {

        //speaking is false and pause is false
        if (!window.speechSynthesis.paused && !window.speechSynthesis.speaking) {
          console.log("play end");
          console.log(arrtext);
          arrtext.shift();
          if (arrtext.length > 0) {
            speak(0);
          }
        } 

        //speaking is true and pause is false
        if (!window.speechSynthesis.paused && window.speechSynthesis.speaking) {
          console.log("click end");
          // arrtext.shift();
          // if (arrtext.length > 0) {
          //   speak(0);
          // }

              // arrtext = [""];
              // window.speechSynthesis.cancel();
              // speak(0);
        } 

      } else {
        currentIdx += 1;
        arrtext.shift();
        if (arrtext.length > 0) {
          speak(0);
        }
      }
      
    };
    utterance.volume = 0.5;
    utterance.rate = rate;
    utterance.pitch = pitch;
    window.speechSynthesis.speak(utterance);
  }

  function playsound() {
    var idx = currentIdx;
    while (idx < text.length) {
      idx += 1;
      speak(idx);
    }

  }

  const clickbutton = () => {

    window.speechSynthesis.onvoiceschanged = () => {
      const voices = speechSynthesis.getVoices()
    };

    if (playState == 0) {
      window.speechSynthesis.cancel();
      isPlaying = true;
      if (arrtext.length == 0) {
        // window.speechSynthesis.resume();

        if (restoreArrtext.length > 0) {
          arrtext = restoreArrtext;
        } else {
          if (isAndroid()) {

            props.text.map(item => {
                arrtext.push([item]);
                restoreArrtext.push([item]);
            }); 
            // arrtext = (props.text+"");
      
            // if (props.voice == 64) {
            //     arrtext = (props.text+"").split('。');
            //   } else {
            //     arrtext = (props.text+""];
            //   }

          } else {
            if (props.voice == 64) {
                arrtext = (props.text+"").split('。');
              } else {
                arrtext = (props.text+"").split('.');
              }
            restoreArrtext = arrtext;
          }
        }
        console.log(arrtext);
        console.log(restoreArrtext);
        speak(0);
        // playsound();
      }

    }
    if (playState == 1) {
      if (isAndroid()) {
        //androud has no pause/resume, special handle ===> cancel and speak at pos
        console.log(restoreArrtext);
        isPlaying = false;
        arrtext = [""];
        window.speechSynthesis.pause();
      } else {
        isPlaying = false;
        window.speechSynthesis.pause();
      }
    }
    if (playState == -1) {
      if (isAndroid()) {
          // speak(0);

        // if (!window.speechSynthesis.paused && window.speechSynthesis.speaking) {

          window.speechSynthesis.cancel();

          props.text.map(item => {
              arrtext.push([item]);
          }); 

          if (pageIdx > 0) {
            for(var i=0;i<pageIdx;i++) {
              arrtext.shift();
            }
          }
          console.log(arrtext);

          speak(0);


        // }
      } else {
        isPlaying = true;
        window.speechSynthesis.resume();
      }
    }

  }

  window.addEventListener('beforeload', () => {
    console.log("load");
    isPlaying = false; 
    setPlayState(0);
    arrtext = [""];
    window.speechSynthesis.cancel();
    speak(0);
  });

  window.addEventListener('beforeunload', () => {
    console.log("unload");
    isPlaying = false; 
    setPlayState(0);
    arrtext = [""];
    window.speechSynthesis.cancel();
    speak(0);
  });

  window.addEventListener('blur', () => {
    console.log("blur");
    isPlaying = false; 
    setPlayState(0);
    arrtext = [""];
    window.speechSynthesis.cancel();
    speak(0);
  });

  return (
    <div>
      <div className="playPauseBtnBox">
        <img className="playPauseBtn" src={(playState == 1) ? pauseBtn : playBtn}  onClick={(e) => { clickbutton(); setPlayState((playState <= 0) ? 1 : -1);  } } />
        {
          (playState == 1) ? <img className="playPauseBtn" src={stopBtn}  onClick={(e) => { 
              isPlaying = false; 
              setPlayState(0);
              setPageIdx(0);
              arrtext = [""];
              window.speechSynthesis.cancel();
              speak(0);
          } } /> : ""
        }
      </div>
    </div>
  );
};

export default SpeechBox;
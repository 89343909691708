import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Landing from '../components/home/Landing';
import Home from '../components/home/Home';
import BibleBook from '../components/bibleBooks/BibleBook';
import BibleBookMap from '../components/bibleBooks/BibleBookMap';
import Magazine from '../components/resource/Magazine';
import Video from '../components/resource/Video';
import Egw from '../components/resource/Egw';
import Topic from '../components/topics/Topic';
import SdaDictionary from '../components/sda_dictionaries/SdaDictionary';

import Signin from '../components/membership/login';
import SearchResult from '../components/common/SearchResult';

import BookmarkedVerses from '../components/membership/bookmarkedVerses';
import DiscoverPlans from '../components/membership/discoverPlans';

import CollectedBibles from '../components/membership/collectedBibles';
import CollectedMagazines from '../components/membership/collectedMagazines';

import PlanPreview from '../components/membership/PlanPreview';

import EgwBook from '../components/egw_books/EgwBook';
import EgwChapter from '../components/egw_books/EgwChapter';


const ROUTES = [
  { path: "/", key: "ROOT", exact: true, component: () => <Landing />
  },
  { path: "/bible", key: "ROOT", exact: true, component: () => <Home />
  },
  {
    path: "/bibleBook",
    key: "APP",
    component: RenderRoutes,
    routes: [
      {
        path: "/bibleBook",
        key: "BIBLE_BOOK",
        exact: true,
        component: () => <h1>book</h1>,
      },
      {
        path: "/bibleBook/:book_id",
        key: "BIBLE_BOOK_ID",
        exact: true,
        component: () => <BibleBook />,
      },
      {
        path: "/bibleBook/:book_id/:chapter_id",
        key: "BIBLE_BOOK_ID",
        exact: true,
        component: () => <BibleBook />,
      },
      {
        path: "/bibleBook/:book_id/:chapter_id/:content_version",
        key: "BIBLE_BOOK_ID",
        exact: true,
        component: () => <BibleBook />,
      },
    ],
  },
  {
    path: "/searchResult",
    key: "searchResult",
    component: RenderRoutes,
    routes: [
      {
        path: "/searchResult",
        key: "SEARCH",
        exact: true,
        component: () => <SearchResult />,
      },
      {
        path: "/searchResult/:type/:start_bible_id/:end_bible_id/:content_version/:option/:word",
        key: "SEARCH_BY",
        exact: true,
        component: () => <SearchResult />,
      },
    ],
  },
  {
    path: "/login", key: "LOGIN", exact: true, component: () => <Signin />
  },
  { 
    path: "/magazine", key: "MAGAZINE", exact: true, component: () => <Magazine />
  },
  { 
    path: "/video", key: "VIDEO", exact: true, component: () => <Video />
  },
  { 
    path: "/egw", key: "EGW", exact: true, component: () => <Egw />
  },
  { 
    path: "/egwBook", key: "EgwBook", exact: true, component: () => <EgwBook />
  },
  { 
    path: "/egwBook/:book_id", key: "EgwBook", exact: true, component: () => <EgwChapter />
  },
  { 
    path: "/egwBook/:book_id/:chap_id", key: "EgwBook", exact: true, component: () => <EgwChapter />
  },
  { 
    path: "/topic", key: "TOPIC", exact: true, component: () => <Topic />
  },
  { 
    path: "/sdaDictionary", key: "SdaDictionary", exact: true, component: () => <SdaDictionary />
  },
  {
    path: "/discoverPlans", key: "biblePlans", exact: true, component: () => <DiscoverPlans />
  },
  {
    path: "/PlanPreview", key: "PlanPreview", exact: true, component: () => <PlanPreview />
  },
  {
    path: "/bibleBookMap/:book_id",
    key: "BIBLE_BOOK_MAP",
    exact: true,
    component: () => <BibleBookMap />,
  },
  {
    path: "/bookmarkedVerses",
    key: "BOOKMARKED_VERSES",
    exact: true,
    component: () => <BookmarkedVerses />,

  },
  {
    path: "/collectedBibles",
    key: "COLLECTED_BIBLES",
    exact: true,
    component: () => <CollectedBibles />,

  },
  {
    path: "/collectedMagazines",
    key: "COLLECTED_MAGAZINES",
    exact: true,
    component: () => <CollectedMagazines />,

  }
];

export default ROUTES;

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props => <route.component {...props} routes={route.routes} />}
    />
  );
}

export function RenderRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  );
}
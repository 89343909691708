
import React, { useState, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../images/logoWithoutText.png';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import './PlanPopup.css'; 

import { getValue, getLocalLocale } from "../../constant/Locale";

import cover1 from '../../images/BiblePlanCovers/1.jpg'
import cover2 from '../../images/BiblePlanCovers/2.jpg'
import cover3 from '../../images/BiblePlanCovers/3.jpg'
import cover4 from '../../images/BiblePlanCovers/4.jpg'
import cover5 from '../../images/BiblePlanCovers/5.jpg'

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function StartPlanPopup( props ) {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  let cover;

    if( props.coverIdx == 1 ) {
      cover = cover1;      
    }
    else if( props.coverIdx == 2 ) {
      cover = cover2;
    }
    else if( props.coverIdx == 3 ) {
      cover = cover3;
    }
    else if( props.coverIdx == 4 ) {
      cover = cover4;
    }
    else {
      cover = cover5;
    }

  return (
    <div>
      <div className="bannerDiv">
        <img className="banner" src={cover} />
        <p>{props.plan['name_tc']}</p>
        <CloseIcon className="closeBtn" onClick={() => props.close()}/>
      </div>

      <Container component="main" maxWidth="xs" className="loginRegisterContainer">
          
          <div className={classes.paper} style={{ marginTop: '20px'}}>
            <div className="dayDiv">
              {props.plan['numOfDays']}天
            </div>
            <Grid container spacing={2} style={{

                  display: 'flex',
                  'justify-content': 'center',
                  padding: '20px 0'

            }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  disablePast
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  label={getValue("choosePlanStartDate")}
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ color: 'white', 'backgroundColor': '#0F514F' }}
                className={classes.submit}
                onClick={() => {
                  props.createPlan(props.plan, selectedDate);
                }}

              >
                {getValue("startPlan")}
              </Button>

            </Grid>
          </div>
         
        </Container>
    </div>
    
  );
}
import * as Action from '../action';

const initialState = {
    pending: false,
    egw_chapters: [],
    error: null
}

export default function egwChapterReducer(state = initialState, action) {
    switch(action.type) {
        case Action.GET_EGW_CHAPTER_PENDING: 
            return {
                ...state,
                pending: true
            }
        case Action.GET_EGW_CHAPTER_SUCCESS:
            return {
                ...state,
                pending: false,
                egw_chapters: action.res
            }
        case Action.GET_EGW_CHAPTER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default: 
            return state;
    }
}

export const getEgwChapters = state => state.egw_chapters;
export const getEgwChaptersPending = state => state.pending;
export const getEgwChaptersError = state => state.error;






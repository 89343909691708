import { searchPending, searchSuccess, searchError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function postSearchByWord($search_type, $word, $start_bible_id, $end_bible_id, $content_version, $option) {

    var api = APP_DOMAIN+"/postVerseByWord";

    if ($search_type == "egw") {
        api = APP_DOMAIN+"/app/searchByKeywords/"+$content_version+"/v2";
        return dispatch => {
        dispatch(searchPending());

        fetch(api, SecurityHeader({
                "keyword": $word,
            }))
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(searchSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(searchError(error));
        })
    }
    }

    return dispatch => {
        dispatch(searchPending());

        fetch(api, SecurityHeader({
                "search_type": $search_type,
                "word": $word,
                "start_bible_id": $start_bible_id,
                "end_bible_id": $end_bible_id,
                "content_version": $content_version,
                "option": $option,
            }))
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(searchSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(searchError(error));
        })
    }



}


import { getBibleBookCategoryPending, getBibleBookCategorySuccess, getBibleBookCategoryError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function getBibleCategories($locale) {

    let user = JSON.parse(sessionStorage.getItem('userInfo'));

    let api = APP_DOMAIN+"/getAllBibleBookCategories/"+$locale;

    if( user ) {
      api = APP_DOMAIN+"/getAllBibleBookCategoriesWithUid";
      return dispatch => {
            dispatch(getBibleBookCategoryPending());

            fetch(api, {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    token : user.token,
                    locale: $locale
                })
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if( res.code == "500" && res.message == "Login information is invalid" ) {
                    throw("Token invalid");
                }
                if(res.error) {
                    throw(res.error);
                }
                dispatch(getBibleBookCategorySuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(getBibleBookCategoryError(error));
            })
        }
    }
    else {

        var request = SecurityHeader();

        return dispatch => {
            dispatch(getBibleBookCategoryPending());

            fetch(api, request)
            .then(res => {
                return res.json()
            })
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(getBibleBookCategorySuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(getBibleBookCategoryError(error));
            })
        }
    }

    
}


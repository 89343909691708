export const GET_LANDING_RESOURCE_PENDING = 'GET_LANDING_RESOURCE_PENDING';
export const GET_LANDING_RESOURCE_SUCCESS = 'GET_LANDING_RESOURCE_SUCCESS';
export const GET_LANDING_RESOURCE_ERROR = 'GET_LANDING_RESOURCE_ERROR';

export const GET_BIBLE_BOOK_CATEGORY_PENDING = 'GET_BIBLE_BOOK_CATEGORY_PENDING';
export const GET_BIBLE_BOOK_CATEGORY_SUCCESS = 'GET_BIBLE_BOOK_CATEGORY_SUCCESS';
export const GET_BIBLE_BOOK_CATEGORY_ERROR = 'GET_BIBLE_BOOK_CATEGORY_ERROR';

export const GET_TOPIC_PENDING = 'GET_TOPIC_PENDING';
export const GET_TOPIC_SUCCESS = 'GET_TOPIC_SUCCESS';
export const GET_TOPIC_ERROR = 'GET_TOPIC_ERROR';

export const GET_TOPIC_SEARCH_PENDING = 'GET_TOPIC_SEARCH_PENDING';
export const GET_TOPIC_SEARCH_SUCCESS = 'GET_TOPIC_SEARCH_SUCCESS';
export const GET_TOPIC_SEARCH_ERROR = 'GET_TOPIC_SEARCH_ERROR';

export const GET_SDA_DICTIONARY_PENDING = 'GET_SDA_DICTIONARY_PENDING';
export const GET_SDA_DICTIONARY_SUCCESS = 'GET_SDA_DICTIONARY_SUCCESS';
export const GET_SDA_DICTIONARY_ERROR = 'GET_SDA_DICTIONARY_ERROR';

export const GET_SDA_DICTIONARY_SEARCH_PENDING = 'GET_SDA_DICTIONARY_SEARCH_PENDING';
export const GET_SDA_DICTIONARY_SEARCH_SUCCESS = 'GET_SDA_DICTIONARY_SEARCH_SUCCESS';
export const GET_SDA_DICTIONARY_SEARCH_ERROR = 'GET_SDA_DICTIONARY_SEARCH_ERROR';

export const GET_RESOURCE_CATEGORY_PENDING = 'GET_RESOURCE_CATEGORY_PENDING';
export const GET_RESOURCE_CATEGORY_SUCCESS = 'GET_RESOURCE_CATEGORY_SUCCESS';
export const GET_RESOURCE_CATEGORY_ERROR = 'GET_RESOURCE_CATEGORY_ERROR';

export const GET_BIBLE_BOOK_PENDING = 'GET_BIBLE_BOOK_PENDING';
export const GET_BIBLE_BOOK_SUCCESS = 'GET_BIBLE_BOOK_SUCCESS';
export const GET_BIBLE_BOOK_ERROR = 'GET_BIBLE_BOOK_ERROR';

export const GET_BIBLE_VERSE_PENDING = 'GET_BIBLE_VERSE_PENDING';
export const GET_BIBLE_VERSE_SUCCESS = 'GET_BIBLE_VERSE_SUCCESS';
export const GET_BIBLE_SECONDARY_VERSE_SUCCESS = 'GET_BIBLE_SECONDARY_VERSE_SUCCESS';
export const GET_BIBLE_VERSE_ERROR = 'GET_BIBLE_VERSE_ERROR';

export const REGISTER_PENDING = 'REGISTSER_PENDING';
export const REGISTER_SUCCESS = 'REGISTSER_SUCCESS';
export const REGISTER_ERROR   = 'REGISTSER_ERROR';

export const REGISTER_PLAN_PENDING = 'REGISTER_PLAN_PENDING';
export const REGISTER_PLAN_SUCCESS = 'REGISTER_PLAN_SUCCESS';
export const REGISTER_PLAN_ERROR   = 'REGISTER_PLAN_ERROR';

export const CREATE_PLAN_PENDING = 'CREATE_PLAN_PENDING';
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS';
export const CREATE_PLAN_ERROR   = 'CREATE_PLAN_ERROR';

export const DELETE_PLAN_PENDING = 'DELETE_PLAN_PENDING';
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS';
export const DELETE_PLAN_ERROR   = 'DELETE_PLAN_ERROR';

export const REMOVE_REGISTERED_PLAN_PENDING = 'REMOVE_REGISTERED_PLAN_PENDING';
export const REMOVE_REGISTERED_PLAN_SUCCESS = 'REMOVE_REGISTERED_PLAN_SUCCESS';
export const REMOVE_REGISTERED_PLAN_ERROR   = 'REMOVE_REGISTERED_PLAN_ERROR';

export const UPDATE_INFO_PENDING = 'UPDATE_INFO_PENDING';
export const UPDATE_INFO_SUCCESS = 'UPDATE_INFO_SUCCESS';
export const UPDATE_INFO_ERROR   = 'UPDATE_INFO_ERROR';

export const SEND_FORGET_PW_EMAIL_PENDING = 'SEND_FORGET_PW_EMAIL_PENDING';
export const SEND_FORGET_PW_EMAIL_SUCCESS = 'SEND_FORGET_PW_EMAIL_SUCCESS';
export const SEND_FORGET_PW_EMAIL_ERROR   = 'SEND_FORGET_PW_EMAIL_ERROR';

export const VERIFY_EMAIL_PENDING = 'VERIFY_EMAIL_PENDING';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR   = 'VERIFY_EMAIL_ERROR';

export const CHANGE_PW_PENDING = 'CHANGE_PW_PENDING';
export const CHANGE_PW_SUCCESS = 'CHANGE_PW_SUCCESS';
export const CHANGE_PW_ERROR   = 'CHANGE_PW_ERROR';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR   = 'LOGIN_ERROR';

export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR   = 'LOGOUT_ERROR';

export const SEARCH_PENDING = 'SEARCH_PENDING';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_ERROR   = 'SEARCH_ERROR';

export const GET_BIBLE_BOOK_NAME_AND_CHAPTERS_PENDING = 'GET_BIBLE_BOOK_NAME_AND_CHAPTERS_PENDING';
export const GET_BIBLE_BOOK_NAME_AND_CHAPTERS_SUCCESS = 'GET_BIBLE_BOOK_NAME_AND_CHAPTERS_SUCCESS';
export const GET_BIBLE_BOOK_NAME_AND_CHAPTERS_ERROR   = 'GET_BIBLE_BOOK_NAME_AND_CHAPTERS_ERROR  ';

export const GET_BOOKMARKED_VERSES_PENDING = 'GET_BOOKMARKED_VERSES_PENDING';
export const GET_BOOKMARKED_VERSES_SUCCESS = 'GET_BOOKMARKED_VERSES_SUCCESS';
export const GET_BOOKMARKED_VERSES_ERROR   = 'GET_BOOKMARKED_VERSES_ERROR';

export const GET_COLLECTED_BIBLES_PENDING = 'GET_COLLECTED_BIBLES_PENDING';
export const GET_COLLECTED_BIBLES_SUCCESS = 'GET_COLLECTED_BIBLES_SUCCESS';
export const GET_COLLECTED_BIBLES_ERROR   = 'GET_COLLECTED_BIBLES_ERROR';

export const GET_COLLECTED_MAGAZINES_PENDING = 'GET_COLLECTED_MAGAZINES_PENDING';
export const GET_COLLECTED_MAGAZINES_SUCCESS = 'GET_COLLECTED_MAGAZINES_SUCCESS';
export const GET_COLLECTED_MAGAZINES_ERROR   = 'GET_COLLECTED_MAGAZINES_ERROR';

export const GET_BIBLE_PLANS_PENDING = 'GET_BIBLE_PLANS_PENDING';
export const GET_BIBLE_PLANS_SUCCESS = 'GET_BIBLE_PLANS_SUCCESS';
export const GET_BIBLE_PLANS_ERROR   = 'GET_BIBLE_PLANS_ERROR';

export const GET_BIBLE_PLAN_DETAILS_PENDING = 'GET_BIBLE_PLAN_DETAILS_PENDING';
export const GET_BIBLE_PLAN_DETAILS_SUCCESS = 'GET_BIBLE_PLAN_DETAILS_SUCCESS';
export const GET_BIBLE_PLAN_DETAILS_ERROR   = 'GET_BIBLE_PLAN_DETAILS_ERROR';

export const GET_MY_PLANS_PENDING = 'GET_MY_PLANS_PENDING';
export const GET_MY_PLANS_SUCCESS = 'GET_MY_PLANS_SUCCESS';
export const GET_MY_PLANS_ERROR   = 'GET_MY_PLANS_ERROR';

export const COLLECT_BIBLE_PENDING = 'COLLECT_BIBLE_PENDING';
export const COLLECT_BIBLE_ERROR   = 'COLLECT_BIBLE_ERROR';
export const COLLECT_BIBLE_SUCCESS = 'COLLECT_BIBLE_SUCCESS';

export const COLLECT_MAGAZINE_PENDING = 'COLLECT_MAGAZINE_PENDING';
export const COLLECT_MAGAZINE_ERROR   = 'COLLECT_MAGAZINE_ERROR';
export const COLLECT_MAGAZINE_SUCCESS = 'COLLECT_MAGAZINE_SUCCESS';

export const BOOKMARK_VERSE_PENDING = 'BOOKMARK_VERSE_PENDING';
export const BOOKMARK_VERSE_ERROR   = 'BOOKMARK_VERSE_ERROR';
export const BOOKMARK_VERSE_SUCCESS = 'BOOKMARK_VERSE_SUCCESS';

export const UPDATE_PLAN_NOTIFICATION_PENDING = 'UPDATE_PLAN_NOTIFICATION_PENDING';
export const UPDATE_PLAN_NOTIFICATION_ERROR   = 'UPDATE_PLAN_NOTIFICATION_ERROR';
export const UPDATE_PLAN_NOTIFICATION_SUCCESS = 'UPDATE_PLAN_NOTIFICATION_SUCCESS';

export const UPDATE_PLAN_PROGRESS_PENDING = 'UPDATE_PLAN_PROGRESS_PENDING';
export const UPDATE_PLAN_PROGRESS_ERROR   = 'UPDATE_PLAN_PROGRESS_ERROR';
export const UPDATE_PLAN_PROGRESS_SUCCESS = 'UPDATE_PLAN_PROGRESS_SUCCESS';

export const GET_LANGUAGE_PENDING = 'GET_LANGUAGE_PENDING';
export const GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS';
export const GET_LANGUAGE_ERROR   = 'GET_LANGUAGE_ERROR';

export const GET_STATIC_CONTENT_PENDING = 'GET_STATIC_CONTENT_PENDING';
export const GET_STATIC_CONTENT_SUCCESS = 'GET_STATIC_CONTENT_SUCCESS';
export const GET_STATIC_CONTENT_ERROR   = 'GET_STATIC_CONTENT_ERROR';

export const GET_EGW_CATEGORY_PENDING = 'GET_EGW_CATEGORY_PENDING';
export const GET_EGW_CATEGORY_SUCCESS = 'GET_EGW_CATEGORY_SUCCESS';
export const GET_EGW_CATEGORY_ERROR   = 'GET_EGW_CATEGORY_ERROR';

export const GET_EGW_CHAPTER_PENDING = 'GET_EGW_CHAPTER_PENDING';
export const GET_EGW_CHAPTER_SUCCESS = 'GET_EGW_CHAPTER_SUCCESS';
export const GET_EGW_CHAPTER_ERROR   = 'GET_EGW_CHAPTER_ERROR';

export const GET_EGW_BOOK_ID_PENDING = 'GET_EGW_BOOK_ID_PENDING';
export const GET_EGW_BOOK_ID_SUCCESS = 'GET_EGW_BOOK_ID_SUCCESS';
export const GET_EGW_BOOK_ID_ERROR   = 'GET_EGW_BOOK_ID_ERROR';

//lang
export function getLanguagePending() {
    return {
        type: GET_LANGUAGE_PENDING
    }
}

export function getLanguageSuccess(res) {
    return {
        type: GET_LANGUAGE_SUCCESS,
        res: res
    }
}

export function getLanguageError(error) {
    return {
        type: GET_LANGUAGE_ERROR,
        error: error
    }
}

//egw

export function getEgwBookByIdPending() {
    return {
        type: GET_EGW_BOOK_ID_PENDING
    }
}

export function getEgwBookByIdSuccess(res) {
    return {
        type: GET_EGW_BOOK_ID_SUCCESS,
        res: res
    }
}

export function getEgwBookByIdError(error) {
    return {
        type: GET_EGW_BOOK_ID_ERROR,
        error: error
    }
}

export function getEgwCategoryPending() {
    return {
        type: GET_EGW_CATEGORY_PENDING
    }
}

export function getEgwCategorySuccess(res) {
    return {
        type: GET_EGW_CATEGORY_SUCCESS,
        res: res
    }
}

export function getEgwCategoryError(error) {
    return {
        type: GET_EGW_CATEGORY_ERROR,
        error: error
    }
}

export function getEgwChapterPending() {
    return {
        type: GET_EGW_CHAPTER_PENDING
    }
}

export function getEgwChapterSuccess(res) {
    return {
        type: GET_EGW_CHAPTER_SUCCESS,
        res: res
    }
}

export function getEgwChapterError(error) {
    return {
        type: GET_EGW_CHAPTER_ERROR,
        error: error
    }
}

//static content
export function getStaticContentPending() {
    return {
        type: GET_STATIC_CONTENT_PENDING
    }
}

export function getStaticContentSuccess(res) {
    return {
        type: GET_STATIC_CONTENT_SUCCESS,
        res: res
    }
}

export function getStaticContentError(error) {
    return {
        type: GET_STATIC_CONTENT_ERROR,
        error: error
    }
}


//landing
export function getLandingResourcePending() {
    return {
        type: GET_LANDING_RESOURCE_PENDING
    }
}

export function getLandingResourceSuccess(res) {
    return {
        type: GET_LANDING_RESOURCE_SUCCESS,
        res: res
    }
}

export function getLandingResourceError(error) {
    return {
        type: GET_LANDING_RESOURCE_ERROR,
        error: error
    }
}

//sda dictionary

function handleTokenInvalid(error) {
    if( error == "Token invalid" ) {
        sessionStorage.removeItem('userInfo');
        window.location.href = "/?q=login"
    }
}


export function getSdaDictionaryPending() {
    return {
        type: GET_SDA_DICTIONARY_PENDING
    }
}

export function getSdaDictionarySuccess(dictionaries) {
    return {
        type: GET_SDA_DICTIONARY_SUCCESS,
        dictionaries: dictionaries
    }
}

export function getSdaDictionaryError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_SDA_DICTIONARY_ERROR,
        error: error
    }
}

export function getSdaDictionarySearchPending() {
    return {
        type: GET_SDA_DICTIONARY_SEARCH_PENDING
    }
}

export function getSdaDictionarySearchSuccess(search_dictionaries) {
    return {
        type: GET_SDA_DICTIONARY_SEARCH_SUCCESS,
        search_dictionaries: search_dictionaries
    }
}

export function getSdaDictionarySearchError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_SDA_DICTIONARY_SEARCH_ERROR,
        error: error
    }
}

//topic
export function getTopicPending() {
    return {
        type: GET_TOPIC_PENDING
    }
}

export function getTopicSuccess(topics) {
    return {
        type: GET_TOPIC_SUCCESS,
        topics: topics
    }
}

export function getTopicError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_TOPIC_ERROR,
        error: error
    }
}

export function getTopicSearchPending() {
    return {
        type: GET_TOPIC_SEARCH_PENDING
    }
}

export function getTopicSearchSuccess(search_topics) {
    return {
        type: GET_TOPIC_SEARCH_SUCCESS,
        search_topics: search_topics
    }
}

export function getTopicSearchError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_TOPIC_SEARCH_ERROR,
        error: error
    }
}

export const GET_BIBLE_CHAIN_BY_VERSE_PENDING = 'GET_BIBLE_CHAIN_BY_VERSE_PENDING';
export const GET_BIBLE_CHAIN_BY_VERSE_SUCCESS = 'GET_BIBLE_CHAIN_BY_VERSE_SUCCESS';
export const GET_BIBLE_CHAIN_BY_VERSE_ERROR = 'GET_BIBLE_CHAIN_BY_VERSE_ERROR';


//resource category
export function getResourceCategoryPending() {
    return {
        type: GET_RESOURCE_CATEGORY_PENDING
    }
}

export function getResourceCategorySuccess(resource_categories) {
    console.log( resource_categories.parentCategories );
    if (resource_categories.parentCategories) {

        return {
            type: GET_RESOURCE_CATEGORY_SUCCESS,
            resource_categories: resource_categories.childCategories,
            parent_categories: resource_categories.parentCategories
        }
    } else {

        return {
            type: GET_RESOURCE_CATEGORY_SUCCESS,
            resource_categories: resource_categories,
        }
    }
}

export function getResourceCategoryError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_RESOURCE_CATEGORY_ERROR,
        error: error
    }
}

//book category
export function getBibleBookCategoryPending() {
    return {
        type: GET_BIBLE_BOOK_CATEGORY_PENDING
    }
}

export function getBibleBookCategorySuccess(book_categories) {
    return {
        type: GET_BIBLE_BOOK_CATEGORY_SUCCESS,
        book_categories: book_categories
    }
}

export function getBibleBookCategoryError(error) {
    handleTokenInvalid(error);

    return {
        type: GET_BIBLE_BOOK_CATEGORY_ERROR,
        error: error
    }
}

//book
export function getBibleBookPending() {
    return {
        type: GET_BIBLE_BOOK_PENDING
    }
}

export function getBibleBookSuccess(books) {
    return {
        type: GET_BIBLE_BOOK_SUCCESS,
        books: books
    }
}

export function getBibleBookError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_BIBLE_BOOK_ERROR,
        error: error
    }
}

//verse
export function getBibleVersePending() {
    return {
        type: GET_BIBLE_VERSE_PENDING
    }
}

export function getBibleVerseSuccess(verses, content_version, isPrimaryContent) {

    if( isPrimaryContent ) {
        return {
            type: GET_BIBLE_VERSE_SUCCESS,
            verses: verses,
            content_version: content_version
        }
    }
    else {
        return {
            type: GET_BIBLE_SECONDARY_VERSE_SUCCESS,
            secondary_verses: verses,
            secondary_content_version: content_version
        }
    }
}

export function getBibleVerseError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_BIBLE_VERSE_ERROR,
        error: error
    }
}

//chain
export function getBibleChainByVersePending() {
    return {
        type: GET_BIBLE_CHAIN_BY_VERSE_PENDING
    }
}

export function getBibleChainByVerseSuccess(chains) {
    return {
        type: GET_BIBLE_CHAIN_BY_VERSE_SUCCESS,
        chains: chains
    }
}

export function getBibleChainByVerseError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_BIBLE_CHAIN_BY_VERSE_ERROR,
        error: error
    }
}

export function registerPending() {
    return {
        type: REGISTER_PENDING
    }
}

export function registerSuccess(userdata) {
    return {
        type: REGISTER_SUCCESS,
        userdata: userdata
    }
}

export function registerError(error) {
    handleTokenInvalid(error);
    return {
        type: REGISTER_ERROR,
        error: error
    }
}

export function registerPlanPending() {
    return {
        type: REGISTER_PLAN_PENDING
    }
}

export function registerPlanSuccess() {
    return {
        type: REGISTER_PLAN_SUCCESS
    }
}

export function registerPlanError(error) {
    handleTokenInvalid(error);
    return {
        type: CREATE_PLAN_ERROR,
        error: error
    }
}

export function createPlanPending() {
    return {
        type: CREATE_PLAN_PENDING
    }
}

export function createPlanSuccess(planData) {
    return {
        type: CREATE_PLAN_SUCCESS,
        planData: planData
    }
}

export function createPlanError(error) {
    handleTokenInvalid(error);
    return {
        type: CREATE_PLAN_ERROR,
        error: error
    }
}

export function deletePlanPending() {
    return {
        type: DELETE_PLAN_PENDING
    }
}

export function deletePlanSuccess() {
    return {
        type: DELETE_PLAN_SUCCESS
    }
}

export function deletePlanError(error) {
    handleTokenInvalid(error);
    return {
        type: DELETE_PLAN_ERROR,
        error: error
    }
}

export function removeRegisteredPlanPending() {
    return {
        type: REMOVE_REGISTERED_PLAN_PENDING
    }
}

export function removeRegisteredPlanSuccess() {
    return {
        type: REMOVE_REGISTERED_PLAN_SUCCESS
    }
}

export function removeRegisteredPlanError(error) {
    handleTokenInvalid(error);
    return {
        type: REMOVE_REGISTERED_PLAN_ERROR,
        error: error
    }
}

export function updateInfoPending() {
    return {
        type: UPDATE_INFO_PENDING
    }
}

export function updateInfoSuccess(userdata) {
    return {
        type: UPDATE_INFO_SUCCESS,
        userdata: userdata
    }
}

export function updateInfoError(error) {
    handleTokenInvalid(error);
    return {
        type: CHANGE_PW_ERROR,
        error: error
    }
}

export function sendForgetPwEmailPending() {
    return {
        type: SEND_FORGET_PW_EMAIL_PENDING
    }
}

export function sendForgetPwEmailSuccess() {
    return {
        type: SEND_FORGET_PW_EMAIL_SUCCESS,
    }
}

export function sendForgetPwEmailError(error) {
    handleTokenInvalid(error);
    return {
        type: SEND_FORGET_PW_EMAIL_ERROR,
        error: error
    }
}

export function verifyEmailPending() {
    return {
        type: VERIFY_EMAIL_PENDING
    }
}

export function verifyEmailSuccess() {
    return {
        type: VERIFY_EMAIL_SUCCESS,
    }
}

export function verifyEmailError(error) {
    handleTokenInvalid(error);
    return {
        type: VERIFY_EMAIL_ERROR,
        error: error
    }
}


export function changePwPending() {
    return {
        type: CHANGE_PW_PENDING
    }
}

export function changePwSuccess(userdata) {
    return {
        type: CHANGE_PW_SUCCESS,
        userdata: userdata
    }
}

export function changePwError(error) {
    handleTokenInvalid(error);
    return {
        type: UPDATE_INFO_ERROR,
        error: error
    }
}

export function loginPending() {
    return {
        type: LOGIN_PENDING
    }
}

export function loginSuccess(userdata) {
    return {
        type: LOGIN_SUCCESS,
        userdata: userdata
    }
}

export function loginError(error) {
    handleTokenInvalid(error);
    return {
        type: LOGIN_ERROR,
        error: error
    }
}

export function logoutPending() {
    return {
        type: LOGOUT_PENDING
    }
}

export function logoutSuccess() {
    return {
        type: LOGOUT_SUCCESS,
    }
}

export function logoutError(error) {
    handleTokenInvalid(error);
    return {
        type: LOGOUT_ERROR,
        error: error
    }
}

export function searchPending() {
    return {
        type: SEARCH_PENDING
    }
}

export function searchSuccess(search_results) {
    return {
        type: SEARCH_SUCCESS,
        search_results: search_results
    }
}

export function searchError(error) {
    handleTokenInvalid(error);
    return {
        type: SEARCH_ERROR,
        error: error
    }
}

export function getBibleBookNameAndChaptersPending() {
    return {
        type: GET_BIBLE_BOOK_NAME_AND_CHAPTERS_PENDING
    }
}

export function getBibleBookNameAndChaptersSuccess(bibleNames) {
    return {
        type: GET_BIBLE_BOOK_NAME_AND_CHAPTERS_SUCCESS,
        bibleNames: bibleNames
    }
}

export function getBibleBookNameAndChaptersError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_BIBLE_BOOK_NAME_AND_CHAPTERS_ERROR,
        error: error
    }
}


export function getBookmarkedVersesPending() {
    return {
        type: GET_BOOKMARKED_VERSES_PENDING
    }
}

export function getBookmarkedVersesSuccess(userdata) {
    return {
        type: GET_BOOKMARKED_VERSES_SUCCESS,
        userdata: userdata
    }
}

export function getBookmarkedVersesError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_BOOKMARKED_VERSES_ERROR,
        error: error
    }
}

export function getBiblePlansPending() {
    return {
        type: GET_BIBLE_PLANS_PENDING
    }
}

export function getBiblePlansSuccess(plansData) {

    return {
        type: GET_BIBLE_PLANS_SUCCESS,
        plansData: plansData
    }
}

export function getBiblePlansError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_BIBLE_PLANS_ERROR,
        error: error
    }
}

export function getPlanDetailsPending() {
    return {
        type: GET_BIBLE_PLAN_DETAILS_PENDING
    }
}

export function getPlanDetailsSuccess(plansData) {

    return {
        type: GET_BIBLE_PLAN_DETAILS_SUCCESS,
        plansData: plansData
    }
}

export function getPlanDetailsError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_BIBLE_PLAN_DETAILS_ERROR,
        error: error
    }
}

export function getMyPlansPending() {
    return {
        type: GET_MY_PLANS_PENDING
    }
}

export function getMyPlansSuccess(plansData) {

    return {
        type: GET_MY_PLANS_SUCCESS,
        plansData: plansData
    }
}

export function getMyPlansError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_MY_PLANS_ERROR,
        error: error
    }
}

export function getCollectedBiblesPending() {
    return {
        type: GET_COLLECTED_BIBLES_PENDING
    }
}

export function getCollectedBiblesSuccess(userdata) {
    return {
        type: GET_COLLECTED_BIBLES_SUCCESS,
        userdata: userdata
    }
}

export function getCollectedBiblesError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_COLLECTED_BIBLES_ERROR,
        error: error
    }
}

export function getCollectedMagazinesPending() {
    return {
        type: GET_COLLECTED_MAGAZINES_PENDING
    }
}

export function getCollectedMagazinesSuccess(userdata) {
    return {
        type: GET_COLLECTED_MAGAZINES_SUCCESS,
        userdata: userdata
    }
}

export function getCollectedMagazinesError(error) {
    handleTokenInvalid(error);
    return {
        type: GET_COLLECTED_MAGAZINES_ERROR,
        error: error
    }
}

export function collectBiblePending() {
    return {
        type: COLLECT_BIBLE_PENDING
    }
}

export function collectBibleSuccess(userdata) {
    return {
        type: COLLECT_BIBLE_SUCCESS
    }
}

export function collectBibleError(error) {
    handleTokenInvalid(error);
    return {
        type: COLLECT_BIBLE_ERROR
    }
}

export function collectMagazinePending() {
    return {
        type: COLLECT_MAGAZINE_PENDING
    }
}

export function collectMagazineSuccess(userdata) {
    return {
        type: COLLECT_MAGAZINE_SUCCESS
    }
}

export function collectMagazineError(error) {
    handleTokenInvalid(error);
    return {
        type: COLLECT_MAGAZINE_ERROR
    }
}

export function bookmarkVersePending() {
    return {
        type: BOOKMARK_VERSE_PENDING
    }
}

export function bookmarkVerseSuccess(userdata) {
    return {
        type: BOOKMARK_VERSE_SUCCESS
    }
}

export function bookmarkVerseError(error) {
    handleTokenInvalid(error);
    return {
        type: BOOKMARK_VERSE_ERROR
    }
}

export function updatePlanNotificationPending() {
    return {
        type: UPDATE_PLAN_NOTIFICATION_PENDING
    }
}

export function updatePlanNotificationSuccess(userdata) {
    return {
        type: UPDATE_PLAN_NOTIFICATION_SUCCESS
    }
}

export function updatePlanNotificationError(error) {
    handleTokenInvalid(error);
    return {
        type: UPDATE_PLAN_NOTIFICATION_ERROR
    }
}

export function UpdatePlanProgressPending() {
    return {
        type: UPDATE_PLAN_PROGRESS_PENDING
    }
}

export function UpdatePlanProgressSuccess(userdata) {
    return {
        type: UPDATE_PLAN_PROGRESS_SUCCESS
    }
}

export function UpdatePlanProgressError(error) {
    handleTokenInvalid(error);
    return {
        type: UPDATE_PLAN_PROGRESS_ERROR
    }
}

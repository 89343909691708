import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import './Resource.css'; 
import { RESOURCE_URL } from "../../constant/Constant";

// import VideoItem from './VideoItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import StartPlanPopup from './startPlanPopup';
import ShouldReceiveNotificationPopup from './receiveNotificationPopup';
import CustomPlanSelectDurationPopup from './customPlanSelectDurationPopup';
import CustomPlanSelectBooksPopup from './customPlanSelectBookPopup';
import CustomPlanSelectBookChaptersPopup from './customPlanSelectBookChapterPopup';
import CustomPlanPreviewPopup from './customPlanPreviewPopup';
import PlanItem from './PlanItem';

import getBiblePlansAction from '../../redux/api/getPlansAPI';
import getMyPlansAction from '../../redux/api/getMyPlansAPI';
import registerPlanAction from '../../redux/api/registerPlanAPI';
import getBibleNamesAction from '../../redux/api/getBibleNamesWithChaptersAPI';
import createPlanAction from '../../redux/api/createPlanAPI';
import removeRegisteredPlanAction from '../../redux/api/removeRegisteredPlanAPI';
import deletePlanAction from '../../redux/api/deletePlanAPI';

import CustomizedDialogs from '../common/dialog.js';

import { getValue, getLocalLocale, getLocalPlanNameKey, getLocalPlanDescKey } from "../../constant/Locale";

import { getBiblePlans, getMyPlans, getBibleNames, getCustomPlanData } from '../../redux/reducer/membershipReducer';

import "./discoverPlans.css"

class DiscoverPlans extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      activeCategory: 0,
      sortDate: 0,
      sortName: 0,
      shouldShowDialog: false,
      dialogKey: '',
      currentShowing: 'biblePlans',
      selectedBooks: [],
      selectedChapters: [],
      isModifyingPlans: false, 
      isModifyingMyPlans: false
    };
  }

  componentWillMount() {
    this.props.getBiblePlans();
    this.props.getBibleNames(getLocalLocale());


    let user = JSON.parse(sessionStorage.getItem('userInfo'));

    if (! user) {
      window.location.href = "/";
    }

    var selectedChapters = this.state.selectedChapters;

    for( var i=0; i<66; i++ ) {
      selectedChapters.push([]);
    }

    this.setState({
      selectedChapters: selectedChapters
    });
  }


  bibleCategoryTabClickEvent($cat_id) {
    this.setState({
      // selectedCategoryId: $cat_id
    })
  }

  createPlan(plan, selectedDate) {
    this.setState({ 
      'dialogKey': 'notificationPopup',
      'selectedDate': selectedDate,
      'selectedPlanId': plan['planId'],
      'numOfDays': plan['numOfDays']
    });
  }

  createCustomPlanByBookChapters(planName, planDesc, duration, totalNumberOfChapter) {
    this.setState({
      dialogKey: 'customPlanByBookChapter',
      customPlanName: planName,
      customPlanDesc: planDesc,
      duration: duration,
      shouldShowDialog: true,
    });
  }

  generatePlanByBookChapters() {
    var k = 0;
    var days = new Array(this.state.duration);
    var chapters = [];

    for( var i=0; i<days.length; i++ ) {
      days[i] = [];
    }

    for( var i=0; i<66; i++ ) {
      if( this.state.selectedChapters[i].length > 0 ) {  //have chapters

        var sortedChapters = this.state.selectedChapters[i].sort((a, b) => {
          if (a < b) { return -1; }
          if (a > b) { return 1; }
          return 0;
        })

        for( var j=0; j<sortedChapters.length; j++ ) {
          chapters.push({
            bookId: i+1,
            chapterId: this.state.selectedChapters[i][j] + 1
          });
        }
      }
    }

    var totalNumberOfChapter = chapters.length;

    for( var i=0; i<this.state.duration; i++ ) {
      var numOfChapterPerDay = Math.ceil((totalNumberOfChapter - k) / (this.state.duration - i));

      for( var j=0; j<numOfChapterPerDay; j++ ) {
        days[i].push(chapters[k]);
        k++;
      }
    }

    console.log(days);
    this.loadPreviewPopup(this.state.customPlanName, this.state.customPlanDesc, days);

  }

  createCustomPlanByBooks(planName, planDesc, duration, totalNumberOfChapter) {
    //construct the data structure and show the preview box
    var k = 0;

    var days = new Array(duration);

    for( var i=0; i<days.length; i++ ) {
      days[i] = [];
    }

    var selectedBooks = this.state.selectedBooks;
    var chapters = [];

    for( var i=0; i<selectedBooks.length; i++ ) {
      
      var bookId = selectedBooks[i];
      var bookIndex = bookId - 1;
      var chapterCnt = this.props.bibleNames[bookIndex].chapterCnt;

      for( var j=0; j<chapterCnt; j++ ) {
        chapters.push({
          bookId: bookId,
          chapterId: j+1
        });
      }
    }

    for( var i=0; i<duration; i++ ) {
      var numOfChapterPerDay = Math.ceil((totalNumberOfChapter - k) / (duration - i));

      console.log(numOfChapterPerDay);

      for( var j=0; j<numOfChapterPerDay; j++ ) {
        days[i].push(chapters[k]);
        k++;
      }
    }

    console.log(days);
    this.loadPreviewPopup(planName, planDesc, days);
  }

  loadPreviewPopup( planName, planDesc, days ) {
    this.setState({
      dialogKey: 'preview',
      customPlanName: planName,
      customPlanDesc: planDesc,
      shouldShowDialog: true,
      days: days
    });
  }

  createPlanRemotely(requireNotification) {
     
    var dateStr = this.state.selectedDate.toLocaleDateString("en-US");
    var month = dateStr.split("/")[0];
    var day = dateStr.split("/")[1];
    var year = dateStr.split("/")[2];

    var dateTimeFormatString = year + "-" + month + "-" + day ;

    this.props.registerPlan(this.state.selectedPlanId, dateTimeFormatString, requireNotification );
    this.setState({
      isModifyingPlans: true
    });
  }

  closePopup() {
    var selectedChapters = [];

    for( var i=0; i<66; i++ ) {
      selectedChapters.push([]);
    }

    this.setState({
      selectedBooks: [],
      selectedChapters: selectedChapters,
      shouldShowDialog: false
    });
  }

  dialogContent() {

    if( this.state.dialogKey == "startPlanPopup" ) {
      console.log(this.state.selectedPlanDict);
      return (
        <StartPlanPopup 
          plan={this.state.selectedPlanDict}
          close={() => this.setState({shouldShowDialog: false}) }
          coverIdx={this.state.selectedPlanCoverIndex}
          createPlan={(plan, selectedDate) => this.createPlan(plan, selectedDate)}
        />
      );
    }
    else if( this.state.dialogKey == 'customPlanByBook' ) {
      return (
        <CustomPlanSelectBooksPopup 
          bibleNames={ this.props.bibleNames }
          selectedBooks={ this.state.selectedBooks }
          close={() => this.closePopup()}
          toggleBook={(bookId) => this.toggleBook(bookId)}
          selectPlanInterval={() => this.selectPlanInterval()}
        />
      );
    }
    else if( this.state.dialogKey == 'customPlanByBookChapter' ) {
      return (
        <CustomPlanSelectBookChaptersPopup 
          bibleNames={ this.props.bibleNames }
          close={() => this.closePopup()}
          toggleChapter={(bookIndex, chapterIndex) => this.toggleChapter(bookIndex, chapterIndex)}
          setAllChapters={(bookIndex) => this.setAllChapters(bookIndex)}
          unsetAllChapters={(bookIndex) => this.unsetAllChapters(bookIndex)}
          selectedChapters={this.state.selectedChapters}
          duration={this.state.duration}
          generatePlanByBookChapters={() => this.generatePlanByBookChapters()}
        />
      );
    } 
    else if( this.state.dialogKey == 'customPlanInterval' ) {
      return ( 
        <CustomPlanSelectDurationPopup 
          bibleNames={ this.props.bibleNames }
          selectedBooks={ this.state.selectedBooks }
          close={() => this.closePopup()}
          createCustomPlanByBooks={(planName, planDesc, duration, totalNumberOfChapter) => this.createCustomPlanByBooks(planName, planDesc, duration, totalNumberOfChapter)}
        />
      );
    }
    else if( this.state.dialogKey == 'customPlanIntervalForSelectingBookChapters' ) {
      return ( 
        <CustomPlanSelectDurationPopup 
          bibleNames={ this.props.bibleNames }
          selectedBooks={ this.state.selectedBooks }
          close={() => this.closePopup()}
          createCustomPlanByBooks={(planName, planDesc, duration, totalNumberOfChapter) => this.createCustomPlanByBookChapters(planName, planDesc, duration, totalNumberOfChapter)}
        />
      );
    }  
    else if( this.state.dialogKey == 'preview' ) {
      return (
        <CustomPlanPreviewPopup 
          planName={this.state.customPlanName}
          planDesc={this.state.customPlanDesc}
          close={() => this.closePopup()}
          duration={this.state.duration}
          days={this.state.days}
          bibleNames={ this.props.bibleNames }
          createPlan={ () => {
              this.props.createPlan(this.state.customPlanName, this.state.customPlanDesc, this.state.days.length, this.state.days);
              var selectedChapters = [];

              for( var i=0; i<66; i++ ) {
                selectedChapters.push([]);
              }

              var needRegister = window.confirm(getValue("planCreated, need Register?"));

              if( needRegister ) {
                this.setState({
                  selectedBooks: [],
                  selectedChapters: selectedChapters,
                  isModifyingPlans: true,
                  shouldLoadStartPlanPopup: true
                });
              }
              else {
                this.setState({
                  selectedBooks: [],
                  selectedChapters: selectedChapters,
                  shouldShowDialog: false,
                  isModifyingPlans: true,
                });
              }

              
            }
          }
        />
      );
    }
    else if( this.state.dialogKey == "customNotification" ) {
      return (
        <ShouldReceiveNotificationPopup  
            close={() => this.setState({shouldShowDialog: false})} 
            processRegisterPlan={(requireNotification) => this.createPlanRemotely(requireNotification)}
            noOfDays={ this.state.days.length }
        />
      ); 
    }
    else {
      return (
        <ShouldReceiveNotificationPopup  
            close={() => this.setState({shouldShowDialog: false})} 
            processRegisterPlan={(requireNotification) => this.createPlanRemotely(requireNotification)}
            noOfDays={ this.state.numOfDays }
        />
      );      
    }
  }

  openCreatePlanPopup(planDict, index) {
    this.setState({ 
      dialogKey: "startPlanPopup", 
      shouldShowDialog: true, 
      selectedPlanDict: planDict,
      selectedPlanCoverIndex: index
    });
  }

  removePlan( planId ) {
    if( this.state.currentShowing == "biblePlans" ) {
      //  remove plan
      this.props.deletePlan(planId);

      setTimeout(function() {
        this.setState({
          isModifyingPlans: true
        });
      }.bind(this), 1000);
    
    }
    else {
      // remove registered plan
      this.props.removeRegisteredPlan(planId);
      
      setTimeout(function() {
        this.setState({
          isModifyingMyPlans: true
        });
      }.bind(this), 1000);
    }

  }

  loadPlanItems() {

    //planId
    //name_tc
    //name_sc
    //desc_tc
    //desc_sc
    //numOfDays
    //uid
    //isRegistered

    var planArr = [];

    this.props.biblePlans.map((dict, index) => {
        
          planArr.push(
            <PlanItem 
              id={dict['planId']}
              selectItem={() => this.openCreatePlanPopup(dict, index%4+1)}
              name={dict[getLocalPlanNameKey()]}
              desc={dict[getLocalPlanDescKey()]}
              coverIdx={index%4+1}
              isRegistered={dict['isRegistered']}
              removePlan={(planId) => this.removePlan(planId)}
              isPlanListing={this.state.currentShowing == "biblePlans"}
            />
          );
      });

    return planArr;

  }

  loadPreviewPlan(dict, index) {
    window.location.href = "/planPreview?pid=" + dict['planId'] + "&index=" + index;
  }

  loadCustomPlanPopup(key) {
    this.setState({
      shouldShowDialog: true,
      dialogKey: key
    });
  }

  selectPlanInterval() {
    this.setState({
      dialogKey: 'customPlanInterval'
    });
  }

  setAllChapters(bookIndex) {
    var selectedChapters = this.state.selectedChapters;

    selectedChapters[bookIndex] = [];

    for( var i=0; i<this.props.bibleNames[bookIndex].chapterCnt; i++ ) {
      selectedChapters[bookIndex].push(i);
    }

    this.setState({
      selectedChapters: selectedChapters
    });
  }

  unsetAllChapters(bookIndex) {
    var selectedChapters = this.state.selectedChapters;
    selectedChapters[bookIndex] = [];

    this.setState({
      selectedChapters: selectedChapters
    });
  }

  toggleChapter(bookIndex, chapterIndex) {
    var selectedChapters = this.state.selectedChapters;

    if( !selectedChapters[bookIndex].includes(chapterIndex) ) {
      selectedChapters[bookIndex].push(chapterIndex);
    }
    else {
      var index = selectedChapters[bookIndex].indexOf(chapterIndex);
      if(index != -1){
         selectedChapters[bookIndex].splice(index, 1);
      }
    }
    
    this.setState({
      selectedChapters: selectedChapters
    });
  }

  toggleBook(bookId) {
    var selectedBooks = this.state.selectedBooks;

    if( !selectedBooks.includes(bookId) ) {
      selectedBooks.push(bookId);
    }
    else {
      var index = selectedBooks.indexOf(bookId);
      if(index != -1){
         selectedBooks.splice(index, 1);
      }
    }

    this.setState({selectedBooks: selectedBooks});
  }

  loadMyPlanItems() {

    // planId":1,"startDate":"2020-07-30","name_tc":"\u9031\u5e74\u8b80\u7d93","name_sc":"\u5468\u5e74\u8bfb\u7ecf","desc_tc":"\u6309\u9806\u5e8f\u8b80:\u5f9e\u5275\u4e16\u8a18\u81f3\u555f\u793a\u9304\uff0c\u4e00\u5e74\u8b80\u5b8c\u6574\u672c\u8056\u7d93\u3002","desc_sc":"\u6309\u987a\u5e8f\u8bfb:\u4ece\u521b\u4e16\u8bb0\u81f3\u542f\u793a\u5f55\uff0c\u4e00\u5e74\u8bfb\u5b8c\u6574\u672c\u5723\u7ecf\u3002","numOfDays":365}]/
    var planArr = [];

    this.props.myBiblePlans.map((dict, index) => {
        
          planArr.push(
            <PlanItem 
              id={dict['planId']}
              selectItem={() => this.loadPreviewPlan(dict, index%4+1)}
              name={dict[getLocalPlanNameKey()]}
              desc={dict[getLocalPlanDescKey()]}
              coverIdx={index%4+1}
              removePlan={(planId) => this.removePlan(planId)}
            />


          );
      });

    return planArr;
  }

  getPlans() {
    this.props.getBiblePlans();
    this.setState({
      currentShowing: 'biblePlans'
    })
  }

   getMyPlans() {
    this.props.getMyPlans();
    this.setState({
      currentShowing: 'myPlans'
    });
  }



  render() {

    const {getBiblePlans} = this.props;

    if( this.state.shouldLoadStartPlanPopup && this.props.customPlanData.planId  ) {

      if( this.state.selectedPlanDict && this.state.selectedPlanDict.planId != this.props.customPlanData.planId ) {
          // alert(this.state.selectedPlanDict.planId);
          // alert(this.props.customPlanData.planId);
          this.setState({
            dialogKey: 'startPlanPopup',
            shouldLoadStartPlanPopup: false,
            selectedPlanDict: this.props.customPlanData
        });
      }
    }


    if( !this.props.pending && this.state.isModifyingPlans ) {
      let user = JSON.parse(sessionStorage.getItem('userInfo'));
      if( user ) {
        this.props.getBiblePlans();
      }

      console.log( this.props.customPlanData );

      if( this.state.shouldLoadStartPlanPopup ) {
         this.setState({
          isModifyingPlans: false,
          selectedPlanDict: this.props.customPlanData,
          
        });
      }
      else {
        this.setState({
          isModifyingPlans: false,
          selectedPlanDict: this.props.customPlanData,
        });
      }
    }

    if( !this.props.pending && this.state.isModifyingMyPlans ) {
      let user = JSON.parse(sessionStorage.getItem('userInfo'));
      if( user ) {
        this.props.getMyPlans();  
      }

      this.setState({
        isModifyingMyPlans: false
      });
    }


    return (
      <div>

        <Header />

        <CustomizedDialogs 
            // shouldShow={this.state.shouldShowLoginRegisterDialog && sessionStorage.getItem('userInfo') == null } 
            shouldShow={ this.state.shouldShowDialog }

            mapTitle={""}
            dialogContent={
              this.dialogContent()
            } 
            closeDialog={() => this.closePopup() }
          />

        <ButtonGroup size="large" 
                     color="primary" 
                     aria-label="large outlined primary button group" 
                     style={{ 
                       'display': 'flex',
                       'justify-content': 'center',
                       'padding': '20px'
                     }}>

          <Button className= {this.state.currentShowing == "biblePlans" ? "planSwitchBtn active" : "planSwitchBtn"}
                  onClick={() => this.getPlans()}>
                  {getValue('planSwitchDiscover')}
          </Button>
          <Button className= {this.state.currentShowing == "myPlans" ? "planSwitchBtn active" : "planSwitchBtn"}
                onClick={()=> this.getMyPlans()} >
          {getValue('planSwitchRegister')}
          </Button>
        </ButtonGroup>
        
        <div className="plansCatItem">
          <div className="plansContainer" style={{ display: 'flex', 'justify-content': 'center' }}>
          
            {
              ( this.state.currentShowing == "biblePlans" ) ? 
              this.loadPlanItems() : this.loadMyPlanItems()
            }


            {
              ( this.state.currentShowing == "biblePlans" ) ? 
              <PlanItem 
              // id={cat_item['video_id']}
              selectItem={() => this.loadCustomPlanPopup('customPlanByBook')}
              name={"自選讀經計劃 (自選書卷)"}
              desc={"定制一個適合您的讀經計劃：可自由決定計劃的長度及閱讀哪本書"}
              coverIdx={5}
              isPlanListing={this.state.currentShowing == "biblePlans"}
              /> : ""
            }

            {
              ( this.state.currentShowing == "biblePlans" ) ?
               <PlanItem 
              // id={cat_item['video_id']}
               selectItem={() => this.loadCustomPlanPopup('customPlanIntervalForSelectingBookChapters')}
               name={"自選讀經計劃 (自選章節)"}
               desc={"定制一個適合您的讀經計劃：可自由決定計劃的長度及閱讀哪本書"}
               coverIdx={5}
               isPlanListing={this.state.currentShowing == "biblePlans"}
               /> : ""
            }

            
          
          </div>

          <div className="separator">
          </div>
        </div>
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  console.log(state);
  return {
      // error: getResourceCatsError(state.resourceCategoryReducer),
      // resource_categories: getResourceCats(state.resourceCategoryReducer),
      // pending: getResourceCatsPending(state.resourceCategoryReducer)
      biblePlans: getBiblePlans(state.membershipReducer),
      myBiblePlans: getMyPlans(state.membershipReducer),
      bibleNames: getBibleNames(state.membershipReducer),
      customPlanData: getCustomPlanData(state.membershipReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getBiblePlans: getBiblePlansAction,
    getMyPlans: getMyPlansAction,
    registerPlan: registerPlanAction,
    getBibleNames: getBibleNamesAction,
    createPlan: createPlanAction,
    deletePlan: deletePlanAction,
    removeRegisteredPlan: removeRegisteredPlanAction

}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(DiscoverPlans));

import { getCollectedBiblesPending, getCollectedBiblesSuccess, getCollectedBiblesError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";

export default function getCollectedBibles() {

    let api = APP_DOMAIN+"/getCollectedBibles";

    return dispatch => {
        dispatch(getCollectedBiblesPending());

        let user = JSON.parse(sessionStorage.getItem('userInfo'));

        if( user ) {
            fetch(api, {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    token : user.token
                })
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if( res.code == "500" && res.message == "Login information is invalid" ) {
                    throw("Token invalid");
                }
                if(res.error) {
                    throw(res.error);
                }

                dispatch(getCollectedBiblesSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(getCollectedBiblesError(error));
            })
        }

        
    }
}
import { getLanguagePending, getLanguageSuccess, getLanguageError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function getLanguages() {


    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    var token = "null";
    if (user) {
        token = user.token;
    }
    let api = APP_DOMAIN+"/getLanguages";



    return dispatch => {
        dispatch(getLanguagePending());

        fetch(api)
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(getLanguageSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(getLanguageError(error));
        })
    }

    
}


import { sendForgetPwEmailPending, sendForgetPwEmailSuccess, sendForgetPwEmailError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function sendForgetPwEmail($email) {    

    let API = APP_DOMAIN+"/sendForgetPwEmail";

    return dispatch => {
        dispatch(sendForgetPwEmailPending());        

        fetch(API, SecurityHeader({
            email : $email
        }))
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }

            if( res.code ) {
                throw(res.message);
            }

            dispatch(sendForgetPwEmailSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(sendForgetPwEmailError(error));
        })
    }
}
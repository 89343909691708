import React, { useState, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../images/logoWithoutText.png';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';

import { getValue, getLocalLocale } from "../../constant/Locale";

function checkEmpty( value ) {
  return ( value.trim().length == 0 );
}

function checkEmailFormat( value ) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Profile( props ) {
  const classes = useStyles();
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  var userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

  const [email, setEmail] = useState(userInfo ? userInfo.email : "");
  const [firstName, setFirstName] = useState(userInfo ? userInfo.firstName : "");
  const [lastName, setLastName] = useState(userInfo ? userInfo.lastName : "");
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState([]);
  const [errorMsgs, setErrorMsgs] = useState({});

  const [isLocked, setIsLocked] = useState(true);

  

  function toggleLockStatus() {
    if( !isLocked ) {
      props.processChangeInfo(firstName, lastName, password);
    }

    setIsLocked(!isLocked);
  }

  function handleChange(e, fieldName) {
    if( !isLocked ) {
      
      if( fieldName == "firstName" ) {
        setFirstName(e.target.value);  
      }
      else if( fieldName == "lastName" ) {
        setLastName(e.target.value);
      }
      else if( fieldName == "password" ) {
        setPassword(e.target.value);
      }

      
    }
  }

  return (
    <Container component="main" maxWidth="xs" className="loginRegisterContainer">
      <Grid container spacing={2} className="logoutArea" onClick={() => props.processLogout()}>
        <ExitToAppIcon  />
        <label style={{position: "relative", top: '2px', left: '2px'}}>登出</label>
      </Grid>

      <CssBaseline />
      <div className={classes.paper} style={{ "margin-top": '10px'}}>
        <img src={logo} className="logo" />

          <Avatar className={classes.avatar} style={{ color: 'white', backgroundColor: '#0F514F' }}>
             
          </Avatar> 

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={(errors.includes("email") || props.error != null ) ? true : false }
              // variant="outlined"
              required
              fullWidth
              id="uid"
              label={getValue("userIdLabel")}
              name="uid"
              // autoComplete="email"
              value={ userInfo ? userInfo.uid : "" }
              readonly="readonly"
              // helperText={(errors.includes("email")) ? errorMsgs["email"] : (props.error) ? props.error : ""}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={(errors.includes("email") || props.error != null ) ? true : false }
              // variant="outlined"
              required
              fullWidth
              id="email"
              label={getValue("emailLabel")}
              name="email"
              // autoComplete="email"
              value={ userInfo ? userInfo.email : "" }
              readonly="readonly"
              // helperText={(errors.includes("email")) ? errorMsgs["email"] : (props.error) ? props.error : ""}
            />
          </Grid>

          <Grid item xs={12}>

            {
              <TextField
                error={(errors.includes("email") || props.error != null ) ? true : false }
                variant={(isLocked) ? "standard" : "outlined" }
                required
                fullWidth
                id="firstName"
                label={getValue("firstNameLabel")}
                name="firstName"
                autoComplete="firstName"
                value={ firstName }
                onChange={(e) => handleChange(e, "firstName")}
                // readonly={true}
                // helperText={(errors.includes("email")) ? errorMsgs["email"] : (props.error) ? props.error : ""}
              />
            }



            
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={(errors.includes("email") || props.error != null ) ? true : false }
              variant={(isLocked) ? "standard" : "outlined" }
              required
              fullWidth
              id="lastName"
              label={getValue("lastNameLabel")}
              name="lastName"
              // autoComplete="email"
              value={ lastName }
              onChange={(e) => handleChange(e, "lastName")}
              // helperText={(errors.includes("email")) ? errorMsgs["email"] : (props.error) ? props.error : ""}
            />
          </Grid>

          

          <Grid item xs={12}>
            <TextField
              error={(errors.includes("password")) ? true : false }
              variant={(isLocked) ? "standard" : "outlined" }
              required
              fullWidth
              name="password"
              label={getValue("passwordOrBlank")}
              type="password"
              id="password"
              // autoComplete="current-password"
              readonly="readonly"
              value = { password }
              onChange={(e) => handleChange(e, "password")}
              helperText={(errors.includes("password")) ? errorMsgs["password"] : ""}
            />
          </Grid>

          {/*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />*/}

          {
            (isLocked) ? 
            <Grid item xs={12}>
              <LockTwoToneIcon className="lockIcon" onClick={() => toggleLockStatus()} />
              <label style={{position: 'relative', top: '-4px', left: '5px'}}>{getValue("confirmEditButton")}</label>
            </Grid> : 
            <Grid item xs={12}>
              <LockOpenTwoToneIcon className="lockIcon" onClick={() => toggleLockStatus()} />
              <label style={{position: 'relative', top: '-4px', left: '5px'}}>{getValue("confirmSaveButton")}</label>
            </Grid>
          }
          
          <Grid container>
            {
              <Grid style={{ "text-align": "right" }} item xs>
               
              </Grid>
            }

            <Box mt={15}>
              
            </Box>
            <Box mt={15}>
              
            </Box>
          </Grid>


        </Grid>
      </div>
     
    </Container>
  );
}
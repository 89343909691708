import React, { Component, useRef } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getResourceCategoriesAction from '../../redux/api/resourceCategoryApi';
import { getResourceCatsError, getResourceCats, getParentCats, getResourceCatsPending } from '../../redux/reducer/resourceCategoryReducer';
import './Resource.css'; 
import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

import VideoItem from './VideoItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';

import TableSortLabel from '@material-ui/core/TableSortLabel';

import * as qs from 'query-string';

class Video extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      selectedParentCategoryId: -1,
      activeCategory: 0,
      sortDate: 0,
      sortName: 0,
    };

    this.inputRef = React.createRef();


  }

  componentDidMount(){
    let sort = -1;

    const parsed = qs.parse(window.location.search);
    var cat = parsed.cat;
    this.setState({ sortDate: sort, sortName: 0, selectedCategoryId: (cat) ? cat : -1 });

    const { getResourceCategories } = this.props;
    getResourceCategories("video",this.state.locale);

    let videoDate = sessionStorage.getItem('videoDate');
    if (videoDate) {
      this.setState({ sortDate: videoDate });
    }

    let videoName = sessionStorage.getItem('videoName');
    if (videoName) {
      this.setState({ sortName: videoName });
    }
    
  }

  componentDidUpdate() {
    if( this.inputRef.current )
      this.inputRef.current.scrollIntoView();
  }

  componentWillMount() {
    
  }


  bibleParentCategoryTabClickEvent($cat) {

    window.history.replaceState(null, "", "/video?cat=" + ($cat['children'] && $cat['children'].length > 0 ? ($cat['children'].includes(",") ? $cat['children'].split(',')[0] : $cat['children']) : -1));

    this.setState({
      selectedCategoryId: $cat['children'] && $cat['children'].length > 0 ? ($cat['children'].includes(",") ? $cat['children'].split(',')[0] : $cat['children']) : -1,
      selectedParentCategoryId: $cat
    });

  }

  bibleCategoryTabClickEvent($cat_id) {
    // window.location.href = "/video?cat=" + $cat_id;
    this.setState({
      selectedCategoryId: $cat_id
    });

    window.history.replaceState(null, "", "/video?cat=" + $cat_id);
  }


  handleDateSort() {
    let sort = (this.state.sortDate == 0) ? 1 : this.state.sortDate * -1;
    this.setState({ sortDate: sort, sortName: 0 });
    sessionStorage.setItem('videoDate',sort);
    sessionStorage.setItem('videoName',0);
  }

  handleNameSort() {
    let sort = (this.state.sortName == 0) ? 1: this.state.sortName * -1;
    this.setState({ sortDate: 0, sortName: sort });
    sessionStorage.setItem('videoName',sort);
    sessionStorage.setItem('videoDate',0);
  }

  render() {

    const {resource_categories, parent_categories, error, pending} = this.props;
    var catArr = [];
    var catTitleArr = [];

    if (resource_categories) {

      console.log(resource_categories);
      console.log(parent_categories);

      catArr = (resource_categories).sort((a,b) => {
        return (a['parent_category_sort_order'] > b['parent_category_sort_order']) ? 1 : -1
      }).reduce((catArr, cat) => {
          (catArr[cat["category_id"]] = catArr[cat["category_id"]] || []).push(cat);
          return catArr;
      },[]);


      if( this.state.selectedCategoryId == -1 && resource_categories.length > 0 ) {
        this.setState({ 
          selectedCategoryId: parent_categories[0]['children'],
          selectedParentCategoryId: parent_categories[0]
        });
      }

      if (this.state.selectedCategoryId != -1 && this.state.selectedParentCategoryId == -1 && resource_categories.length > 0 ) {
        this.setState({ selectedParentCategoryId: parent_categories.filter(item => { 
            if (item['children'].includes(',')) {
              return item['children'].indexOf(this.state.selectedCategoryId) !== -1
            } else {
              return item['children'] == this.state.selectedCategoryId.toString() 
            }
          })[0] 
        });
      }

      console.log(this.state.selectedParentCategoryId);

      catTitleArr = resource_categories.reduce((catArr, cat) => {
          (catArr[cat["parent_category_sort_order"]] = catArr[cat["parent_category_sort_order"]] || []).push(cat);
          return catArr;
      },[]);

    }

    console.log(catArr);

    return (
      <div>

        <Header />

        <div ref={this._scroller} onMouseDown={this.onScroll}
        onScroll={this.onMouseMove}>

         <ul className="categoryMenu">
        {  
          /* Cat title tab */
          (this.state.selectedParentCategoryId) ? (

            (parent_categories).map((cat, index) => {
              {/*console.log(cat);*/}
              return (
                (this.state.selectedParentCategoryId['category_id'] == cat['category_id']) ? 

                <li className="active" onClick={(e) => this.bibleParentCategoryTabClickEvent(cat)} >


                  {cat['category_name']}
                  

                </li> : 

                <li onClick={(e) => this.bibleParentCategoryTabClickEvent(cat)} >


                  {cat['category_name']}
                  

                </li>
              )
            })
          ) : ""
        }
        </ul>
        
        { (this.state.selectedParentCategoryId != -1) ?

        <ul className="categoryMenu">
        {
          /* Cat title tab */
          (!this.state.selectedParentCategoryId['children']) ? "" :
          (

              (this.state.selectedParentCategoryId['children'].includes(",")) ? 
              (
                this.state.selectedParentCategoryId['children'].split(",").map(child => {

                  return (catArr).map((cat, index) => {

                  
                    if( child.toString() == index.toString()) {

                      return (
                        (this.state.selectedCategoryId == cat[0]['category_id']) ? 

                        <li className="active" onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_id'])} >


                          {cat[0]['category_name']}
                          

                        </li> : 

                        <li onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_id'])} >


                          {cat[0]['category_name']}
                          

                        </li>
                      ) 
                    }


                  })


                })
              ) : 
              (
                (catArr).map((cat, index) => {
                
                  if( this.state.selectedParentCategoryId['children'].toString() == index.toString()) {

                    return (
                      (this.state.selectedCategoryId == cat[0]['category_id']) ? 

                      <li className="active" onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_id'])} >


                        {cat[0]['category_name']}
                        

                      </li> : 

                      <li onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_id'])} >


                        {cat[0]['category_name']}
                        

                      </li>
                    ) 
                  }


                })
              )
          )
          
          
        }
        </ul> : "" }


        </div>

        <div className="sortTable" >
          <TableSortLabel 
                active={(this.state.sortDate != 0)}
                direction={(this.state.sortDate == -1) ? 'desc' : 'asc'}
                onClick={() => this.handleDateSort() }
                > 
                <p className={(this.state.sortDate != 0) ? "active" : ""}>{getValue("resourceDate")}</p>

          </TableSortLabel>

          <TableSortLabel 
                active={(this.state.sortName != 0)}
                direction={(this.state.sortName == -1) ? 'desc' : 'asc'}
                onClick={() => this.handleNameSort() }
                > 
                <p className={(this.state.sortName   != 0) ? "active" : ""}>{getValue("resourceName")}</p>

          </TableSortLabel>
        </div>
        
        {
          /* Cat title tab */
          (this.state.selectedCategoryId > 0 && catArr.length > 0) ? (

            (catArr).map((cat, index) => {
              return (cat[0]['category_id'] != this.state.selectedCategoryId) ? "" : (
                 (

                  <div id={cat[0]['category_id']} className="videoCatItem">
                  
                    <ul className="categoryMenu">
                      <li>

                        {cat[0]['category_name']}
                        
                      </li>
                    </ul>


                    <div className="videoContainer">
                      {
                          cat.reduce((videoArr, video) => {
                              (videoArr[video.video_id] = videoArr[video.video_id] || []).push(video);
                              return videoArr;
                          },[]).sort((ap, bp) => {
                            let a = ap[0];
                            let b = bp[0];
                            if (this.state.sortDate != 0) {
                              return (new Date(a['upload_date'].replace(' ', 'T')+"Z").getTime() - new Date(b['upload_date'].replace(' ', 'T')+"Z").getTime()) * this.state.sortDate;
                            }

                            if (this.state.sortName != 0) {
                               if (this.state.sortName == 1) {
                                  return (a['video_name'].localeCompare(b['video_name'])) ;
                               }

                               if (this.state.sortName == -1) {
                                  return (b['video_name'].localeCompare(a['video_name'])) ;
                               }
                            }

                          }).map((cat_items) => {
                            const cat_item = cat_items[0];
                            const cat_item_second = cat_items[1] || {};
                            return (

                              <VideoItem 
                                id={cat_item['video_id']}
                                name={cat_item['video_name']}
                                desc={cat_item['locale_descriptions_elt']}
                                cover={RESOURCE_URL+cat_item['cover_image']}
                                url={cat_item['link']}
                                source_name={cat_item['video_source_name']}
                                second_url={(cat_item_second) ? cat_item_second['link'] : ""}
                                second_source_name={(cat_item_second) ? cat_item_second['video_source_name'] : ""}
                              />


                            )
                          })
                        
                        
                      }
                    </div>

                    <div className="separator"></div>
                  </div>

                )
              )
            })

          ) : ""
          
        }
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  console.log(state);
  return {
      error: getResourceCatsError(state.resourceCategoryReducer),
      resource_categories: getResourceCats(state.resourceCategoryReducer),
      parent_categories: getParentCats(state.resourceCategoryReducer),
      pending: getResourceCatsPending(state.resourceCategoryReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getResourceCategories: getResourceCategoriesAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Video));

import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BOOK_ID_NAME_TC, BOOK_ID_NAME_CN, CONTENT_VERSION_TC_EN, CONTENT_VERSION_CN_EN } from '../../constant/Constant';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import SearchIcon from '@material-ui/icons/Search';
import Popover from '@material-ui/core/Popover';
import BibleSearchMenu from "./BibleSearchMenu";
import { getValue, getLocalLocale } from "../../constant/Locale";

import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';

import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';

const SearchOutlineField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(OutlinedInput);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default class BibleSearchView extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
        anchorEl: '',
        setAnchorEl: '',
        tabValue: 0,
        popSearchView: false,
        startBookId: 1,
        endBookId: 66,
        singleBookId: -1,
        is_egw: 0,
        searchWord: "",
        isSearchOption: false,
        searchContentVersion: "",
        locale: getLocalLocale(),
        resetFlags: [false, false, false]
    }
  }

  setAnchorEl(e) {
    this.setState({ anchorEl: e });
  }

  searchResult() {
    let flag = (this.state.searchWord.length > 0 ) && ((this.state.tabValue == 1) ? true : (this.state.resetFlags.some((i) => i)) && (this.state.searchContentVersion.length > 0));
    
    if (!flag) {
      return;
    }

    const type = (this.state.tabValue == 0) ? "biblebook" : ((this.state.is_egw == 0) ? "resource" : "egw");
    const word = this.state.searchWord;
    const sid = (this.state.singleBookId == -1) ? this.state.startBookId : this.state.singleBookId;
    const eid = (this.state.singleBookId == -1) ? this.state.endBookId : this.state.singleBookId;
    var version = (this.state.searchContentVersion) ? this.state.searchContentVersion : getLocalLocale();
    if (type == "egw") {
      version = (getLocalLocale() == "sc" || getLocalLocale() == "cn" || getLocalLocale() == "tc" ) ? "cn" : "en";
    }
    const option = this.state.isSearchOption ? "true": "false";
    window.location = "/searchResult/"+type+"/"+sid+"/"+eid+"/"+version+"/"+option+"/"+word;
  }

  render() {

    const handleClick = (event) => {
        this.setState({ popSearchView: true, anchorEl: event.currentTarget });
    };

    const handleClose = () => {
        this.setAnchorEl(null);
        this.setState({ popSearchView: false, resetFlags: [false, false, false] });
    };

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const btnOn = (this.state.searchWord.length > 0) && ((this.state.tabValue == 1) ? true : (this.state.resetFlags.some((i) => i)) && (this.state.searchContentVersion.length > 0));

    const BOOK_ID_NAME = (this.state.locale == "tc") ? BOOK_ID_NAME_TC : BOOK_ID_NAME_CN;
    const CONTENT_VERSION = (this.state.locale == "tc") ? CONTENT_VERSION_TC_EN : CONTENT_VERSION_CN_EN;

    return (
      <div>

        <SearchIcon onClick={handleClick} style={{ color: (this.props.colors) ? this.props.colors : "black", transform: "translate(0px,6px)" }} />

        <div className={(this.state.popSearchView) ? "searchPopOverContainer" : ""} > 

          <Popover
            style={{ "marginTop": "12px" }}
              id={id}
              open={open}
              anchorEl={this.state.anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div style={{ "padding": "12px" }}>

                <SearchOutlineField 
                  fullWidth
                  placeholder={getValue('searchWord')}
                  value={this.state.searchWord}
                  onChange={(e) => {
                    this.setState({
                      searchWord: e.target.value
                    })
                  }}
                />

                  <div>

                    <Tabs
                      value={this.state.tabValue}
                      onChange={(e, ne) => {
                        this.setState({ tabValue: ne });
                      }}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                    >
                      <Tab label={getValue('searchBible')} />
                      <Tab label={getValue('searchResource')} />
                    </Tabs>

                    <div className="searchFilter">

                      <div className="searchPopOverTabPanel">

                        <div
                          role="tabpanel"
                          hidden={this.state.tabValue !== 0}
                          id={`scrollable-force-tabpanel-0`}
                          aria-labelledby={`scrollable-force-tab-0`}
                        >
                        {this.state.tabValue === 0 && (
                          <div>

                            <BibleSearchMenu 
                              placeholder={getValue('allBible')}
                              selected={this.state.resetFlags[0]}
                              hint=""
                              items={[]}
                              onItemClick={((id) => { this.setState({ startBookId: 1, endBookId: 66, singleBookId: -1, resetFlags: [true,false,false] }) })}
                            />

                            <BibleSearchMenu 
                              placeholder={getValue('singleBible')}
                              selected={this.state.resetFlags[1]}
                              hint={getValue('chooseBible')}
                              items={BOOK_ID_NAME}
                              onItemClick={((id) => { this.setState({ startBookId: 1, endBookId: 66, singleBookId: id, resetFlags: [false,true,false] }) })}
                            />
                            
                            <BibleSearchMenu 
                              placeholder={getValue('startBible')}
                              selected={this.state.resetFlags[2]}
                              hint={getValue('chooseBible')}
                              items={BOOK_ID_NAME.filter((item) => item.id < this.state.endBookId)}
                              onItemClick={((id) => { this.setState({ startBookId: id, singleBookId: -1, resetFlags: [false,false,true] }) })}
                            />

                            <BibleSearchMenu 
                              placeholder={getValue('endBible')}
                              selected={this.state.resetFlags[2]}
                              hint={getValue('chooseBible')}
                              items={BOOK_ID_NAME.filter((item) => item.id > this.state.startBookId)}
                              onItemClick={((id) => { this.setState({ endBookId: id, singleBookId: -1, resetFlags: [false,false,true] }) })}
                            />

                            <BibleSearchMenu 
                              placeholder={getValue('bibleVersion')}
                              hint={getValue('chooseBibleVersion')}
                              alwaysOn={true}
                              selected={this.state.searchContentVersion.length > 0}
                              onItemClick={((id) => { this.setState({ searchContentVersion: id }) })}
                              items={CONTENT_VERSION}
                            />

                            <BibleSearchMenu 
                              placeholder={getValue('otherOption')}
                              hint={getValue('chooseOption')}
                              selected={this.state.isSearchOption == "true"}
                              onItemClick={((id) => { this.setState({ isSearchOption: id }) })}
                              items={[{"id": "false", "name": getValue('optionReset')},{"id": "true", "name": getValue('optionCommentary')}]}
                            />

                          </div>
                        )}
                      </div>

                      <div
                        role="tabpanel"
                        hidden={this.state.tabValue !== 1}
                        id={`scrollable-force-tabpanel-1`}
                        aria-labelledby={`scrollable-force-tab-1`}
                        >
                        {this.state.tabValue === 1 && (
                          <div>

                            <BibleSearchMenu 
                              placeholder={getValue('search_tab_resource')}
                              selected={this.state.is_egw == 0}
                              hint=""
                              items={[]}
                              onItemClick={((id) => { this.setState({ is_egw: 0 }) })}
                            />

                            <BibleSearchMenu 
                              placeholder={getValue('search_tab_egw')}
                              selected={this.state.is_egw == 1}
                              hint=""
                              items={[]}
                              onItemClick={((id) => { this.setState({ is_egw: 1 }) })}
                            />
                          </div>
                        )}
                      </div>

                      <div className={(btnOn) ? "searchConfirmBtn active" : "searchConfirmBtn" } onClick={this.searchResult.bind(this)}>
                        <p>{getValue('startSearch')}</p>
                      </div>

                    </div>

                    <div className="searchList">



                    </div>

                  </div>

                </div>



              </div>
            </Popover>
      
          </div>
      </div>
    );

  }

}

import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getResourceCategoriesAction from '../../redux/api/resourceCategoryApi';
import { getResourceCatsError, getResourceCats, getResourceCatsPending } from '../../redux/reducer/resourceCategoryReducer';
import './Resource.css'; 
import { RESOURCE_URL } from "../../constant/Constant";

import MagazineItem from './MagazineItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';


class Egw extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: "tc",
      selectedCategoryId: -1,
      activeCategory: 0
    };
  }

  componentWillMount() {
    const { getResourceCategories } = this.props;
    getResourceCategories("egw",this.state.locale);
  }


  bibleCategoryTabClickEvent($cat_id) {
    this.setState({
      selectedCategoryId: $cat_id
    })
  }

  render() {

    const {resource_categories, error, pending} = this.props;
    var catArr = [];

    if (resource_categories) {

      console.log(resource_categories);

      catArr = (resource_categories).reduce((catArr, cat) => {
          (catArr[cat["category_item_id"]] = catArr[cat["category_item_id"]] || []).push(cat);
          return catArr;
      },[]);

    }

    console.log(catArr);

    return (
      <div>

        <Header />
        
        <ul className="categoryMenu">
        {
          /* Cat title tab */
          (catArr).map((cat, index) => {
            return (
              (this.state.selectedCategoryId == cat[0]['category_item_id']) ? 

              <li className="active" onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_item_id'])} >


                {cat[0]['author']}
                

              </li> : 

              <li onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_item_id'])} >


                {cat[0]['author']}
                

              </li>
            )
          })
        }
        </ul>
        <div className="booksContainer">
          {
            /* book grid  */
            (this.state.selectedCategoryId > 0) ? (
              (catArr[this.state.selectedCategoryId]).map((cat_item) => {

                return (

                  <MagazineItem 
                    id={cat_item['magazine_id']}
                    name={cat_item['name']}
                    desc={cat_item['locale_descriptions_elt']}
                    cover={RESOURCE_URL+cat_item['cover_image']}
                    url={RESOURCE_URL+cat_item['document_pdf']}
                  />


                )
              })
            ) : (
              /* show all  */
              <div></div>
            )
            
          }
        </div>
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  console.log(state);
  return {
      error: getResourceCatsError(state.resourceCategoryReducer),
      resource_categories: getResourceCats(state.resourceCategoryReducer),
      pending: getResourceCatsPending(state.resourceCategoryReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getResourceCategories: getResourceCategoriesAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Egw));

import { getEgwBookByIdPending, getEgwBookByIdSuccess, getEgwBookByIdError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function egwBookByIdAPI(locale, book_id, chap_id) {

    let api = APP_DOMAIN+"/app/getEgwBookById/"+locale+"/v2";

    // let user = JSON.parse(sessionStorage.getItem('userInfo'));

    // if( user ) {

      return dispatch => {
            dispatch(getEgwBookByIdPending());

            fetch(api, {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    // token : user.token,
                    book_id: book_id,
                    chap_id: chap_id
                })
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if( res.code == "500" && res.message == "Login information is invalid" ) {
                    throw("Token invalid");
                }
                
                if(res.error) {
                    throw(res.error);
                }

                dispatch(getEgwBookByIdSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(getEgwBookByIdError(error));
            })
        }
    // }

    
}


import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getAllBibleBooksAction from '../../redux/api/bibleApi';
import { getBibleBooksError, getBibleBooks, getBibleBooksPending } from '../../redux/reducer/bibleReducer';
import '../home/Home.css'; 
import './Map.css'; 
import { RESOURCE_URL } from "../../constant/Constant";

import MapItem from './MapItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';

import CustomizedDialogs from '../common/dialog.js';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


class BibleBookMap extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: "tc",
      selectedCategoryId: -1,
      activeCategory: 0,
      selectedImage: "",
      itemName: "",
      shouldShowDialog: false
    };
  }

  componentWillMount() {
    const { getBibleBookById } = this.props;
    let book_id = this.props.match.params.book_id;
    getBibleBookById(book_id);
  }


  bibleCategoryTabClickEvent($cat_id) {
    this.setState({
      selectedCategoryId: $cat_id
    })
  }

  updateSelectedImageEvent (url, itemName) {
    this.setState({ 
      selectedImage:url, 
      shouldShowDialog: true,
      itemName: itemName
    });
  }

  closeDialog() {
    this.setState({ shouldShowDialog: false });
  }

  dialogContent() {
    return (
      <DialogContent dividers>
      <img src={this.state.selectedImage} style={{width: '100%'}} />
      </DialogContent>
    );
  }


  render() {
    
    const {books, error, pending } = this.props;
    var mapArr = [];
    var bookName = "";

    if (books) {
      const maps = this.props.books['maps'] || [];
      const informations = this.props.books['informations'] || [];

      mapArr = maps.filter((item) => {
        return item['locale_names_idx'] == this.state.locale
      });
    }

    var content = this.dialogContent();

    return (
      <div>

        <Header />

        <CustomizedDialogs 
          shouldShow={this.state.shouldShowDialog} 
          mapTitle={this.state.itemName}
          dialogContent={content} 
          closeDialog={() => this.closeDialog()}
        />
        
        <div className="booksContainer">
          {
            (mapArr).map((item) => {

              return (

                <MapItem 
                  id={item['bible_map_id']}
                  name={item['name']}
                  desc={item['locale_names_elt']}
                  cover={RESOURCE_URL+item['image']}
                  onSelect={(url, itemName) => this.updateSelectedImageEvent(url, item['name'])}
                />

              )
            })
            
          }
        </div>

      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {
    error: getBibleBooksError(state.bibleBookReducer),
    books: getBibleBooks(state.bibleBookReducer),
    pending: getBibleBooksPending(state.bibleBookReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getBibleBookById: getAllBibleBooksAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BibleBookMap));

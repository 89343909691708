import React, { Component } from 'react';
import placeholder from '../../images/BookCoverPlaceholder.png';

import { UAT_RESOURCE_URL } from "../../constant/Constant";

class EgwBookItem extends Component {

	constructor(props, context) {
		super(props, context);
	}

	render() {
		return (

			<div className="egwBookItem">
				<div className="cover">
				{
					(this.props.cover) ? <img src={UAT_RESOURCE_URL+this.props.cover} /> : <img src={placeholder} />
				}
				</div>

				<div className="desc" >
					<h1>{this.props.name}</h1>
				</div>
			</div>

			);


	}


}

export default EgwBookItem;
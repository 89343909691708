import { collectMagazinePending, collectMagazineSuccess, collectMagazineError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";

export default function collectMagazine(magazineId, shouldCollect) {

    let api = APP_DOMAIN+"/collectMagazine";


    return dispatch => {
        dispatch(collectMagazinePending());

        let user = JSON.parse(sessionStorage.getItem('userInfo'));

        if( user ) {
            fetch(api, {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    token : user.token,
                    magazine_id: magazineId,
                    status: shouldCollect ? 1 : 0
                })
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if( res.code == "500" && res.message == "Login information is invalid" ) {
                    throw("Token invalid");
                }
                if(res.error) {
                    throw(res.error);
                }

                dispatch(collectMagazineSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(collectMagazineError(error));
            })
        }
    }
}
import React, { useState, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../images/logoWithoutText.png';

import { getValue, getLocalLocale } from "../../constant/Locale";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function checkEmpty( value ) {
  return ( value.trim().length == 0 );
}

function checkIsAlpha( value ) {
  const re = /^[A-Za-z]+$/;
  return re.test(String(value).toLowerCase())
}

function checkEmailFormat( value ) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

function textFieldOnChange(event) {
  // setFirstNameValue(event.target.value);
}
                               
export default function SignUp(props) {
  const classes = useStyles();
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState([]);
  const [errorMsgs, setErrorMsgs] = useState({});
  const [shouldShowPropsError, setShouldShowPropsError] = useState(true);
  console.log("refresh");

  function validateItem( value, item ) {
    var isValid = false;
    if( item == "firstName" || item == "lastName"  || item == "email" || item == "password" ) {
      if( checkEmpty(value) ) {
        if( !errors.includes(item) ){
          errors.push(item);
        }
        errorMsgs[item] =  getValue("emptyFieldError");
      }
      else if( (item == "firstName" || item == "lastName") && !checkIsAlpha(value) ) {
        if( !errors.includes(item) ){
          errors.push(item);
        }
        errorMsgs[item] = getValue("emailAlphabetError");
      }
      else if( (item == "email") && !checkEmailFormat(value) ) {
        if( !errors.includes(item) ){
          errors.push(item);
        }
        errorMsgs[item] = getValue("emailFormatError");
      }
      else if( (item == "password") && value.trim().length < 8 ) {
        if( !errors.includes(item) ){
          errors.push(item);
        }
        errorMsgs[item] = getValue("passwordFormatError");
      }
      else {
        for( var i = 0; i < errors.length; i++){ 
          if ( errors[i] === item) {
            errors.splice(i, 1); 
          }
        }
        isValid = true;
      }
    }

    console.log(errors);

    forceUpdate();
    return isValid;
  }

  return (
    <Container component="main" maxWidth="xs" className="loginRegisterContainer">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} className="logo" />
        <Avatar className={classes.avatar} style={{ color: 'white', backgroundColor: '#0F514F' }}>
          <LockOutlinedIcon />
        </Avatar>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField 
                error={(errors.includes("firstName")) ? true : false }
                autoComplete="fname"
                variant="outlined"
                required
                fullWidth
                label={getValue("firstNameLabel")}
                autoFocus
                
                onChange={(event) => {
                  setFirstName(event.target.value);
                  validateItem(event.target.value, "firstName");
                }
                }
                helperText={(errors.includes("firstName")) ? errorMsgs["firstName"] : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={(errors.includes("lastName")) ? true : false }
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label={getValue("lastNameLabel")}
                name="lastName"
                autoComplete="lname"
                onChange={(event) => {
                  setLastName(event.target.value);
                  validateItem(event.target.value, "lastName");
                }
                }
                helperText={(errors.includes("lastName")) ? errorMsgs["lastName"] : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={(errors.includes("email") || ( shouldShowPropsError && props.error != null )) ? true : false }
                variant="outlined"
                required
                fullWidth
                id="email"
                label={getValue("emailLabel")}
                name="email"
                autoComplete="email"
                onChange={(event) => {
                  if( props.error != null ) {
                    setShouldShowPropsError(false);
                  }
                  
                  setEmail(event.target.value);
                  validateItem(event.target.value, "email");
                  console.log(shouldShowPropsError);
                }
                }
                helperText={(errors.includes("email")) ? errorMsgs["email"] : (shouldShowPropsError && props.error != null) ? getValue(props.error) : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={(errors.includes("password")) ? true : false }
                variant="outlined"
                required
                fullWidth
                name="password"
                label={getValue("passwordLabel")}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => {
                  setPassword(event.target.value);
                  validateItem(event.target.value, "password");
                }
                }
                helperText={(errors.includes("password")) ? errorMsgs["password"] : ""}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{ backgroundColor: '#0F514F', color: 'white'}}
            onClick={() => {
              var v1 = validateItem(firstName, "firstName");
              var v2 = validateItem(lastName, "lastName");
              var v3 = validateItem(email, "email");
              var v4 = validateItem(password, "password");

              if( v1 && v2 && v3 && v4 )
              {
                setShouldShowPropsError(true);
                props.process(firstName, lastName, email, password);
              }
            }}
          >
            {getValue("registerLabel")}
          </Button>
      
      </div>
      <Box mt={5}>
       
      </Box>
    </Container>
  );
}
import React, { Component, useRef, useState } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getEgwChaptersAction from '../../redux/api/getEgwChaptersAPI';
import getEgwBookAction from '../../redux/api/egwBookByIdAPI';
import { getEgwChaptersError, getEgwChapters, getEgwChaptersPending } from '../../redux/reducer/egwChapterReducer';
import { getEgwBookByIdError, getEgwBookById, getEgwBookByIdPending } from '../../redux/reducer/egwBookByIdReducer';
import './Egw.css'; 
import { UAT_RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

import EgwBookItem from './EgwBookItem';

// import VideoItem from './VideoItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';

import playBtn from '../../images/play_button.png';
import pauseBtn from '../../images/pause_button.png';
import stopBtn from '../../images/stop_button.png';
import prevBtn from '../../images/prevIcon.png';
import nextBtn from '../../images/nextIcon.png';
import placeholder from '../../images/BookCoverPlaceholder.png';

import TableSortLabel from '@material-ui/core/TableSortLabel';

import * as qs from 'query-string';

import { useSpeechSynthesis } from 'react-speech-kit';

import SpeechBox from './SpeechBox';

class EgwChapter extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      currentChapterId: 0,
      selectedParentCategoryId: -1,
      activeCategory: 0,
      sortDate: 0,
      sortName: 0,
      clickedChapterIdx: 0,
      playState: 0,
      customLocale: 'sc'
    };

    this.audio = new Audio(null);
    this.chapFlag = false;
    this.inputRef = React.createRef();
    this.updateChapterSegment = this.updateChapterSegment.bind(this);


  }

  componentDidMount(){
    let sort = -1;

    var book_id = this.props.match.params.book_id;
    var chap_id = this.props.match.params.chap_id;

    if (chap_id && chap_id > 0) {
      this.setState({
        currentChapterId: chap_id
      });
    }

    const { getEgwBookById, getEgwChapters } = this.props;

    let customLocale = (this.state.locale == "tc" || this.state.locale == "cn" || this.state.locale == "sc") ? "sc" : "en";
    this.setState({ customLocale: customLocale });
    getEgwBookById(customLocale, book_id, 0);
    getEgwChapters(book_id,customLocale);

    
  }

  concateSegments(egw_chapters) {

    var arr = [];

    if (egw_chapters) {
      if (egw_chapters.length > this.state.clickedChapterIdx && this.state.clickedChapterIdx != -1) {
        console.log(egw_chapters[this.state.clickedChapterIdx]);
        let text = egw_chapters[this.state.clickedChapterIdx]['content'].sort((a,b) => a.sege_id - b.sege_id ).map(e => e.sege_cont).join();
        if (this.state.customLocale == 'sc') {
          arr = text.split('。');
        } else {
          arr = text.split('.');
          // arr[0] = text;
        }


        // .split( /(?<=^(?:.{50})+)(?!$)/ );
      }
    }

    // return "";
    return arr;

  }

  componentDidUpdate() {
    if( this.inputRef.current )
      this.inputRef.current.scrollIntoView();
  }

  componentWillMount() {
    
  }

  updateChapterSegment(index) {
    if (index < 0 || index > this.props.egw_chapters.length) {
      return
    }

    this.props.egw_chapters.map((item,idx) => {
      if (idx == index) {
        this.setState({
          clickedChapterIdx: index,
          playState: 0, 
          audioFileUrl: (item['audio_file_'+this.state.customLocale] !== null && item['audio_file_'+this.state.customLocale][0] !== undefined) ? UAT_RESOURCE_URL+item['audio_file_'+this.state.customLocale][0]['download_link'] : ""
        });
      }
    })

  }

  render() {

    const {egw_chapters, egw_book, error, pending} = this.props;

    var book;

    console.log(this.audio.currentTime);
    console.log(this.audio.duration);

    if (egw_book) {
      book = egw_book;
    }
    if (book) {
      if (egw_chapters[0]) {

        egw_chapters.map((item,idx) => {
          if (item.chap_id == this.state.currentChapterId) {
            if (!this.chapFlag) {
              this.setState({
                clickedChapterIdx: idx,
                playState: 0, 
                audioFileUrl: (item['audio_file_'+this.state.customLocale] !== null && item['audio_file_'+this.state.customLocale][0] !== undefined) ? UAT_RESOURCE_URL+item['audio_file_'+this.state.customLocale][0]['download_link'] : ""
              });
              this.chapFlag = true;
            }

          }
        })

        if (egw_chapters[0]['audio_file_'+this.state.customLocale]) {
          if (egw_chapters[0]['audio_file_'+this.state.customLocale][0] !== undefined && this.state.playState == 0) {
            this.audio.src = UAT_RESOURCE_URL + egw_chapters[0]['audio_file_'+this.state.customLocale][0]['download_link'];
          }
        }


      }


    console.log(this.state.playState);

      if (this.state.playState == 0 ){

        this.audio.pause();
        this.audio.currentTime = 0;
        this.audio.src = this.state.audioFileUrl;
      } 
      if (this.state.playState == -1 ) {
        if (this.audio) {
          this.audio.pause();
        }
      }
      if (this.state.playState == 1 ) {
        if (!this.audio.src) {
          this.audio.src = this.state.audioFileUrl;
        }
        this.audio.play();
      }
    }

    var catArr = [];
    var catTitleArr = [];

    return (
      <div>

        <Header />

        <div ref={this._scroller} onMouseDown={this.onScroll}
        onScroll={this.onMouseMove}>

          <div className="flexBox">

            <div>
              
              {
                (book) ? (
                  <div>
                    <img src={(book['thumbnail']) ? UAT_RESOURCE_URL+book['thumbnail'] : placeholder } />
                    {
                      (!this.state.audioFileUrl) ? <SpeechBox isStopped={this.state.clickedChapterIdx} voice={(this.state.customLocale == "sc") ? 64 : 0 } lang={(this.state.customLocale == "sc") ? "zh-CN" : "en-US"} text={this.concateSegments(egw_chapters)}></SpeechBox> : (
                        <div className="playPauseBtnBox">
                          <img className="playPauseBtn" src={(this.state.playState == 1) ? pauseBtn : playBtn}  onClick={(e) => this.setState({ playState: (this.state.playState <= 0) ? 1 : -1 })} />
                          {
                            (this.state.playState == 1) ? <img className="playPauseBtn" src={stopBtn}  onClick={(e) => this.setState({ playState: 0 })} /> : ""
                          }
                          <div className="totalTimebar" style={{ width: '100%' }}>
                            <div className="currentTimebar" style={{ width: ((this.audio.currentTime*100)/this.audio.duration)+"%" }}></div>
                          </div>
                        </div>
                      )
                    }
                    <div className="prevNextContainer">
                          <img className="prevIcon prevNextIcon" src={prevBtn} onClick={(e) => this.updateChapterSegment(this.state.clickedChapterIdx-1) } />
                          <img className="nextIcon prevNextIcon" src={nextBtn} onClick={(e) => this.updateChapterSegment(this.state.clickedChapterIdx+1) } />
                    </div>
                    <div className="linkContainer">
                      {
                        (book['pdf']) ? (
                          <div>
                            <a target="_blank" href={UAT_RESOURCE_URL+book['pdf']}>PDF</a>
                          </div>
                        ) : ""
                      }
                      {
                        (book['epub']) ? (
                          <div>
                            <a target="_blank" href={UAT_RESOURCE_URL+book['epub']}>EPUB</a>
                          </div>
                        ) : ""
                      }
                      
                    </div>
                    <div className="borderBox"><p>{(egw_chapters && egw_chapters.length > 0 && this.state.clickedChapterIdx >= 0) ? egw_chapters[this.state.clickedChapterIdx]['chap_name_'+this.state.customLocale] : ""}</p></div>
                  </div>
                ) : ""
              }
            </div>
        
            <div>
              {
                (egw_chapters || []).map((item, index) => {
                  return (
                    <div>
                      <p onClick={(e) => this.setState({ playState: (this.state.clickedChapterIdx == index) ? this.state.playState : 0, audioFileUrl: (item['audio_file_'+this.state.customLocale] !== null && item['audio_file_'+this.state.customLocale][0] !== undefined) ? UAT_RESOURCE_URL+item['audio_file_'+this.state.customLocale][0]['download_link'] : "", clickedChapterIdx: (this.state.clickedChapterIdx == index) ? -1 : index }) }>{item['chap_name_'+this.state.customLocale]}</p>
                      <div className={(index == this.state.clickedChapterIdx) ? "egwSegment active" : "egwSegment"} >
                        {
                          (item.content || []).sort((a,b) => a.sege_id - b.sege_id ).map((seg, idx) => {
                            return (
                              <div>
                                <span>{seg.sege_cont}</span>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>

          </div>

        </div>
        
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  console.log(state);
  return {
      error: getEgwChaptersError(state.egwChapterReducer),
      egw_chapters: getEgwChapters(state.egwChapterReducer),
      egw_book: getEgwBookById(state.egwBookByIdReducer),
      pending: getEgwChaptersPending(state.egwChapterReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getEgwChapters: getEgwChaptersAction,
    getEgwBookById: getEgwBookAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EgwChapter));

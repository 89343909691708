import { getSdaDictionarySearchPending, getSdaDictionarySearchSuccess, getSdaDictionarySearchError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function getSdaDictionaryByWords($word) {

    let api = APP_DOMAIN+"/getSdaDictionaryByWords";

    return dispatch => {
        dispatch(getSdaDictionarySearchPending());

        fetch(api, SecurityHeader({"word": $word}))
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(getSdaDictionarySearchSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(getSdaDictionarySearchError(error));
        })
    }
}


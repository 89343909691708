import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getBibleCategoriesAction from '../../redux/api/bibleCategoryApi';
import collectBibleAction from '../../redux/api/collectBibleApi';
import { getBibleBookCatsError, getBibleBookCats, getBibleBookCatsPending } from '../../redux/reducer/bibleBookCategoryReducer';
import { getMembershipPending } from '../../redux/reducer/membershipReducer';
import './Home.css'; 

import BookItem from './LandBookItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';

import { getValue, getLocalLocale } from "../../constant/Locale";

class Home extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      isCollecting: false
    };
    this._scroller = React.createRef();
  }

  componentWillMount() {

    const { getBibleCategories } = this.props;
    getBibleCategories(this.state.locale);

  }

  componentDidMount() { 
  }

  toggleCollection( bookId, shouldCollect ) {
    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    if( user ) {
      this.props.collectBible( bookId, shouldCollect );
    }
    this.setState({ isCollecting: true });
  }

  bibleCategoryTabClickEvent($cat_id) {
    this.setState({
      selectedCategoryId: $cat_id
    })
  }

  componentWillUpdate = (nextProps, nextState) =>{
     if(this.state.isScrolling !== nextState.isScrolling ) {
       this.toggleScrolling(nextState.isScrolling);
      }
  };

  toggleScrolling = (isEnable) => {
    if (isEnable) {
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    } else {
      window.removeEventListener('mousemove', this.onMouseMove);
    }
  };

  onScroll = (event) => {
  };

  onMouseMove = (event) => {
    const {clientX, scrollLeft, scrollTop, clientY} = this.state;
    this._scroller.current.scrollLeft = scrollLeft - clientX + event.clientX;
    this._scroller.current.scrollTop = scrollTop - clientY + event.clientY;
  };

  onMouseUp =  () => {
    this.setState({isScrolling: false, 
                   scrollLeft: 0, scrollTop: 0,
                   clientX: 0, clientY:0});
  };

  onMouseDown = (event) => {
    const {scrollLeft, scrollTop} = this._scroller.current;
    this.setState({isScrolling:true, scrollLeft, scrollTop, clientX:event.clientX, clientY:event.clientY});
  };

  render() {
    const {book_categories, error, pending, membershipPending} = this.props;
    var catArr = [];
    var catTitleArr = [];

    if( this.state.isCollecting && !membershipPending ) {
      this.setState({ isCollecting: false });
      this.props.getBibleCategories(this.state.locale);
    }

    if (book_categories) {

      catArr = (book_categories).reduce((catArr, cat) => {
          (catArr[cat["category_item_id"]] = catArr[cat["category_item_id"]] || []).push(cat);
          return catArr;
      },[]);

      if( catArr !== undefined && this.state.selectedCategoryId == -1 ) {
        (catArr).map((cat, index) => {
          if( index == 1 ) {
            console.log(cat[0]['category_item_id']);
            this.bibleCategoryTabClickEvent(cat[0]['category_item_id']);
          }
        })
        // this.bibleCategoryTabClickEvent(catArr[0]['category_item_id'])
      }
      
      catTitleArr = book_categories.reduce((catArr, cat) => {
          (catArr[cat["category_sort_order"]] = catArr[cat["category_sort_order"]] || []).push(cat);
          return catArr;
      },[]);


    }
    return (
      <div>

        <Header 
          refresh={() => this.props.getBibleCategories(this.state.locale)} 
          needRefresh={true}
        />

        <div ref={this._scroller} onMouseDown={this.onScroll}
        onScroll={this.onMouseMove} >
        <ul className="categoryMenu"  >
        {
          /* Cat title tab */
          (catTitleArr).map((cat, index) => {
            return (
              (this.state.selectedCategoryId == cat[0]['category_item_id']) ? 

              <li className="active" onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_item_id'])} >


                {cat[0]['category_name']}
                

              </li> : 

              <li onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_item_id'])} >


                {cat[0]['category_name']}
                

              </li>
            )
          })
        }
        </ul>
        </div>
        <div className="booksContainer">
          {
            /* book grid  */
            (this.state.selectedCategoryId > 0) ? (
              (catArr[this.state.selectedCategoryId]).map((cat_book_item) => {
                console.log(cat_book_item);
                return (
                  <BookItem 
                    bookId={cat_book_item['book_id']}
                    bookName={cat_book_item['book_name']}
                    verse={cat_book_item['locale_contents_elt']}
                    hasCollected={cat_book_item['hasCollected']}
                    toggleCollection={(bookId, shouldCollect) => this.toggleCollection(bookId, shouldCollect)}
                    shouldShowCollectBtn={sessionStorage.getItem('userInfo') != null}
                  />
                )
              })
            ) : (
              /* show all  */
              <div></div>
            )
            
          }
        </div>
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {
      error: getBibleBookCatsError(state.bibleBookCategoryReducer),
      book_categories: getBibleBookCats(state.bibleBookCategoryReducer),
      pending: getBibleBookCatsPending(state.bibleBookCategoryReducer),
      membershipPending: getMembershipPending(state.membershipReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getBibleCategories: getBibleCategoriesAction,
    collectBible: collectBibleAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Home));

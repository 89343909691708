import React, { useState, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../images/logoWithoutText.png';

import { getValue, getLocalLocale } from "../../constant/Locale";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RegisterSuccess( props ) {
  const classes = useStyles();
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState([]);
  const [errorMsgs, setErrorMsgs] = useState({});

  return (
    <Container component="main" maxWidth="xs" className="loginRegisterContainer">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} className="logo" />
        <Avatar className={classes.avatar} style={{ color: 'white', backgroundColor: '#0F514F' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <p style={{ "text-align": "center" }}>
              {getValue("emailSendDesc")}<br/>
              {props.email}
            </p>
            <p style={{ "text-align": "center" }}>
              {getValue("emailSendDesc4")}
               <br/>
              {getValue("emailSendDesc3")}
            </p>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ color: 'white', 'backgroundColor': '#0F514F' }}
            className={classes.submit}
            onClick={() => {
              props.close()
            }}

          >
            {getValue("confirm")}
          </Button>
          <Grid container>
            {/*<Grid item xs>
              <Link href="#" variant="body2">
                {getValue("forgotPasswordLabel")}?
              </Link>
            </Grid>*/}

            <Box mt={5}>
              
            </Box>
          </Grid>
        </Grid>
      </div>
     
    </Container>
  );
}
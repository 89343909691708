import React, { useState, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../images/logoWithoutText.png';

import { getValue, getLocalLocale } from "../../constant/Locale";

function checkEmpty( value ) {
  return ( value.trim().length == 0 );
}

function checkEmailFormat( value ) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangePw( props ) {
  const classes = useStyles();
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const [accessCode, setAccessCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, setErrors] = useState([]);
  const [errorMsgs, setErrorMsgs] = useState({});

  function validateItem( value, item ) {
    var isValid = false;
    if( item == "accessCode" || item == "password" || item == "confirmPassword" ) {
      if( checkEmpty(value) ) {
        if( !errors.includes(item) ){
          errors.push(item);
        }
        errorMsgs[item] = getValue("emptyFieldError");
      }
      else if( (item == "password" || item == "confirmPassword" ) && value.trim().length < 8 ) {
        if( !errors.includes(item) ){
          errors.push(item);
        }
        errorMsgs[item] = getValue("passwordFormatError");
      }
      else if( item == "confirmPassword"  && value != password ) {
        if( !errors.includes(item) ){
          errors.push(item);
        }
        errorMsgs[item] = getValue("passwordMismatchError"); 
      }
      else {
        for( var i = 0; i < errors.length; i++){ 
          if ( errors[i] === item) {
            errors.splice(i, 1); 
          }
        }
        isValid = true;
      }
    }

    forceUpdate();
    return isValid;
  }

  return (
    <Container component="main" maxWidth="xs" className="loginRegisterContainer">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} className="logo" />
        <Avatar className={classes.avatar} style={{ color: 'white', backgroundColor: '#0F514F' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
                error={(errors.includes("accessCode") || props.error != null ) ? true : false }
                variant="outlined"
                required
                fullWidth
                id="accessCode"
                label={getValue("enterEmailToken")}
                name="accessCode"
                autoComplete="accessCode"
                value={props.accessCode}
                helperText={(errors.includes("accessCode")) ? errorMsgs["accessCode"] : (props.error) ? props.error : ""}
              />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errors.includes("password") ? true : false }
              variant="outlined"
              required
              fullWidth
              id="password"
              label={getValue("newPasswordInput")}
              name="password"
              autoComplete="password"
              onChange={(event) => {
                setPassword(event.target.value);
                validateItem(event.target.value, "password");
              }}
              type="password"
              helperText={(errors.includes("password")) ? errorMsgs["password"] : ""}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={errors.includes("confirmPassword") ? true : false }
              variant="outlined"
              required
              fullWidth
              id="confirmPassword"
              label={getValue("confirmPasswordInput")}
              name="confirmPassword"
              autoComplete="confirmPassword"
              onChange={(event) => {
                setConfirmPassword(event.target.value);
                validateItem(event.target.value, "confirmPassword");
              }}
              type="password"
              helperText={(errors.includes("confirmPassword")) ? errorMsgs["confirmPassword"] : ""}
            />
          </Grid>

          {/*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ color: 'white', 'backgroundColor': '#0F514F' }}
            className={classes.submit}
            onClick={() => {
              var v1 = validateItem(password, "password");
              var v2 = validateItem(confirmPassword, "confirmPassword");

              if( v1 && v2 )
              {
                props.process(props.accessCode, password);
              }
            }}

          >
            {getValue("updatePassword")}
          </Button>
          <Grid container>
            {/*<Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>*/}

            <Box mt={5}>
              
            </Box>
          </Grid>
        </Grid>
      </div>
     
    </Container>
  );
}
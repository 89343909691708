import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import ROUTES, { RenderRoutes } from "./routes/routes";

import { BrowserRouter as BrowserRouter, Route, Redirect} from 'react-router-dom';
import ReactGA from 'react-ga';
import Analytics from 'react-router-ga';

const TRACKING_ID = "UA-59007761-2"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
	  <BrowserRouter>
	  	 <Analytics id="UA-59007761-2" debug>
		    <div>
			    <RenderRoutes routes={ROUTES} />
			    <div className="footer subMenu footerDesktopMenu">
				    <p><a href="mailto:feedback@sdabible.org">{ (localStorage.getItem('locale') == 'cn') ? "Comments or Feedback? 评论或回馈？ 华安联合会" : "Comments or Feedback? 評論或回饋？ 華安聯合會" }</a> Chinese Union Mission © 2022 • ALL RIGHTS RESERVED</p>
			    </div>
			    <div className="footer subMenu footerTabletMenu">
				    <p><a href="mailto:feedback@sdabible.org">{ (localStorage.getItem('locale') == 'cn') ? "Comments or Feedback? 评论或回馈？" : "Comments or Feedback? 評論或回饋？" }</a> <br/>{ (localStorage.getItem('locale') == 'cn') ? "华安联合会" : "華安聯合會" } Chinese Union Mission © 2022 • ALL RIGHTS RESERVED</p>
			    </div>
			    <div className="footer subMenu footerMobileMenu">
				    <p><a href="mailto:feedback@sdabible.org">{ (localStorage.getItem('locale') == 'cn') ? "Comments or Feedback? 评论或回馈？" : "Comments or Feedback? 評論或回饋？" }</a> <br/>{ (localStorage.getItem('locale') == 'cn') ? "华安联合会" : "華安聯合會" } Chinese Union Mission <br/>© 2022 • ALL RIGHTS RESERVED</p>
			    </div>
		    </div>
	    </Analytics>
	  </BrowserRouter>
  );
}

export default App;

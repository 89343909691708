import { getEgwChapterPending, getEgwChapterSuccess, getEgwChapterError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function getEgwChapters(book_id, locale) {

    let api = APP_DOMAIN+"/app/getEgwBookChapters/"+book_id+"/"+locale+"/v2";

    // let user = JSON.parse(sessionStorage.getItem('userInfo'));

    // if( user ) {

      return dispatch => {
            dispatch(getEgwChapterPending());

            fetch(api, {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    // token : user.token,
                    locale: locale
                })
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if( res.code == "500" && res.message == "Login information is invalid" ) {
                    throw("Token invalid");
                }
                
                if(res.error) {
                    throw(res.error);
                }

                dispatch(getEgwChapterSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(getEgwChapterError(error));
            })
        }
    // }

    
}


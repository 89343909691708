import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';

import CustomizedDialogs from '../common/dialog.js';
import Signin from '../membership/login';
import SignUp from '../membership/register';
import Profile from '../membership/profile';
import SignUpSuccess from '../membership/registerSuccess';
import ForgetPw from '../membership/forgetPw';
import ChangePw from '../membership/changePw';
import ChangePwSuccess from '../membership/changePwSuccess';
import ForgetPwSuccess from '../membership/forgetPwSuccess';
import VerifyEmailSuccess from '../membership/verifyEmailSuccess';
import BibleSearchView from './BibleSearchView';

import CircularProgress from '@material-ui/core/CircularProgress';

import registerAction from '../../redux/api/registerAPI';
import loginAction from '../../redux/api/loginAPI';
import logoutAction from '../../redux/api/logoutAPI';
import changePwAction from '../../redux/api/changePwAPI';
import changeInfoAction from '../../redux/api/changeUserInfoAPI';
import sendForgetPwEmailAction from '../../redux/api/sendForgetPwEmailAPI';
import verifyEmailAction from '../../redux/api/verifyEmailAPI';

import languageAction from '../../redux/api/getLanguagesAPI';
import contentAction from '../../redux/api/getStaticContentAPI';

import * as qs from 'query-string';


import { getValue, updateContent } from "../../constant/Locale";

import { getMembershipPending, 
	 	 getMembershipError, 
	 	 getMembershipUserInfo, 
	 	 getMembershipRegisterStatus, 
	 	 getChangePasswordSuccess,
	 	 getVerifyEmailSuccess  } from '../../redux/reducer/membershipReducer';

import { getLanguage } from '../../redux/reducer/languageReducer';
import { getStaticContent } from '../../redux/reducer/staticContentReducer';

import './header.css'; 

class Header extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	      isMenuOpen: false,
	      shouldShowLoginRegisterDialog: false,
	      shouldShowLoadingDialog: false,
	      dialogKey: '',
	      isLoggingIn: false,
	      isVerified: false

	    };
	  }

	toggleMenu = (isOpen) => (event) => {
	    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
	      return;
	    }

	    this.setState({"isMenuOpen": isOpen});
	  };

	componentDidMount() {

		this.props.getLanguage();
		this.props.getStaticContent(localStorage.getItem('locale') || "tc");

		const parsed = qs.parse(window.location.search);
		var action = parsed.q;
		var token = parsed.t;

		if( action == "forgetPw" ) {
			this.setState({
				dialogKey: 'changePw',
				changePwAccessCode: token,
				shouldShowLoginRegisterDialog: true
			});
		}
		else if( action == "verify" ) {
			//call api to verify
			this.props.verifyEmail(token);
		}
		else if( action == "login" ) {
			this.setState({
				dialogKey: 'login',
				shouldShowLoginRegisterDialog: true
			});
		}
	}

	processRegister( firstName, lastName, email, password ) {
		this.setState({ 
			registerEmail: email,
			shouldShowLoadingDialog: true 
		});
		this.props.register(firstName, lastName, email, password);
	}

	processLogin( email, password ) {
		this.setState({ shouldShowLoadingDialog: true, isLoggingIn: true });
		this.props.login(email, password);
	}

	processLogout() {
		this.props.logout();
		sessionStorage.removeItem('userInfo');
    	this.setState({ shouldShowLoginRegisterDialog: false });
    	this.forceUpdate();
    	if( this.props.needRefresh ) {
    		this.props.refresh();
    	}
	}

	processForgetPw( email ) {

		this.props.sendForgetPwEmail(email);


		this.setState({ forgetPwEmail: email, dialogKey: 'forgetPwSuccess' })
	}

	processChangePw( accessCode, newPw ) {

		//call API to update password
		this.props.changePw(accessCode, newPw);

	}

	processChangeInfo( firstName, lastName, password ) {

		//call API to update info
		this.props.changeInfo(firstName, lastName, password);


	}

	switchLocale() {
		if ((localStorage.getItem('locale') || '') == "tc") {
			localStorage.setItem('locale','cn');
		} else {
			localStorage.setItem('locale','tc');
		}
		window.location.reload(false);
	}

	switchStaticLocale(locale) {

		localStorage.setItem('locale',locale);

		window.location.reload(false);
	}

	list = () => (
	    <div
	      role="presentation"
	      className="leftMenu"
	    >
	      
	      <Divider />

	      <List>
	      	<ListItem button key={getValue('menuHome')} onClick={() => window.location.href="/"}>
	          <ListItemText primary={getValue('menuHome')} />
	        </ListItem>
	        <ListItem button key={getValue('menuBible')} onClick={() => window.location.href="/bible"}>
	          <ListItemText primary={getValue('menuBible')} />
	        </ListItem>
	        <ListItem button key={getValue('menuDictionary')} onClick={() => window.location.href="/sdaDictionary"} >
	          <ListItemText primary={getValue('menuDictionary')} />
	        </ListItem>
	        <ListItem button key={getValue('menuQA')} onClick={() => window.location.href="/topic"} >
	          <ListItemText primary={getValue('menuQA')} />
	        </ListItem>
	        
	        <ListItem button key={getValue('menuMagazine')} onClick={() => window.location.href="/magazine"} >
	          <ListItemText primary={getValue('menuMagazine')} />
	        </ListItem>
	        <ListItem button key={getValue('menuVideo')} onClick={() => window.location.href="/video"} >
	          
	          <ListItemText primary={getValue('menuVideo')} />
	        </ListItem>
	        <ListItem button key={getValue('menuEgw')} onClick={() => window.location.href="/egwBook"} >
	          
	          <ListItemText primary={getValue('menuEgw')} />
	        </ListItem>

	         { ( sessionStorage.getItem('userInfo') ) ? 
         	<ListItem button key={getValue('biblePlan')} onClick={() => window.location.href="/discoverPlans" }  >
	          <ListItemText primary={getValue('biblePlan')} />
	        </ListItem>
	         : ""}

	      </List>
	      
	      <Divider />

	      { ( sessionStorage.getItem('userInfo') ) ? 


	      	<List>
	      		<ListItem button key={getValue('bookmark')} onClick={() => window.location.href="/bookmarkedVerses" } >
		          <ListItemText primary={getValue('bookmark')} />
		        </ListItem>
		        <ListItem button key={getValue('bookCollection')} onClick={() => window.location.href="/collectedBibles" } >
		          <ListItemText primary={getValue('bookCollection')} />
		        </ListItem>
		        <ListItem button key={getValue('resourceCollection')} onClick={() => window.location.href="/collectedMagazines" } >
		          <ListItemText primary={getValue('resourceCollection')} />
		        </ListItem>
		      	<ListItem button key="繁 ｜ 簡" >
		            <span style={{ 'paddingLeft': '20px' }}>
		            	{
			            	(this.props.language).map((item, idx) => {
			            		if (idx > 0 ) {
				            		return (
				            			<b onClick={() => this.switchStaticLocale(item.locale) }> | {item.display_name}</b>
				            		)
			            		} else {
				            		return (
				            			<b onClick={() => this.switchStaticLocale(item.locale) }>{item.display_name}</b>
				            		)

			            		}
			            	})
			            }
		            </span>
		        </ListItem>
		        <ListItem button key="登出" onClick={() =>  this.processLogout()}>
		          <ListItemText primary="登出" />
		        </ListItem>
		    </List>

	      	:

	      	<List>
				<ListItem button key="註冊" onClick={() => this.setState({ dialogKey:'register', shouldShowLoginRegisterDialog: true })} >
		          <ListItemText primary="註冊" />
		        </ListItem>

		        <ListItem button key="登入" onClick={() => this.setState({ dialogKey:'login', shouldShowLoginRegisterDialog: true })} >
		          <ListItemText primary="登入" />
		        </ListItem>

		        <ListItem button key="繁 ｜ 簡" >
		          <span style={{ 'paddingLeft': '20px' }}>
		          	{
		            	(this.props.language).map((item, idx) => {
		            		if (idx > 0 ) {
			            		return (
			            			<b key={idx} onClick={() => this.switchStaticLocale(item.locale) }> | {item.display_name}</b>
			            		)
		            		} else {
			            		return (
			            			<b key={idx} onClick={() => this.switchStaticLocale(item.locale) }>{item.display_name}</b>
			            		)

		            		}
		            	})
		            }
		          </span>
		        
		        </ListItem>

		       
		      </List>

	      }

	      
	       <Divider />
	    </div>
	  );

	dialogContent() {

		if( this.props.registerSuccess ) {
			return (
				<SignUpSuccess 
					email={this.state.registerEmail}
					close={() => this.setState({shouldShowLoginRegisterDialog: false}) }
				/>
			);
		}
		else 
		{
			if( this.state.dialogKey == "register" ) {
				return (
					<SignUp 
						process={(firstName, lastName, email, password) => this.processRegister(firstName, lastName, email, password)}
						error={this.props.error} 
					/>
					
				);
			}
			else if( this.state.dialogKey == "login" ) {
				return (
					<Signin 
						process={(email, password) => this.processLogin(email, password)} 
						processForgetPw={() => this.setState({ 
							dialogKey: 'forgetPw'
						})}
						error={this.props.error}
					/>
				);
			}
			else if( this.state.dialogKey == "profile" ) {
				return (
					<Profile 
						processLogout={() => this.processLogout()} 
						processChangeInfo={(firstName, lastName, password) => this.processChangeInfo(firstName, lastName, password)}
					/>
				);
			}
			else if( this.state.dialogKey == "forgetPw" ) {
				return (
					<ForgetPw 
						process={(email) => this.processForgetPw(email)}
					/>
				);
			}
			else if( this.state.dialogKey == "changePw" ) {
				if( this.props.changePwSuccess ) {
					return (
						<ChangePwSuccess 
							processLogin={() => this.setState({ dialogKey: 'login' })}
						/>
					);
				}
				else {
					return (
						<ChangePw 
							accessCode={this.state.changePwAccessCode}
							process={(accessCode, newPw) => this.processChangePw(accessCode, newPw)}
							error={this.props.error}
						/>
					);
				}
				
			}
			else if( this.state.dialogKey == "forgetPwSuccess" ) {
				return (
					<ForgetPwSuccess 
						email={this.state.forgetPwEmail}
						close={() => this.setState({shouldShowLoginRegisterDialog: false}) }
					/>
				)
			}
			else if( this.state.dialogKey == "verifySuccess" ) {
				return (
					<VerifyEmailSuccess 
						processLogin={() => this.setState({ 
							dialogKey: 'login',
							isVerified: true
						})}
					/>
				)
			}
		}
	}

	render() {
		const {error, pending, userInfo, registerSuccess } = this.props;

		if( this.state.isLoggingIn && !pending && this.props.needRefresh ) {
			this.props.refresh();
			this.setState({ isLoggingIn: false });
		}

		if( this.props.verifyEmailSuccess && this.state.dialogKey != 'verifySuccess' && !this.state.isVerified ) {
			this.setState({
				dialogKey: 'verifySuccess',
				shouldShowLoginRegisterDialog: true
			})
		}

		return (
		<div>
			{(pending) ?
	          <div className="loadingPortal" style={{'z-index': '2000'}}>
	            <div className="loadingArea">
	              <CircularProgress />
	              <p style={{ color: 'black'}}>Loading ... </p>
	            </div>
	          </div> : ""
	        }

	        

			<CustomizedDialogs 
	          // shouldShow={this.state.shouldShowLoginRegisterDialog && sessionStorage.getItem('userInfo') == null } 
	          shouldShow={ ( this.state.shouldShowLoginRegisterDialog && 
	          			     this.state.dialogKey != 'register' && 
	          			     this.state.dialogKey != 'login' ) || 
	          	           ( this.state.shouldShowLoginRegisterDialog && 
	          	           	 ( this.state.dialogKey == 'register' || this.state.dialogKey == 'login' ) && 
	          	           	 sessionStorage.getItem('userInfo') == null)  
	          	       	 }

	          mapTitle={""}
	          dialogContent={
	          	this.dialogContent()
	          } 
	          closeDialog={() => this.setState({shouldShowLoginRegisterDialog: false}) }
	        />
			<div className="header">

	          <a href="/"><img src={logo} className="logo" /></a>
	          
	          	{ ( sessionStorage.getItem('userInfo') ) ? 
	          	<div className="headerMenu">
		            <span onClick={() => window.location.href="/bookmarkedVerses" } >{getValue('bookmark')}</span>
		            <span onClick={() => window.location.href="/collectedBibles"  } >{getValue('bookCollection')}</span>
		            <span onClick={() => window.location.href="/collectedMagazines" }>{getValue('resourceCollection')}</span>
		            <span>
		            	{
			            	(this.props.language).map((item, idx) => {
			            		if (idx > 0 ) {
				            		return (
				            			<b onClick={() => this.switchStaticLocale(item.locale) }> | {item.display_name}</b>
				            		)
			            		} else {
				            		return (
				            			<b onClick={() => this.switchStaticLocale(item.locale) }>{item.display_name}</b>
				            		)

			            		}
			            	})
			            }
		            </span>
		            {/*<span onClick={() =>  this.processLogout()}>{getValue('logout')}</span>*/}
		            <AccountCircleRoundedIcon 
		            	className="profileIcon"
		            	style={{ position: 'relative', top: '5px'}} 
		            	onClick={() => this.setState({ dialogKey:'profile', shouldShowLoginRegisterDialog: true })}
		            />


	            </div>
	            :
	            <div className="headerMenu">  
		            <span onClick={() => this.setState({ dialogKey:'register', shouldShowLoginRegisterDialog: true })} >{getValue('register')}</span>
		            <span onClick={() => this.setState({ dialogKey:'login', shouldShowLoginRegisterDialog: true })} >{getValue('login')}</span>
		            <span>
		            {
		            	(this.props.language).map((item, idx) => {
		            		if (idx > 0 ) {
			            		return (
			            			<b key={idx} onClick={() => this.switchStaticLocale(item.locale) }> | {item.display_name}</b>
			            		)
		            		} else {
			            		return (
			            			<b key={idx} onClick={() => this.switchStaticLocale(item.locale) }>{item.display_name}</b>
			            		)

		            		}
		            	})
		            }
		            </span>
		            <span>
				    	<BibleSearchView />
			    	</span>
	            </div>
	        	}

	        </div>

	        <Drawer anchor={'left'} 
	                open={this.state.isMenuOpen} 
	                onClose={this.toggleMenu(false)}>
	          {this.list()}
	        </Drawer>

	        <div className="subMenu">
	          <MenuIcon fontSize="medium" 
	          	onClick={this.toggleMenu(true)} 
	          	className="menuIcon"
	          />
	          <div class="mobileSearch">
		          <BibleSearchView colors="white" />
	          </div>
	          <ul className="subMenuList">
	            <li>
	            {(window.location.href.substring(window.location.href.lastIndexOf('/') + 1)) == "" ? 
	              <a className="active" href="/">{getValue('menuHome')}</a> : <a href="/">{getValue('menuHome')}</a>
	            } 
	            </li>
	            <li>
	            {(window.location.href.substring(window.location.href.lastIndexOf('/bible') + 1)) == "" ? 
	              <a className="active" href="/bible">{getValue('menuBible')}</a> : <a href="/bible">{getValue('menuBible')}</a>
	            } 
	            </li>
	            <li>
	            {(window.location.href.substring(window.location.href.lastIndexOf('/') + 1)) == "sdaDictionary" ? 
	              <a className="active" href="/sdaDictionary">{getValue('menuDictionary')}</a> : <a href="/sdaDictionary">{getValue('menuDictionary')}</a>
	          	}
	            </li>
	            <li>
	            {(window.location.href.substring(window.location.href.lastIndexOf('/') + 1)) == "topic" ? 
	              <a className="active" href="/topic">{getValue('menuQA')}</a> : <a href="/topic">{getValue('menuQA')}</a>
	          	}
	              
	            </li>

	            { ( sessionStorage.getItem('userInfo') ) ? 
	            <li>
	              <a href="/discoverPlans">{getValue('biblePlan')}</a>
	            </li> : "" }

	            <li>
	            {(window.location.href.substring(window.location.href.lastIndexOf('/') + 1)) == "magazine" ? 
	              <a className="active" href="/magazine">{getValue('menuMagazine')}</a> : <a href="/magazine">{getValue('menuMagazine')}</a>
	          	}
	              
	            </li>
	            <li>
	            {(window.location.href.substring(window.location.href.lastIndexOf('/') + 1)) == "video" ? 
	              <a className="active" href="/video">{getValue('menuVideo')}</a> : <a href="/video">{getValue('menuVideo')}</a>
	          	}
	            </li>
	            <li>
	            {(window.location.href.substring(window.location.href.lastIndexOf('/') + 1)) == "egwbook" ? 
	              <a className="active" href="/egwBook">{getValue('menuEgw')}</a> : <a href="/egwBook">{getValue('menuEgw')}</a>
	          	}
	            </li>
	          </ul>
	        </div>
	    </div>

		);
	}

}

const mapStateToProps = (state) => {
  return {
    error: getMembershipError(state.membershipReducer),
    pending: getMembershipPending(state.membershipReducer),
    registerSuccess: getMembershipRegisterStatus(state.membershipReducer),
    userInfo: getMembershipUserInfo(state.membershipReducer),
    changePwSuccess:  getChangePasswordSuccess(state.membershipReducer),
    verifyEmailSuccess: getVerifyEmailSuccess(state.membershipReducer),
    static_content: getStaticContent(state.staticContentReducer),
    language: getLanguage(state.languageReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  register: registerAction,
  login: loginAction,
  logout: logoutAction,
  changePw: changePwAction,
  changeInfo: changeInfoAction,
  sendForgetPwEmail: sendForgetPwEmailAction,
  verifyEmail: verifyEmailAction,
  getLanguage: languageAction,
  getStaticContent: contentAction

}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Header));
import { combineReducers } from 'redux';
import bibleBookReducer from './bibleReducer';
import bibleBookCategoryReducer from './bibleBookCategoryReducer';
import resourceCategoryReducer from './resourceCategoryReducer';
import topicReducer from './topicReducer';
import sdaDictionaryReducer from './sdaDictionaryReducer';
import membershipReducer from './membershipReducer';
import searchReducer from './searchReducer';
import landingResourceReducer from './landingResourceReducer';
import languageReducer from './languageReducer';
import staticContentReducer from './staticContentReducer';
import egwCategoryReducer from './egwCategoryReducer';
import egwChapterReducer from './egwChapterReducer';
import egwBookByIdReducer from './egwBookByIdReducer';


const rootReducer = combineReducers({
    bibleBookReducer,
    bibleBookCategoryReducer,
    resourceCategoryReducer,
    topicReducer,
    sdaDictionaryReducer,
    membershipReducer,
    searchReducer,
    landingResourceReducer,
    languageReducer,
    egwCategoryReducer,
    egwChapterReducer,
    staticContentReducer,
    egwBookByIdReducer
});

export default rootReducer;
import * as Action from '../action';

const initialState = {
    pending: false,
    changePwSuccess: false,
    userInfo: {},
    registerStatus: false,
    verifySuccess: false,
    error: null,
    bookmarkedVerses: [],
    collectedBibles: [],
    collectedMagazines: [],
    plansListing: [],
    myPlansListing: [],
    planDetails: {},
    customPlanData: {},
    bibleNames: []
}

export default function membershipReducer(state = initialState, action) {
    switch(action.type) {
        
        case Action.REGISTER_PENDING: 
        case Action.LOGIN_PENDING: 
        case Action.UPDATE_INFO_PENDING:
        case Action.CHANGE_PW_PENDING:
        case Action.VERIFY_EMAIL_PENDING:
        case Action.GET_BIBLE_PLANS_PENDING:
        case Action.REGISTER_PLAN_PENDING:
        case Action.GET_MY_PLANS_PENDING:
        case Action.GET_BIBLE_PLAN_DETAILS_PENDING:
        case Action.UPDATE_PLAN_NOTIFICATION_PENDING:
        case Action.UPDATE_PLAN_PROGRESS_PENDING:
        case Action.GET_BIBLE_BOOK_NAME_AND_CHAPTERS_PENDING:
        case Action.CREATE_PLAN_PENDING:
        case Action.DELETE_PLAN_PENDING:
        case Action.REMOVE_REGISTERED_PLAN_PENDING:
            return {
                ...state,
                pending: true
            }
        case Action.REGISTER_SUCCESS:
            return {
                ...state,
                pending: false,
                registerStatus: true,
                error: null
            }
        case Action.LOGIN_SUCCESS:
            sessionStorage.setItem('userInfo', JSON.stringify(action.userdata));
            localStorage.setItem('userInfo', JSON.stringify(action.userdata));
            return {
                ...state,
                pending: false,
                userInfo: action.userdata,
                error: null
            }

        case Action.UPDATE_INFO_SUCCESS:
            sessionStorage.setItem('userInfo', JSON.stringify(action.userdata));
            localStorage.setItem('userInfo', JSON.stringify(action.userdata));
            return {
                ...state,
                pending: false,
                userInfo: action.userdata,
                error: null
            }

        case Action.CHANGE_PW_SUCCESS:
            return {
                ...state,
                pending: false,
                changePwSuccess: true,
                userInfo: action.userdata,
                error: null
            }

        case Action.VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                pending: false,
                verifySuccess: true,
                error: null
            }
        
        case Action.GET_BIBLE_PLANS_SUCCESS: 
            return {
                ...state,
                pending: false,
                plansListing: action.plansData,
            }

        case Action.GET_MY_PLANS_SUCCESS: 
            return {
                ...state,
                pending: false,
                myPlansListing: action.plansData,
            }

        case Action.REGISTER_PLAN_SUCCESS:
            return {
                ...state,
                pending: false
            }

        case Action.GET_BIBLE_PLAN_DETAILS_SUCCESS:
            return {
                ...state,
                pending: false,
                planDetails: action.plansData
            }

        case Action.UPDATE_PLAN_NOTIFICATION_SUCCESS:
            return {
                ...state,
                pending: false
            }

        case Action.UPDATE_PLAN_PROGRESS_SUCCESS: 
            return {
                ...state,
                pending: false
            }

        case Action.GET_BIBLE_BOOK_NAME_AND_CHAPTERS_SUCCESS:
            return {
                ...state,
                pending: false,
                bibleNames: action.bibleNames
            }

        case Action.CREATE_PLAN_SUCCESS: 
            console.log( action.planData );
            return {
                ...state,
                pending: false,
                customPlanData: action.planData

            }

        case Action.DELETE_PLAN_SUCCESS: 
            return {
                ...state,
                pending: false
            }

        case Action.REMOVE_REGISTERED_PLAN_SUCCESS: 
            return {
                ...state,
                pending: false
            }

        case Action.REGISTER_ERROR:
        case Action.LOGIN_ERROR:
        case Action.GET_COLLECTED_BIBLES_ERROR:
        case Action.UPDATE_INFO_ERROR:
        case Action.VERIFY_EMAIL_ERROR:
        case Action.REGISTER_PLAN_ERROR:
        case Action.GET_BIBLE_PLANS_ERROR:
        case Action.GET_MY_PLANS_ERROR:
        case Action.GET_BIBLE_PLAN_DETAILS_ERROR:
        case Action.UPDATE_PLAN_NOTIFICATION_ERROR:
        case Action.UPDATE_PLAN_PROGRESS_ERROR:
        case Action.GET_BIBLE_BOOK_NAME_AND_CHAPTERS_ERROR:
        case Action.CREATE_PLAN_ERROR:
        case Action.DELETE_PLAN_ERROR:
        case Action.REMOVE_REGISTERED_PLAN_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case Action.GET_BOOKMARKED_VERSES_SUCCESS:
            return {
                ...state,
                pending: false,
                bookmarkedVerses: action.userdata,
            }

        case Action.GET_COLLECTED_BIBLES_SUCCESS:
            return {
                ...state,
                pending: false,
                collectedBibles: action.userdata
            }

        case Action.GET_COLLECTED_MAGAZINES_SUCCESS:
            return {
                ...state,
                pending: false,
                collectedMagazines: action.userdata
            }

        case Action.COLLECT_BIBLE_PENDING: 
        case Action.COLLECT_MAGAZINE_PENDING:
            return {
                ...state,
                pending: true,
            }
        
        case Action.COLLECT_BIBLE_SUCCESS: 
        case Action.COLLECT_MAGAZINE_SUCCESS: 
            return {
                ...state,
                pending: false,
            }

        default: 
            return state;
    }
}


export const getMembershipPending = state => state.pending;
export const getMembershipRegisterStatus = state => state.registerStatus;
export const getMembershipError = state => state.error;
export const getMembershipUserInfo = state => state.userInfo;
export const getBookmarkedVerses = state => state.bookmarkedVerses;
export const getCollectedBibles = state => state.collectedBibles;
export const getCollectedMagazines = state => state.collectedMagazines;
export const getChangePasswordSuccess = state => state.changePwSuccess;
export const getVerifyEmailSuccess = state => state.verifySuccess;
export const getCustomPlanData = state => state.customPlanData;


export const getBiblePlans = state => state.plansListing;
export const getMyPlans = state => state.myPlansListing;
export const getPlanDetails = state => state.planDetails;
export const getBibleNames = state => state.bibleNames;




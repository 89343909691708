import * as Action from '../action';

const initialState = {
    pending: false,
    resource_categories: [],
    parent_categories: [],
    error: null
}

export default function resourceCategoryReducer(state = initialState, action) {
    switch(action.type) {
        case Action.GET_RESOURCE_CATEGORY_PENDING: 
            return {
                ...state,
                pending: true
            }
        case Action.GET_RESOURCE_CATEGORY_SUCCESS:
            return {
                ...state,
                pending: false,
                resource_categories: action.resource_categories,
                parent_categories: action.parent_categories
            }
        case Action.GET_RESOURCE_CATEGORY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default: 
            return state;
    }
}

export const getResourceCats = state => state.resource_categories;
export const getParentCats = state => state.parent_categories;
export const getResourceCatsPending = state => state.pending;
export const getResourceCatsError = state => state.error;






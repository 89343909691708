import React, { Component } from 'react';
import cover1 from '../../images/bookCover1@2x.png'
import cover2 from '../../images/BookCover2@2x.png'
import cover3 from '../../images/BookCover3@2x.png'
import cover4 from '../../images/BookCover4@2x.png'
import cover5 from '../../images/BookCover5@2x.png'

import BookmarkBorderTwoToneIcon from '@material-ui/icons/BookmarkBorderTwoTone';
import BookmarkTwoToneIcon from '@material-ui/icons/BookmarkTwoTone';
import Checkbox from '@material-ui/core/Checkbox';

import CloseIcon from '@material-ui/icons/Close';

class BookItem extends Component {

	constructor(props, context) {
		super(props, context);
	}

	toggleCollection( shouldCollect ) {
		this.props.toggleCollection( this.props.bookId, shouldCollect );
	}

	removeItem() {
		if( this.props.type == "collect" ) {
			this.props.cancelCollect(this.props.bookId);
		}
		else if( this.props.type == "bookmark" ) {
			this.props.cancelBookmark(this.props.verseId);
		}
	}

	handleCheckboxChange() {
		this.props.handleCheckboxChange(this.props.index);
	}

	handleCoverOnClick() {
		if( this.props.shouldShowCheckbox ) {
			if( !this.props.shouldChecked ) {
				this.props.handleSelectCheckbox(this.props.index);
			}
		}

		window.location.href="/bibleBook/" + this.props.bookId;
	}

	render() {

		var bookURL = "/bibleBook/" + this.props.bookId;
		if( this.props.chapterId ) {
			bookURL += "/" + this.props.chapterId;
		}
		console.log(this.props.hasCollected);
		return (
			<div className="bookItem" style={{ position: 'relative' }}>

				{( this.props.shouldShowCollectBtn && this.props.hasCollected ) ? 
				 <BookmarkTwoToneIcon 
				 	className="collectBtn"
					style={{ position: 'absolute', top: '5px', right: '5px', color: '#155251'}} 
					fontSize="large" 
					onClick={() => this.toggleCollection(false)}
				  /> : 
				  ( this.props.shouldShowCollectBtn ) ?
				  <BookmarkBorderTwoToneIcon 
				  	className="collectBtn"
					style={{ position: 'absolute', top: '5px', right: '5px', color: '#155251'}} 
					fontSize="large" 
					onClick={() => this.toggleCollection(true)}
				  /> : ""
				}

				{( this.props.shouldShowCloseBtn ) ? 
					<CloseIcon 
						className="closeBtn"
						style={{ position: 'absolute', top: '5px', right: '5px', color: '#155251'}} 
						fontSize="large"  
						onClick={() => this.removeItem()}
					/> : ""
				}
				
				
				<div className="cover" onClick={() => this.handleCoverOnClick() }>
					{
						((this.props.bookId >= 1 && this.props.bookId <=5 ) || this.props.bookId == 66 ) ? 
						<img src={cover1} onClick={() => this.handleCoverOnClick() } /> : 
						((this.props.bookId >= 6 && this.props.bookId <=17 ) || ( this.props.bookId >= 59 && this.props.bookId <= 65 ))  ? 
						<img src={cover2} onClick={() => this.handleCoverOnClick() } /> :
						((this.props.bookId >= 18 && this.props.bookId <= 22 ) || ( this.props.bookId >= 45 && this.props.bookId <= 58 ))  ? 
						<img src={cover3} onClick={() => this.handleCoverOnClick() } /> :
						((this.props.bookId >= 23 && this.props.bookId <=27 ) || ( this.props.bookId == 44 ))  ? 
						<img src={cover4} onClick={() => this.handleCoverOnClick() } /> : 
						<img src={cover5} onClick={() => this.handleCoverOnClick() } /> 
					}
					
					<p>{this.props.bookName.trim()}</p>
				</div>
				<div className="desc" >
					{ this.props.shouldShowCheckbox ?
					  <Checkbox
				        checked={this.props.shouldChecked }
				        onChange={() => this.handleCheckboxChange()}
				        className="checkbox"
				        style={{ position: 'absolute', right: '0' }}
				        inputProps={{ 'aria-label': 'primary checkbox' }}
				      /> : ""
					} 
					
					<h1>{this.props.chapter ? "第" + this.props.chapter + "章" : this.props.bookName.trim()}</h1>
					<p>{this.props.verse}</p>
				</div>
				
			</div>

			);


	}


}

export default BookItem;
import { verifyEmailPending, verifyEmailSuccess, verifyEmailError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";

export default function verifyEmail($token) {    

    let API = APP_DOMAIN+"/verifyEmail";

    return dispatch => {
        dispatch(verifyEmailPending());

        fetch(API, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                token : $token
            })
        })
        .then(res => {
            dispatch(verifyEmailSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(verifyEmailError(error));
        })
    }
}
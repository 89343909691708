import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getResourceCategoriesAction from '../../redux/api/resourceCategoryApi';
import collectMagazineAction from '../../redux/api/collectMagazineApi';
import { getResourceCatsError, getResourceCats, getParentCats, getResourceCatsPending } from '../../redux/reducer/resourceCategoryReducer';
import { getMembershipPending } from '../../redux/reducer/membershipReducer';

import './Resource.css'; 
import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";


import MagazineItem from './MagazineItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';

import TableSortLabel from '@material-ui/core/TableSortLabel';

import * as qs from 'query-string';


class Magazine extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      selectedParentCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      sortDate: 0,
      sortName: 0,
      isCollecting: false
    };
    this._scroller = React.createRef();
  }

  componentDidMount() {
    const { getResourceCategories } = this.props;
    getResourceCategories("magazine",this.state.locale);

    let magazineDate = sessionStorage.getItem('magazineDate');
    if (magazineDate) {
      this.setState({ sortDate: magazineDate });
    }

    let magazineName = sessionStorage.getItem('magazineName');
    if (magazineName) {
      this.setState({ sortName: magazineName });
    }

    let sort = -1;

    const parsed = qs.parse(window.location.search);
    var cat = parsed.cat;
    if( cat ) {
      this.setState({ sortDate: sort, sortName: 0, selectedCategoryId: cat });
    }
    else
      this.setState({ sortDate: sort, sortName: 0 });
    
  }

  bibleParentCategoryTabClickEvent($cat) {

    window.history.replaceState(null, "", "/magazine?cat=" + ($cat['children'] && $cat['children'].split(',').length > 0 ? $cat['children'].split(',')[0] : -1));

    console.log($cat['children']);

    this.setState({
      selectedCategoryId: $cat['children'] && $cat['children'].split(',').length > 0 ? $cat['children'].split(',')[0] : -1,
      selectedParentCategoryId: $cat
    })

  }

  bibleCategoryTabClickEvent($cat_id) {
    this.setState({
      selectedCategoryId: $cat_id
    })

    window.history.replaceState(null, "", "/magazine?cat=" + $cat_id);
  }

  componentWillUpdate = (nextProps, nextState) =>{
     if(this.state.isScrolling !== nextState.isScrolling ) {
       this.toggleScrolling(nextState.isScrolling);
      }
  };

  toggleScrolling = (isEnable) => {
    if (isEnable) {
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    } else {
      window.removeEventListener('mousemove', this.onMouseMove);
    }
  };

  onScroll = (event) => {
  };

  onMouseMove = (event) => {
    const {clientX, scrollLeft, scrollTop, clientY} = this.state;
    this._scroller.current.scrollLeft = scrollLeft - clientX + event.clientX;
    this._scroller.current.scrollTop = scrollTop - clientY + event.clientY;
  };

  onMouseUp =  () => {
    this.setState({isScrolling: false, 
                   scrollLeft: 0, scrollTop: 0,
                   clientX: 0, clientY:0});
  };

  onMouseDown = (event) => {
    const {scrollLeft, scrollTop} = this._scroller.current;
    this.setState({isScrolling:true, scrollLeft, scrollTop, clientX:event.clientX, clientY:event.clientY});
  };

  toggleCollection(magazineId, shouldCollect) {
    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    if( user ) {
      this.props.collectMagazine( magazineId, shouldCollect );
    }
    this.setState({ isCollecting: true });

  }

  handleDateSort() {
    let sort = (this.state.sortDate == 0) ? 1 : this.state.sortDate * -1;
    this.setState({ sortDate: sort, sortName: 0 });
    sessionStorage.setItem('magazineDate',sort);
    sessionStorage.setItem('magazineName',0);
  }

  handleNameSort() {
    let sort = (this.state.sortName == 0) ? 1: this.state.sortName * -1;
    this.setState({ sortDate: 0, sortName: sort });
    sessionStorage.setItem('magazineName',sort);
    sessionStorage.setItem('magazineDate',0);
  }

  render() {

    const {resource_categories, parent_categories, error, pending, membershipPending} = this.props;
    var catArr = [];
    var catTitleArr = [];

    // if( !membershipPending && this.state.isCollecting ) {
    //   this.props.getResourceCategories("magazine",this.state.locale);
    //   this.setState({ isCollecting: false });
    // }

    if (resource_categories) {

      console.log(resource_categories);

      catArr = (resource_categories).sort((a,b) => {
        if (this.state.sortDate != 0) {
          return (new Date(a['issue_date'].replace(' ', 'T')+"Z").getTime() - new Date(b['issue_date'].replace(' ', 'T')+"Z").getTime()) * this.state.sortDate;
        }

        if (this.state.sortName != 0) {
           if (this.state.sortName == 1) {
              return (a['magazine_name'].localeCompare(b['magazine_name'])) ;
           }

           if (this.state.sortName == -1) {
              return (b['magazine_name'].localeCompare(a['magazine_name'])) ;
           } 
        }

      }).reduce((catArr, cat) => {
          (catArr[cat["category_id"]] = catArr[cat["category_id"]] || []).push(cat);
          return catArr;
      },[]);

      console.log(catArr);

      if( this.state.selectedCategoryId == -1 && resource_categories.length > 0 ) {
        this.setState({ 
          selectedCategoryId: parent_categories[0]['children'].split(',')[0],
          selectedParentCategoryId: parent_categories[0]
        });
      }

      if (this.state.selectedCategoryId != -1 && this.state.selectedParentCategoryId == -1 && resource_categories.length > 0 ) {
        this.setState({ selectedParentCategoryId: parent_categories.filter(item => { return item['children'].indexOf(this.state.selectedCategoryId) !== -1 })[0] });
      }

      catTitleArr = resource_categories.reduce((catArr, cat) => {
          (catArr[cat["category_sort_order"]] = catArr[cat["category_sort_order"]] || []).push(cat);
          return catArr;
      },[]);

      console.log( parent_categories );

    }

    return (
      <div>

        <Header 
          refresh={() => this.props.getResourceCategories("magazine",this.state.locale)}
          needRefresh={true}
        />
        
        <div ref={this._scroller} onMouseDown={this.onScroll}
        onScroll={this.onMouseMove}>


        <ul className="categoryMenu">
        {  
          /* Cat title tab */
          (parent_categories).map((cat, index) => {
            {/*console.log(cat);*/}
            return (
              (this.state.selectedParentCategoryId['category_id'] == cat['category_id']) ? 

              <li className="active" onClick={(e) => this.bibleParentCategoryTabClickEvent(cat)} >


                {cat['category_name']}
                

              </li> : 

              <li onClick={(e) => this.bibleParentCategoryTabClickEvent(cat)} >


                {cat['category_name']}
                

              </li>
            )
          })
        }
        </ul>


        { (this.state.selectedParentCategoryId != -1) ?

        <ul className="categoryMenu">
        {
          /* Cat title tab */
          (!this.state.selectedParentCategoryId['children']) ? "" :
          (
            this.state.selectedParentCategoryId['children'].split(",").map(child => {

              return (catArr).map((cat, index) => {

              
                if( child.toString() == index.toString()) {

                  return (
                    (this.state.selectedCategoryId == cat[0]['category_id']) ? 

                    <li className="active" onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_id'])} >


                      {cat[0]['category_name']}
                      

                    </li> : 

                    <li onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_id'])} >


                      {cat[0]['category_name']}
                      

                    </li>
                  ) 
                }


              })


            })
          )
          
          
        }
        </ul> : "" }
        </div>

        <div className="sortTable" >
          <TableSortLabel 
                active={(this.state.sortDate != 0)}
                direction={(this.state.sortDate == -1) ? 'desc' : 'asc'}
                onClick={() => this.handleDateSort()}
                > 
                <p className={(this.state.sortDate != 0) ? "active" : ""}>{getValue("resourceDate")}</p>

          </TableSortLabel>

          <TableSortLabel 
                active={(this.state.sortName != 0)}
                direction={(this.state.sortName == -1) ? 'desc' : 'asc'}
                onClick={() => this.handleNameSort() }
                > 
                <p className={(this.state.sortName   != 0) ? "active" : ""}>{getValue("resourceName")}</p>

          </TableSortLabel>
        </div>


        <div className="booksContainer">
          {
            /* book grid  */
            (this.state.selectedCategoryId > 0 && catArr.length > 0) ? (
              (catArr[this.state.selectedCategoryId] || []).map((cat_item) => {

                return (

                  <MagazineItem 
                    id={cat_item['magazine_id']}
                    name={cat_item['magazine_name']}
                    desc={cat_item['locale_descriptions_elt']}
                    cover={RESOURCE_URL+cat_item['cover_image']}
                    url={RESOURCE_URL+cat_item['document_pdf']}
                    hasCollected={cat_item['hasCollected']}
                    toggleCollection={(magazineId, shouldCollect) => this.toggleCollection(magazineId, shouldCollect)}
                    shouldShowCollectBtn={sessionStorage.getItem('userInfo') != null}
                  />


                )
              })
            ) : (
              /* show all  */
              <div></div>
            )
            
          }
        </div>
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  console.log(state);
  return {
      error: getResourceCatsError(state.resourceCategoryReducer),
      resource_categories: getResourceCats(state.resourceCategoryReducer),
      parent_categories: getParentCats(state.resourceCategoryReducer),
      pending: getResourceCatsPending(state.resourceCategoryReducer),
      membershipPending: getMembershipPending(state.membershipReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getResourceCategories: getResourceCategoriesAction,
    collectMagazine: collectMagazineAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Magazine));

import React, { Component } from 'react';
import cover1 from '../../images/BiblePlanCovers/1.jpg'
import cover2 from '../../images/BiblePlanCovers/2.jpg'
import cover3 from '../../images/BiblePlanCovers/3.jpg'
import cover4 from '../../images/BiblePlanCovers/4.jpg'
import cover5 from '../../images/BiblePlanCovers/5.jpg'

import CloseIcon from '@material-ui/icons/Close';
import { getValue, getLocalLocale } from "../../constant/Locale";

import './PlanItem.css'; 

class PlanItem extends Component {

	constructor(props, context) {
		super(props, context);
	}

	handlePlanItemOnClick() {

		if( !this.props.isRegistered ) {
			this.props.selectItem();
		}
	}

	render() {
		let cover;

		if( this.props.coverIdx == 1 ) {
			cover = cover1;			
		}
		else if( this.props.coverIdx == 2 ) {
			cover = cover2;
		}
		else if( this.props.coverIdx == 3 ) {
			cover = cover3;
		}
		else if( this.props.coverIdx == 4 ) {
			cover = cover4;
		}
		else {
			cover = cover5;
		}

		return (
			<div className={this.props.isRegistered ? "planItem registered" : "planItem"} >
				{(this.props.isPlanListing && this.props.id > 4 && this.props.coverIdx < 5) || !this.props.isPlanListing  ? 
						<CloseIcon onClick={() => {
						this.closeBtnOnClick = true;
						var confirmToRemove = window.confirm(getValue("removePlan?"));
						if( confirmToRemove ) {
							this.props.removePlan(this.props.id);
						}
					}} className="closeBtn" /> : "" }

				<div onClick={() => this.handlePlanItemOnClick()}>
					<div className="cover">
						<img src={cover} /> 
					</div>
					<div className="desc" >
						<h1>{this.props.name}</h1>
						<p>{this.props.desc}</p>
					</div>
				</div>
				
			</div>
		);
	}
}

export default PlanItem;
import * as Action from '../action';

const initialState = {
    pending: false,
    books: [],
    verses: [],
    secondVerses: [],
    chains: [],
    content_version: '',
    secondary_content_version: '',
    error: null
}

export default function bibleBookReducer(state = initialState, action) {
    switch(action.type) {
        case Action.GET_BIBLE_BOOK_PENDING: 
            return {
                ...state,
                pending: true
            }
        case Action.GET_BIBLE_BOOK_SUCCESS:
            return {
                ...state,
                pending: false,
                books: action.books
            }
        case Action.GET_BIBLE_BOOK_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case Action.GET_BIBLE_VERSE_PENDING: 
            return {
                ...state,
                pending: true
            }
        case Action.GET_BIBLE_VERSE_SUCCESS:
            return {
                ...state,
                pending: false,
                verses: action.verses,
                content_version: action.content_version
            }
        case Action.GET_BIBLE_SECONDARY_VERSE_SUCCESS:
            return {
                ...state,
                pending: false,
                secondary_verses: action.secondary_verses,
                secondary_content_version: action.secondary_content_version
            }
        case Action.GET_BIBLE_VERSE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case Action.GET_BIBLE_CHAIN_BY_VERSE_PENDING: 
            return {
                ...state,
                pending: true
            }
        case Action.GET_BIBLE_CHAIN_BY_VERSE_SUCCESS:
            return {
                ...state,
                pending: false,
                chains: action.chains
            }
        case Action.GET_BIBLE_CHAIN_BY_VERSE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default: 
            return state;
    }
}

export const getBibleBooks = state => state.books;
export const getBibleBooksPending = state => state.pending;
export const getBibleBooksError = state => state.error;
export const getBibleVerses = state => state.verses;
export const getBibleSecondaryVerses = state => state.secondary_verses;
export const getBibleContentVersion = state => state.content_version;
export const getBibleSecondaryContentVersion = state => state.secondary_content_version;
export const getBibleChainByVerse = state => state.chains;






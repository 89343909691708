import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getAllTopicsAction from '../../redux/api/bibleTopicApi';
import getAllTopicSearchAction from '../../redux/api/bibleTopicSearchApi';
import { getTopicsError, getTopics, getTopicsPending, getSearchTopics } from '../../redux/reducer/topicReducer';
import './Topic.css'; 
import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';

import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Autocomplete from '@material-ui/lab/Autocomplete';


import TopicItem from './TopicItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';

const SearchOutlineField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(OutlinedInput);

var refs = [];
const handleTopicSearchItemClick = id => {
  refs[id].current.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
  return id;
}

class Topic extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      selectedTopicId: 1,
      activeCategory: 0,
      searchedWord: "",
      searchList: [],
    };
    this.catContainer = React.createRef();
  }

  componentWillMount() {
    const { getAllTopics } = this.props;
    getAllTopics(this.state.locale);
  }


  bibleCategoryTabClickEvent($cat_id) {
    this.setState({
      selectedCategoryId: $cat_id
    })
  }

  topicItemClickEvent($topic_id) {

  }

  onTextChanged(e) {
    console.log(e.target.value);
    this.setState({ searchedWord: e.target.value });
  }

  render() {

    const {topics, error, pending, search_topics} = this.props;
    var catArr = [];
    var searchWords = [];

    if (search_topics) {
      console.log(search_topics);
      searchWords = search_topics.reduce((t, i) => { return t.concat(i['name']) }, []);
    }

    if (topics) {

      catArr = (topics).reduce((catArr, cat) => {
          (catArr[cat["pin_yin"].charCodeAt(0)] = catArr[cat["pin_yin"].charCodeAt(0)] || []).push(cat);
          return catArr;
      },[]);

      refs = topics.reduce((acc, value) => {
        acc[value['id']] = React.createRef();
        return acc;
      }, {});

    }

    return (
      <div>

        <Header />
        
        <div className="topicSearchContainer">

        <FormControl fullWidth variant="outlined">
          <Autocomplete
            value={''}
            onChange={(event, newValue) => {
              
              if (newValue) {
                topics.map((i) => {
                  if (i['name'] === newValue) {
                    
                    const pin = handleTopicSearchItemClick(i['id']);
                    this.setState({ selectedTopicId: i['id'] });
                  }
                });
              }

            }}
            inputValue={this.state.searchedWord}
            onInputChange={(event, newInputValue) => {
              this.setState({ searchedWord: newInputValue });
              if (newInputValue.trim()) {
                this.props.getTopicsbyWords((newInputValue.trim()));
              }
            }}
            id="controllable-states-demo"
            options={searchWords}
            style={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label={getValue('searchWord')} variant="outlined" />}
          />
        </FormControl>

        </div>
        <div ref={this.catContainer}>
        {
          /* Cat title tab */
          (catArr).map((cat) => {
            return (
              <div className="topicCatItem">
              
                <div className="topicCatContainer" >
                  <span>{cat[0]['pin_yin']}</span>
                </div>

                <div className="topicContainer">
                  {
                      cat.map((item) => {
                        return (
                          <div ref={refs[item['id']]} style={{ scrollMargin: (this.state.selectedTopicId < item['id']) ? "500px" : "0" }} className="topicItem" onClick={(e) => (this.setState({ selectedTopicId: item['id'] })) }>
                            <div className={(this.state.selectedTopicId == item['id']) ? "topicContent active" : "topicContent"}>
                              <p>{item['locale_titles_elt']}</p>
                              <div className="teaser" dangerouslySetInnerHTML={{ __html: item['locale_teasers_elt'] }}  ></div>
                              <p className="source">{getValue('source')}：<a href={item['locale_urls_elt']} target="_blank">{item['locale_urls_elt']}</a></p>
                            </div>
                          </div>
                        )
                      })
                    
                  }
                </div>

              </div>

            )
          })
        }
        </div>
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {
      error: getTopicsError(state.topicReducer),
      topics: getTopics(state.topicReducer),
      search_topics: getSearchTopics(state.topicReducer),
      pending: getTopicsPending(state.topicReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getAllTopics: getAllTopicsAction,
    getTopicsbyWords: getAllTopicSearchAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Topic));

import React, { Component } from 'react';

const localeJson = 
{
	"tc": {
		"appTitle": "",
		//menu
		"menuHome": "主頁 HOME",
		"menuBible": "聖經註釋 Bible Commentaries",
		"menuDictionary": "聖經詞典 Bible Dictionary",
		"menuQA": "聖經問答 Bible Q&A",
		"menuMagazine": "文字資源 Literature",
		"menuVideo": "影音資源 Media",
		//header
		"landing": "主頁",
		"register": "註冊",
		"login": "登入",
		"logout": "登出",
		"bookmark": "我的書籤",
		"bookCollection": "我的收藏(聖經)",
		"resourceCollection": "我的收藏(雜誌)",
		"plan": "我的計劃",
		"bibleBook": "聖經註釋",
		"sdaDictionary": "聖經詞典",
		"topic": "聖經問答",
		"biblePlan": "讀經計劃",
		"magazine": "文字資源",
		"video": "影音資源",
		"otherResource": "其他資源",
		//search
		"searchWord": "搜尋用字",
		"searchBible": "聖經書卷",
		"searchResource": "聖經資源",
		"allBible": "全部書卷",
		"singleBible": "單卷書卷",
		"chooseBible": "請選擇一本聖經",
		"startBible": "開始卷",
		"endBible": "結束卷",
		"bibleVersion": "聖經版本",
		"chooseBibleVersion": "請選擇聖經版本",
		"otherOption": "其他選項",
		"chooseOption": "請選擇其他選項",
		"optionReset": "重設",
		"optionCommentary": "含註釋",
		"startSearch": "開始搜尋",
		"searchRecord": "搜尋結果",
		"shortCommentary": "註",
		//share
		"shareTitle": "分享內容",
		//topic
		"source": "來源",
		//sda dictionary
		"originPronoun": "原文音譯",
		"originDefinition": "原文意譯",
		"definition": "詞解",
		//resource
		"resourceDate": "日期",
		"resourceName": "名稱",
		//book
		"menuSetting": "快速設定",
		"chainReference": "串珠",
		"definition": "詞解",
		"dot": "加點",
		"commentary": "註釋",
		"translate": "中英對照",
		"map": "地圖",
		"summary": "概論",
		"information": "資料",
		"collectBook": "收藏聖經",
		"select": "選取",
		"selectAll": "全選",
		"selectCancel": "取消選取",
		"share": "分享",
		"bookmark": "加入書籤",
		"chooseVersion": "選擇版本",
		"fontSize": "字體大小",
		"bibleInformation": "聖經資料",
		"shareContent": "分享內容",
		"chooseBibleInformation": "請選擇聖經資料",
		"browse": "閱覽",
		"chooseShareVerse": "請選擇要分享的經文",
		"chainReferenceShare": "串珠分享",
		"confirm": "確認",
		"chooseBibleVersionCh": "請選擇聖經版本 （中文）",
		"chooseBibleVersionEn": "請選擇聖經版本 （英文）",
		//member
		"emptyFieldError": "該字段不能為空",
		"emailFormatError": "電子郵件格式無效",
		"emailAlphabetError": "該字段只能包含字母值",
		"passwordFormatError": "密碼長度不得少於8個字符",
		"passwordMismatchError": "密碼/確認密碼不匹配",
		"emailLabel": "電子郵件地址",
		"passwordLabel": "密碼",
		"forgotPasswordLabel": "忘記密碼",
		"loginLabel": "登入",
		"firstNameLabel": "名",
		"lastNameLabel": "姓",
		"registerLabel": "註冊",
		"validateEmailLabel": "請驗證您的電子郵件",
		"emailSendDesc": "我們發送了一封電子郵件至",
		"emailSendDesc2": "只需點擊該電郵中的鏈接即可完成註冊。",
		"emailSendDesc3": "如果沒有看到該電郵，請檢查您的垃圾郵件文件夾。",
		"emailSendDesc4": "只需點擊該電郵中的鏈接即可重設密碼。",
		"confirmEmailLabel": "您的電郵已確認",
		"clicktoLogin": "請點擊下面的按鈕登錄。",
		"confirmPasswordLabel": "您的密碼已更新",
		"userIdLabel": "用戶號碼",
		"passwordOrBlank": "密碼 (留空為不更改密碼)",
		"confirmEditButton": "請點擊鎖按鈕進行更改",
		"confirmSaveButton": "請點擊鎖保存您的更改",
		"enterEmailToken": "請輸入您電郵的代碼",
		"newPasswordInput": "請輸入您新的密碼",
		"confirmPasswordInput": "請再次輸入您新的密碼",
		"updatePassword": "更新密碼",
		//plan
		"planSwitchDiscover": "探索計劃",
		"planSwitchRegister": "我的計劃",
		"selfPlanBookTitle": "自選讀經計劃 (自選書卷)",
		"selfPlanBookDesc": "定制一個適合您的讀經計劃：可自由決定計劃的長度及閱讀哪本書",
		"selfPlanChapterTitle": "自選讀經計劃 (自選章節)",
		"selfPlanChapterDesc": "定制一個適合您的讀經計劃：可自由決定計劃的長度及閱讀哪本書",
		"bibleFavourite": "我的收藏(聖經)",
		"readPlan": "閱讀計劃",
		"remaining": "還有",
		"startDate": "開始日期",
		"endDate": "完成日期",
		"notify": "提醒",
		"stopPlan": "停止計劃",
		"receiveNotificatioMessage": "你是否需要每天收到讀經計劃的提醒嗎？",
		"need": "需要",
		"noNeed": "不需要",
		"choosePlanStartDate": "請選擇計劃開始日期",
		"startPlan": "開始計劃",
		"chooseDuration": "請選擇計劃的持續時間",
		"startDraftPlan": "開始定制計劃",
		"planNameLabel": "計劃名稱",
		"planDescLabel": "計劃描述",
		"addBible": "添加聖經",
		"addChapter": "添加章節",
		"createPlan": "建立計劃",
		"removePlan?": "您確定要刪除該計劃嗎?",
		"planCreated, need Register?": "閱讀計劃己成功創建，你要立即開始閱讀計劃嗎？",
		"Email address already registered": "電子郵件地址已經被註冊"
	},
	"cn": {
		"appTitle": "",
		//menu,
		"menuHome": "主页 HOME",
		"menuBible": "圣经注释 Bible Commentaries",
		"menuDictionary": "圣经词典 Bible Dictionary",
		"menuQA": "圣经问答 Bible Q&A",
		"menuMagazine": "文字资源 Literature",
		"menuVideo": "影音资源 Media",
		//heade
		"landing": "主頁",
		"register": "注册",
		"login": "登入",
		"logout": "登出",
		"bookmark": "我的书签",
		"bookCollection": "我的书签",
		"resourceCollection": "我的收藏(杂志)",
		"plan": "我的计划",
		"bibleBook": "圣经注释",
		"sdaDictionary": "圣经词典",
		"topic": "圣经问答",
		"biblePlan": "读经计划",
		"magazine": "文字资源",
		"video": "影音资源",
		"otherResource": "其他资源",
		//search
		"searchWord": "搜寻用字",
		"searchBible": "圣经书卷",
		"searchResource": "圣经资源",
		"allBible": "全部书卷",
		"singleBible": "单卷书卷",
		"chooseBible": "请选择一本圣经",
		"startBible": "开始卷",
		"endBible": "结束卷",
		"bibleVersion": "圣经版本",
		"chooseBibleVersion": "请选择圣经版本",
		"otherOption": "其他选项",
		"chooseOption": "请选择其他选项",
		"optionReset": "重设",
		"optionCommentary": "含注释",
		"startSearch": "开始搜寻",
		"searchRecord": "搜寻结果",
		"shortCommentary": "注",
		//share
		"shareTitle": "分享內容",
		//topic
		"source": "来源",
		//sda dictionary
		"originPronoun": "原文音译",
		"originDefinition": "原文意译",
		"definition": "词解",
		//resource
		"resourceDate": "日期",
		"resourceName": "名称",
		//book
		"menuSetting": "快速设定",
		"chainReference": "串珠",
		"definition": "词解",
		"dot": "加点",
		"commentary": "注释",
		"translate": "中英对照",
		"map": "地图",
		"summary": "概论",
		"information": "资料",
		"collectBook": "收藏圣经",
		"select": "选取",
		"selectAll": "全选",
		"selectCancel": "取消选取",
		"share": "分享",
		"bookmark": "加入书签",
		"chooseVersion": "选择版本",
		"fontSize": "字体大小",
		"bibleInformation": "圣经资料",
		"shareContent": "分享内容",
		"chooseBibleInformation": "请选择圣经资料",
		"browse": "阅览",
		"chooseShareVerse": "请选择要分享的经文",
		"chainReferenceShare": "串珠分享",
		"confirm": "确认",
		"chooseBibleVersionCh": "请选择圣经版本 （中文）",
		"chooseBibleVersionEn": "请选择圣经版本 （英文）",
		//member
		"emptyFieldError": "该字段不能为空",
		"emailFormatError": "电子邮件格式无效",
		"emailAlphabetError": "该字段只能包含字母值",
		"passwordFormatError": "密码长度不得少于8个字符",
		"passwordMismatchError": "密码/确认密码不匹配",
		"emailLabel": "电子邮件地址",
		"passwordLabel": "密码",
		"forgotPasswordLabel": "忘记密码",
		"loginLabel": "登入",
		"firstNameLabel": "名",
		"lastNameLabel": "姓",
		"registerLabel": "注册",
		"validateEmailLabel": "请验证您的电子邮件",
		"emailSendDesc": "我们发送了一封电子邮件至",
		"emailSendDesc2": "只需点击该电邮中的链接即可完成注册。",
		"emailSendDesc3": "如果没有看到该电邮，请检查您的垃圾邮件文件夹。",
		"emailSendDesc4": "只需点击该电邮中的链接即可重设密码。",
		"confirmEmailLabel": "您的电邮已确认",
		"clicktoLogin": "请点击下面的按钮登录。",
		"confirmPasswordLabel": "您的密码已更新",
		"userIdLabel": "用户号码",
		"passwordOrBlank": "密码 (留空为不更改密码)",
		"confirmEditButton": "请点击锁按钮进行更改",
		"confirmSaveButton": "请点击锁保存您的更改",
		"enterEmailToken": "请输入您电邮的代码",
		"newPasswordInput": "请输入您新的密码",
		"confirmPasswordInput": "请再次输入您新的密码",
		"updatePassword": "更新密码",
		//plan
		"planSwitchDiscover": "探索计划",
		"planSwitchRegister": "我的计划",
		"selfPlanBookTitle": "自选读经计划 (自选书卷)",
		"selfPlanBookDesc": "定制一个适合您的读经计划：可自由决定计划的长度及阅读哪本书",
		"selfPlanChapterTitle": "自选读经计划 (自选章节)",
		"selfPlanChapterDesc": "定制一个适合您的读经计划：可自由决定计划的长度及阅读哪本书",
		"bibleFavourite": "我的收藏(圣经)",
		"readPlan": "阅读计划",
		"remaining": "还有",
		"startDate": "开始日期",
		"endDate": "完成日期",
		"notify": "提醒",
		"stopPlan": "停止计划",
		"receiveNotificatioMessage": "你是否需要每天收到读经计划的提醒吗？",
		"need": "需要",
		"noNeed": "不需要",
		"choosePlanStartDate": "请选择计划开始日期",
		"startPlan": "开始计划",
		"chooseDuration": "请选择计划的持续时间",
		"startDraftPlan": "开始定制计划",
		"planNameLabel": "计划名称",
		"planDescLabel": "计划描述",
		"addBible": "添加圣经",
		"addChapter": "添加章节",
		"createPlan": "建立计划",
		"removePlan?": "您确定要删除该计划吗?",
		"planCreated, need Register?": "阅读计划己成功创建，你要立即开始阅读计划吗？",
		"Email address already registered": "电子邮件地址已经被注册"
	}
};

export function updateContent(locale, content) {
	localeJson[locale] = content;
}

export const getLocalPlanNameKey = () => {
	return (localStorage.getItem('locale') == 'tc') ? "name_tc" : "name_sc";
}

export const getLocalPlanDescKey = () => {
	return (localStorage.getItem('locale') == 'tc') ? "desc_tc" : "desc_sc";
}

export const getLocalLocale = () => {
	return localStorage.getItem('locale') || 'tc';
}

export const getValue = (key) => {
	const locale = localStorage.getItem('locale') || 'tc';
	if (!localeJson[locale]) return "";
	return (localeJson[locale][key]) ? localeJson[locale][key] :  "";
}
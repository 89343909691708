import React, { Component } from 'react';
import cover1 from '../../images/bookCover1@2x.png'
import cover2 from '../../images/BookCover2@2x.png'
import cover3 from '../../images/BookCover3@2x.png'
import cover4 from '../../images/BookCover4@2x.png'
import cover5 from '../../images/BookCover5@2x.png'

class TopicItem extends Component {

	constructor(props, context) {
		super(props, context);
	}

	render() {
		return (

			<div className="videoItem">
				<div className="cover">
					<img src={this.props.cover} /> 
					<div className="dim">
						<a target="_blank" href={this.props.url}>
							<span>{this.props.source_name}</span>
						</a>
						{
							(this.props.second_url) ? 
							<a target="_blank" href={this.props.second_url}>
								<span>{this.props.second_source_name}</span>
							</a> : ""
						}
					</div>
				</div>

				<div className="desc" >
					<h1>{this.props.name}</h1>
					<p>{this.props.desc}</p>
				</div>
			</div>

			);


	}


}

export default TopicItem;
import { registerPlanPending, registerPlanSuccess, registerPlanError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";

export default function registerPlan($planId, $startDate, $requireNotification) {

    let API = APP_DOMAIN+"/registerPlan";
    let user = JSON.parse(sessionStorage.getItem('userInfo'));

    return dispatch => {
        dispatch(registerPlanPending());

        var today = new Date();  
        var time = today.getHours() + ":" + today.getMinutes();

        fetch(API, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                token : user.token,
                planId: $planId,
                startDate: $startDate,
                requireNotification: $requireNotification ? "1" : "0",
                notificationTime: time
            })
        })
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }

            if( res.code ) {
                throw(res.message);
            }

            dispatch(registerPlanSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(registerPlanError(error));
        })
    }
}
import { getBibleBookPending, getBibleBookSuccess, getBibleBookError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export  function getAllBibleBooks() {

    let domain = APP_DOMAIN+"/getAllBibleBooks";

    return dispatch => {
        dispatch(getBibleBookPending());
        fetch(domain, SecurityHeader())
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(getBibleBookSuccess(res));
            return res;
        })
        .catch(error => {
            console.log(error);
            dispatch(getBibleBookError(error));
        })
    }
}

export default function getBibleBookById(book_id, locale) {

    let domain = APP_DOMAIN+"/getBibleBook/"+book_id+"/"+locale;
    console.log(domain);

    return dispatch => {
        dispatch(getBibleBookPending());
        fetch(domain, SecurityHeader())
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(getBibleBookSuccess(res));
            return res;
        })
        .catch(error => {
            console.log(error);
            dispatch(getBibleBookError(error));
        })
    }
}


import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getSdaDictionaryAction from '../../redux/api/sdaDictionaryApi';
import getSdaDictionarySearchAction from '../../redux/api/sdaDictionarySearchApi';
import getSdaDictionaryShortAction from '../../redux/api/sdaDictionaryShortApi';
import { getSdaDictionariesError, getSdaDictionaries, getSdaDictionariesPending, getSearchSdaDictionaries } from '../../redux/reducer/sdaDictionaryReducer';
import '../topics/Topic.css'; 
import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';

import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';


var refs = [];
const handleDicsSearchItemClick = id =>
refs[id].current.scrollIntoView({
  behavior: 'smooth',
  block: 'start',
});

class SdaDictionary extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      selectedTopicId: 1,
      activeCategory: 0,
      searchedWord: "",
      searchList: [],
    };
  }

  componentWillMount() {
    const { getShortSdaDictionaries, getAllSdaDictionaries } = this.props;
    getShortSdaDictionaries(this.state.locale);
    getAllSdaDictionaries(this.state.locale);
  }


  bibleCategoryTabClickEvent($cat_id) {
    this.setState({
      selectedCategoryId: $cat_id
    })
  }

  topicItemClickEvent($topic_id) {

  }

  onTextChanged(e) {
    console.log(e.target.value);
    this.setState({ searchedWord: e.target.value });
  }

  render() {

    const {dictionaries, error, pending, search_dictionaries} = this.props;
    var catArr = [];
    var searchWords = [];

    if (search_dictionaries) {
      searchWords = search_dictionaries.reduce((t, i) => { return t.concat(i['name']) }, []);
    }



    if(dictionaries) {
      catArr = dictionaries;

      refs = dictionaries.reduce((acc, value) => {
        acc[value['id']] = React.createRef();
        return acc;
      }, {});

    }

    return (
      <div>

        <Header />
        
        <div className="topicSearchContainer">

        <FormControl fullWidth variant="outlined">
          <Autocomplete
            value={''}
            onChange={(event, newValue) => {
              
              if (newValue) {
                dictionaries.map((i) => {
                  if (i['name'] === newValue) {
                    handleDicsSearchItemClick(i['id']);
                    this.setState({ selectedTopicId: i['id'] });
                  }
                });
              }

            }}
            inputValue={this.state.searchedWord}
            onInputChange={(event, newInputValue) => {
              this.setState({ searchedWord: newInputValue });
              if (newInputValue.trim()) {
                this.props.getSdaDictionaryByWords(newInputValue.trim());
              }
            }}
            id="controllable-states-demo"
            options={searchWords}
            style={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label={getValue('searchWord')} variant="outlined" />}
          />
        </FormControl>

        </div>

        <div className="topicCatItem dicCatItem">
          <div className="topicContainer">
          {
            /* Cat title tab */
            
            (catArr).map((item) => {

              return (

                <div ref={refs[item['id']]} className="topicItem" onClick={(e) => (this.setState({ selectedTopicId: item['id'] })) }>
                  <div className={(this.state.selectedTopicId == item['id']) ? "topicContent active" : "topicContent" }>
                    <p className="smallText">{item['locale_words_elt']}</p>
                    <p className="title">{item['name']}</p>
                    <p className=""><b>{getValue('originPronoun')}：</b>{item['origin_pronunciation']}</p>
                    <p className=""><b>{getValue('originDefinition')}：</b> {(item['origin_meaning'] == "NULL") ? '' : item['origin_meaning']}</p>
                    <p className=""><u><b>{getValue('definition')}</b></u></p>
                    <div className="teaser" dangerouslySetInnerHTML={{ __html: item['definition'] }}  ></div>
                    <p className="source"></p>
                  </div>
                </div>

              )

            })

          }
          </div>
        </div>

      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {
      error: getSdaDictionariesError(state.sdaDictionaryReducer),
      dictionaries: getSdaDictionaries(state.sdaDictionaryReducer),
      search_dictionaries: getSearchSdaDictionaries(state.sdaDictionaryReducer),
      pending: getSdaDictionariesPending(state.sdaDictionaryReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getAllSdaDictionaries: getSdaDictionaryAction,
    getShortSdaDictionaries: getSdaDictionaryShortAction,
    getSdaDictionaryByWords: getSdaDictionarySearchAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SdaDictionary));

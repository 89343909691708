
import React, { useState, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../images/logoWithoutText.png';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import './PlanPopup.css'; 

import { getValue, getLocalLocale } from "../../constant/Locale";

import cover from '../../images/BiblePlanCovers/5.jpg';
import BibleBookItem from './bibleBookItem';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CustomPlanSelectBookPopup( props ) {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function generateBookNames() {
    var bookNames = [];

    props.bibleNames.map((bible, index) => (
      bookNames.push(
        <BibleBookItem 
          bookId={bible.bookId}
          bookName={bible.bookName}
          selected={props.selectedBooks.includes(bible.bookId) ? true : false }
          toggleBook={(bookId) => props.toggleBook(bookId)}
        />
      )
    ));
    return bookNames;
  }

  return (
    <div className="customPlanBookRootDiv">
      <div className="bannerDiv customPlanBookDiv">
        <img className="banner" src={cover} />
        <p>自選讀經計劃 (自選書卷)</p>
        <CloseIcon className="closeBtn" onClick={() => props.close()}/>
      </div>

      <Container component="main" maxWidth="xs" className="loginRegisterContainer">
          
          <div className={classes.paper} style={{ marginTop: '0px'}}>
            <h1>添加聖經</h1>
            <Grid className="bookGrid" container spacing={2} style={{
                  display: 'flex',
                  'justify-content': 'center',            
            }}>
              
              {generateBookNames()}


            </Grid>
            <Grid container spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ color: 'white', 'backgroundColor': '#0F514F', 'opacity': props.selectedBooks.length > 0 ? "1" : "0.6" }}
                className={classes.submit}
                onClick={() => {
                  if( props.selectedBooks.length > 0 )
                    props.selectPlanInterval();
                }}

              >
                {props.selectedBooks.length > 0 ? getValue("addBible") + " (" + props.selectedBooks.length + ")" : getValue("addBible")} 
              </Button>

            </Grid>
          </div>
         
        </Container>
    </div>
    
  );
}

import React, { useState, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../images/logoWithoutText.png';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import './PlanPopup.css'; 

import { getValue, getLocalLocale } from "../../constant/Locale";

import cover from '../../images/BiblePlanCovers/5.jpg';
import BibleBookItem from './bibleBookItem';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import BookItem from '../home/BookItem';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CustomPlanPreviewPopup( props ) {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedDay, setSelectedDay] = React.useState(0);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function generateDays() {

    var daysToShow = Array();

    if( props.days ) {
      props.days.map((day, index) => 
        daysToShow.push(

          <div className={index == selectedDay ? "selected" : "" } onClick={() => dayChanged(index)}><p>第{index+1}日</p></div>
        )
      );
    }

    return daysToShow;
  }

  function dayChanged(index) {
    setSelectedDay(index);
  }

  function loadBookItems() {

    var booksToShow = Array();

    if( props.days ) {
      var day = props.days[selectedDay];
      console.log(day);
      day.map((chapter, index) => 

        booksToShow.push(
           <BookItem 
              bookName={props.bibleNames[chapter.bookId-1].bookName}
              bookId={ chapter.bookId }
              verse={ props.bibleNames[chapter.bookId-1].firstVerses[chapter.chapterId-1].v }
              chapter={ chapter.chapterId }
           />
        )
      );
    }

    return booksToShow;


  }

  return (
    <div className="customPlanPreviewRootDiv">
      <div className="bannerDiv customPlanBookDiv">
        <img className="banner" src={cover} />
        <p>{props.planName}</p>
        <h5>{props.planDesc}</h5>
        <CloseIcon className="closeBtn" onClick={() => props.close()}/>
      </div>

      <Container component="main" maxWidth="xs" className="loginRegisterContainer">
          
          <div className={classes.paper} style={{ marginTop: '0px'}}>
            <Grid className="bookGrid" container spacing={2} style={{
                  display: 'flex',
                  'justify-content': 'center',            
            }}>

            <div className="daysDiv">
              { generateDays() }
            </div>
              
            </Grid>

            <Grid container spacing={2} style={{ display: 'flex', 'maxHeight': '150px', 'overflow-y': 'scroll'}}>
              {loadBookItems()}
            </Grid>


            <Grid container spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ color: 'white', 'backgroundColor': '#0F514F' }}
                className={classes.submit}
                onClick={() => {
                  props.createPlan();
                }}

              >
                {getValue("createPlan")}
              </Button>

            </Grid>
          </div>
         
        </Container>
    </div>
    
  );
}
import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getAllBibleBooksAction from '../../redux/api/bibleApi';
import getBibleVerseAction from '../../redux/api/bibleVerseApi';
import getBibleChainByVerseAction from '../../redux/api/bibleChainAPI';
import bookmarkVersesAction from '../../redux/api/bookmarkVersesApi';

import { getBibleBooksError, getBibleBooks, getBibleVerses, getBibleSecondaryVerses, getBibleBooksPending, getBibleChainByVerse, getBibleContentVersion, getBibleSecondaryContentVersion } from '../../redux/reducer/bibleReducer';
import { getMembershipPending } from '../../redux/reducer/membershipReducer';
import './BibleBook.css'; 
import { RESOURCE_URL, IOSSwitch, zeroPad, useStyles } from "../../constant/Constant";

import ScrollMenu from 'react-horizontal-scrolling-menu';
import Header from '../common/Header';

import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import MapIcon from '@material-ui/icons/Map';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FavoriteIcon from '@material-ui/icons/Favorite';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ShareIcon from '@material-ui/icons/Share';
import UpdateIcon from '@material-ui/icons/Update';
import CloseIcon from '@material-ui/icons/Close';

import CustomizedDialogs from '../common/dialog.js';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { getValue, getLocalLocale } from "../../constant/Locale";


import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  WeiboShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

const selected = 'item1';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



class BibleBook extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      content_verison: getLocalLocale(),
      second_content_version: "",
      chapter: -1,
      chain_reference_flag: (window.sessionStorage.getItem("chain_reference_flag") == 'true'),
      dictionary_flag: (window.sessionStorage.getItem("dictionary_flag") == 'true'),
      dot_flag: (window.sessionStorage.getItem("dot_flag") == 'true'), 
      commentary_flag: (window.sessionStorage.getItem("commentary_flag") == 'true'),
      is_both_lang_available_flag: (window.sessionStorage.getItem("is_both_lang_available_flag") == 'true'), 
      isMenuOpen: false,
      shouldShowLoading: false,
      selectedChapter: -1,
      size: 18,
      shouldDisplayCheckbox: false,
      shouldShowDialog: false,
      selectedVerse: -1,
      selectedVerseContent: '',
      selectedVerseCrIdx: -1,
      dialogKey: '',
      selectedInformation: '',
      selectedSummary: '',
      checked: [],
      isBookmarking: false,
      shouldShowBookmarkCompleteSnackBar: false
    };
  }

  componentWillMount() {

    const { getAllBibleBooks, getBibleBookById } = this.props;
    let book_id = this.props.match.params.book_id;
    var target_content_version = null;
    if (this.props.match.params.content_version) {
      target_content_version = this.props.match.params.content_version;
      this.setState({ content_verison: this.props.match.params.content_version });
    }

    getBibleBookById(book_id,this.state.locale);

    let chapter_id = this.props.match.params.chapter_id;

    if( chapter_id ) {
      this.getBibleVerseEvent( chapter_id, true, target_content_version );

      this.setState({"selectedChapter": chapter_id });
    }



  }

  getBibleVerseEvent(chapter_id, should_fetch_primary_content, target_content_version) {

    if( target_content_version ) {
      this.props.getBibleVerse(chapter_id,target_content_version,this.state.locale, should_fetch_primary_content);
    }
    else {
      if( should_fetch_primary_content )
        this.props.getBibleVerse(chapter_id,this.state.content_verison,this.state.locale, should_fetch_primary_content);  
      else 
        this.props.getBibleVerse(chapter_id,this.state.second_content_version,this.state.locale, should_fetch_primary_content);  
    }

    
    this.setState({ chapter: chapter_id });
  }

  onSelect = key => {
    this.setState({ selected: key });
    console.log(this.state.selected);
  }

  splice(string, idx, rem, str) {
    const charArr = string.split('');
    charArr.splice(idx, rem, str);
    const newStr = charArr.join('');
    return newStr;
  }

  spannableContent (verse, crIdx) {

    if (!verse) return [];

    var verseContent = verse['content']['0']['locale_contents_elt'];
    var positionArr = [];

    if (this.state.dictionary_flag) {

      verse['dictionary'].map((dict) => {
        if (dict['verse_dictionary_position']) {
          positionArr.push({
            "position": dict['verse_dictionary_position']-1,
            "length": dict['verse_dictionary_length'],
            "replace": verseContent.substring(dict['verse_dictionary_position']-1,dict['verse_dictionary_position']+dict['verse_dictionary_length']-1),
            "type": "dict"
          });
        }
      });

      positionArr = positionArr.filter((v,i,s) => {
        return s.map(x => x.position).indexOf(v.position) == i
      });
    }

    if (this.state.chain_reference_flag) {
      verse['chain_reference'].map((cr) => {
        if (cr['position']) {
          crIdx++;
          positionArr.push({
            "position": cr['position']-1,
            "length": cr['length'],
            "replace": ""+crIdx,
            "type": "cr"
          });
        }
      });
    }

    if (this.state.dot_flag) {

      verse['dot'].map((dot) => {
        if (dot['position']) {
          if (positionArr.some(obj => (obj.position >= dot['position'] && obj.position <= dot['position']+dot['length'] ))) {

            //split the dot string to two parts if collapsed with cr/dict

          } else {

            positionArr.push({
              "position": dot['position']-1,
              "length": dot['length'],
              "replace": verseContent.substring(dot['position']-1,dot['position']+dot['length']-1),
              "type": "dot"
            });

          }
        }
      });

    }


    positionArr.sort((a,b) => {
      return b.position - a.position
    }).map((obj) => {

      var open = "";
      var close = "";

      switch (obj.type) {
        case "dot":
          open = '<span class="dot" style="color: green;">';
          close = "</span>";
          break;
        case "dict":
          open = '<span class="dict" style="color: blue;">';
          close = "</span>";
          break;
        case "cr":
          open = '<span class="cr" style="color: red; font-size: 10px; vertical-align:top;">';
          close = "</span>";
          break;
      }

      verseContent = this.splice(verseContent,obj.position,obj.length,open+obj.replace+close);
    })


    return [verse['verse_num']+". "+verseContent, crIdx];               

  }

  toggleDrawer = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (!isOpen) {
      this.setState({ selectedSummary: '' })
    }

    this.setState({"isMenuOpen": isOpen});
  };

  handleClose() {
    this.setState({"shouldShowBookmarkCompleteSnackBar": false});
  }


  handleChange( index ) {

    if( index == 0 ) {
      if( !this.state.chain_reference_flag  && (this.state.is_both_lang_available_flag || (this.props.content_verison != 'tc' && this.props.content_verison != 'cn'))) {
        return false;
      }

      window.sessionStorage.setItem("chain_reference_flag", !this.state.chain_reference_flag);

      this.setState({ chain_reference_flag: !this.state.chain_reference_flag });
    }
    else if( index == 1 ){
      if( !this.state.dictionary_flag  && (this.state.is_both_lang_available_flag || (this.props.content_verison != 'tc' && this.props.content_verison != 'cn'))) {
        return false;
      }

      window.sessionStorage.setItem("dictionary_flag", !this.state.dictionary_flag);

      this.setState({ dictionary_flag: !this.state.dictionary_flag });
    }
    else if( index == 2 ) {
      if( !this.state.state  && (this.state.is_both_lang_available_flag || (this.props.content_verison != 'tc' && this.props.content_verison != 'cn'))) {
        return false;
      }

      window.sessionStorage.setItem("dot_flag", !this.state.dot_flag);

      this.setState({ dot_flag: !this.state.dot_flag });
    }
    else if( index == 3 ) {
      if( !this.state.commentary_flag  && (this.state.is_both_lang_available_flag || (this.props.content_verison != 'tc' && this.props.content_verison != 'cn'))) {
        return false;
      }

      window.sessionStorage.setItem("commentary_flag", !this.state.commentary_flag);

      this.setState({ commentary_flag: !this.state.commentary_flag });
    }
    else if( index == 4 ) {
      if( !this.state.is_both_lang_available_flag ) {
        this.setState({ 
          is_both_lang_available_flag: !this.state.is_both_lang_available_flag ,
          chain_reference_flag: false,
          dictionary_flag: false,
          dot_flag: false,
          commentary_flag: false,
          content_verison: 'tc',
          second_content_version: 'en'
        });

        this.getBibleVerseEvent(this.state.chapter, true, 'tc');
        this.getBibleVerseEvent(this.state.chapter, false, 'en');
      }
      else {
        window.sessionStorage.setItem("is_both_lang_available_flag", !this.state.is_both_lang_available_flag);
        
        this.setState({ is_both_lang_available_flag: !this.state.is_both_lang_available_flag });
      }
      
    }
  }

  getSwitchState( index ) {
    if( index == 0 ) 
      return this.state.chain_reference_flag;
    else if( index == 1 ) 
      return this.state.dictionary_flag;
    else if( index == 2 ) 
      return this.state.dot_flag;
    else if( index == 3 )
      return this.state.commentary_flag;
    else if( index == 4 ) 
      return this.state.is_both_lang_available_flag;
  }

  handleSliderChange = (event: any, newValue: number | number[]) => {
    this.setState({"size": newValue});
  };

  list = () => (
    <div
      role="presentation"
      className="rightMenu"
      // onClick={this.toggleDrawer(false)}
      // onKeyDown={this.toggleDrawer(false)}
    >
      <p style={{ 'font-size': '20px', 'padding': '0 10px'}}>
        {getValue("menuSetting")}
      </p>
      <Divider />
      <List>
        {[getValue("chainReference"), getValue("definition"), getValue("dot"), getValue("commentary"), getValue("translate")].map((text, index) => (
          <ListItem button key={text}>
            
            <ListItemText primary={text} />
            <IOSSwitch
              checked={this.getSwitchState(index)}
              onChange={() => this.handleChange(index)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>

        <ListItem button key={getValue("map")} onClick={() => window.open("/bibleBookMap/" + this.props.match.params.book_id, "_blank")}>
          <MapIcon />
          <ListItemText primary={getValue("map")} />
        </ListItem>

        <ListItem button key={getValue("summary")} onClick={() => this.setState({ selectedSummary: 1 })}>
          <DescriptionIcon />
          <ListItemText primary={getValue("summary")} />
        </ListItem>


        <ListItem button key={getValue("information")} onClick={() => this.handleInformationOnClick() }>
          <DataUsageIcon />
          <ListItemText primary={getValue("information")} />
        </ListItem>

      </List>

      <Divider />

      <List>
        
        <ListItem button key={getValue("collectBook")}>
          <FavoriteIcon />
          <ListItemText primary={getValue("collectBook")} />
        </ListItem>

        <ListItem button key={getValue("select")} onClick={() => this.setState({ "isMenuOpen": false ,  "shouldDisplayCheckbox": !this.state.shouldDisplayCheckbox })}>
          <CheckBoxIcon />
          <ListItemText primary={getValue("select")} />
        </ListItem>
        
        <ListItem button key={getValue("selectAll")}  onClick={() => this.handleSelectAllCheckboxes()}>
          <SelectAllIcon />
          <ListItemText primary={getValue("selectAll")} />
        </ListItem>
        
        <ListItem button key={getValue("selectCancel")}  onClick={() => this.setState({ "isMenuOpen": false ,  "checked": [], "shouldDisplayCheckbox": true })}>
          <CheckBoxOutlineBlankIcon />
          <ListItemText primary={getValue("selectCancel")} />
        </ListItem>

        <ListItem button key={getValue("share")} onClick={() => this.handleShareOnClick()} >
          <ShareIcon />
          <ListItemText primary={getValue("share")} />
        </ListItem>

        <ListItem button key={getValue("bookmark")}>
          <BookmarkIcon />
          <ListItemText primary={getValue("bookmark")} onClick={() => this.handleBookmarkOnClick()} />
        </ListItem>

        <ListItem button key={getValue("chooseVersion")} onClick={() => this.handleVersionOnClick() } >
          <UpdateIcon />
          <ListItemText primary={getValue("chooseVersion")} />
        </ListItem>

        <ListItem button key={getValue("fontSize")} style={{ 'margin-top': '15px' }}>
          <FontDownloadIcon style={{ left: "20px" }} />
          
          <Slider
            defaultValue={this.state.size}
            // getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={18}
            max={32}
            onChange={this.handleSliderChange}
          />
        </ListItem>
      </List>
    </div>
  );

  chapterBtnOnChange(chapterId) {

    if( this.state.is_both_lang_available_flag ) {
      this.getBibleVerseEvent(chapterId, true);
      this.getBibleVerseEvent(chapterId, false);
    }
    else {
      this.getBibleVerseEvent(chapterId, true);
    }
    
    this.setState({"selectedChapter": chapterId, checked: []});
    window.history.replaceState(null, "", "/bibleBook/" + this.props.match.params.book_id + "/" + chapterId);
  }

  shareDialog(subject, body) {
    let url = "https://www.sdabible.org/";
    return (
      <DialogContent dividers>
        <div>
          <EmailShareButton className="shareIcon" 
                            subject={subject} 
                            body={subject + "\n" + body} url={url} children={
            <EmailIcon size={32} round={true} />
          } />

          <WhatsappShareButton className="shareIcon" title={subject + "\n" + body} url={url} children={
            <WhatsappIcon size={32} round={true} />
          } />

          <FacebookShareButton className="shareIcon" quote={subject + "\n" + body} url={url} children={
            <FacebookIcon size={32} round={true} />
          } />

          <TwitterShareButton className="shareIcon" title={subject + "\n" + body} url={url} children={
            <TwitterIcon size={32} round={true} />
          } />

          <LineShareButton className="shareIcon" title={subject + "\n" + body} url={url} children={
            <LineIcon size={32} round={true} />
          } />

          <WeiboShareButton className="shareIcon" title={subject + "\n" + body} url={url} children={
            <WeiboIcon size={32} round={true} />
          } />
        </div>
      </DialogContent>
    );
  }

  dialogTitle() {
    if( this.state.dialogKey == "verse" ) {
      return (
      <div className="dialogTitleContainer">
        <p dangerouslySetInnerHTML={{ __html: this.state.selectedVerseContent }} ></p>
        <div className="dialogTitleBtns">
          <ShareIcon className="dialogBtn" onClick={() => this.handleShareChainOnClick()} />
          <BookmarkIcon className="dialogBtn" />
        </div>
        
      </div>
      
      );
    }
    else if( this.state.dialogKey == "information" ) {
      return (
      <div className="dialogTitleContainer">
        <p>
          {getValue("bibleInformation")}
        </p>
        
      </div>
      
      );
    }
    else if( this.state.dialogKey == "version" ) {
      return (
      <div className="dialogTitleContainer">
        <p>
          {getValue("bibleVersion")}
        </p>
        
      </div>
      
      );
    }
    else if( this.state.dialogKey == "shareVerse" || this.state.dialogKey == "shareChain" || this.state.dialogKey == "shareDictionary" ) {
      return (
        <div className="dialogTitleContainer">
          <p>
            {getValue("shareContent")}
          </p>
        </div>
      );
    }
    else 
      return "";
  }

  dialogContent() {
    if( this.state.dialogKey == "verse" ) {
      var dictionaries = [];
      var chains = [];

      if( this.state.selectedVerseDictionary ) {
        this.state.selectedVerseDictionary.map((item) => {  
          
        dictionaries.push(
          <div className="dictionaryContainer">
            <p className="dictionaryName">{item['locale_words_elt'] + " " + item['bible_dictionary_origin']}</p>
            <p className="dictionaryDefinition" dangerouslySetInnerHTML={{__html: item['locale_definitions_elt']}}></p>
            <ShareIcon className="dialogBtn" 

              onClick={() => this.setState({
                sharedDictionaryItem: item,
                dialogKey: "shareDictionary"
              })}

            />
          </div>
        );
      });
      }

      if( this.props.chains ) {
        let crIdx = this.state.selectedVerseCrIdx;
        this.props.chains.map((chainItems) => {  
          crIdx ++;
          chainItems['to_verses'].map((item, index) => {
            console.log(item);
            chains.push(
              <div className="chainContainer">
                <p className="crIdx">
                  {(index == 0) ? crIdx : ""}
                 </p>
                <div className="chainContentContainer">
                  <p className="chainName">
                 {item['bible_book_name'] + item['chapter_num'] + ":" + item['verse_num']}</p>
                  <p className="chainDefinition" dangerouslySetInnerHTML={{__html: item['locale_contents_elt']}}></p>
                  
                </div>
                <div className="chainBtnsContainer">
                  <ArrowForwardIcon 
                    className="chainBtn"  
                    onClick={() => window.open("/bibleBook/" + item["bible_book_id"] + "/" + item["chapter_id"],  "_blank")}


                  />
                  <BookmarkIcon className="chainBtn" />
                </div>
              </div>
            );
          });

        // );


      });
      }

      return (
        <div>

        {
          (dictionaries.length > 0 && this.state.dictionary_flag ) ? 
          <DialogContent dividers >
             <div className="scrollable" style={{ "overflow": "scroll", "display": "block", "maxHeight": "400px" }}  >
               <p className="popupLegend">{getValue("definition")}</p>
               <p>
                 {dictionaries}
               </p>
             </div>
          </DialogContent> : "" 
        }

        {
           (chains.length > 0 && this.state.chain_reference_flag ) ? 
           <DialogContent dividers>
             <div className="scrollable" style={{ "overflow": "scroll", "display": "block", "maxHeight": "400px" }} >
               <p className="popupLegend">{getValue("chainReference")}</p>
               <p>
                  {chains}
               </p>
             </div>
        </DialogContent> : ""


        }
        
        </div>
      );
    }
    else if( this.state.dialogKey == "information" ) {
      // const informations = this.props.books['informations'] || [];
      const informations = this.props.books['information_contents'] || [];

      // const classes = useStyles();

      let menuItems = [];

      informations.map((item) => {  
        // console.log(RESOURCE_URL+item['locale_documents_elt']); console.log(item['name']); 
        menuItems.push(
          <MenuItem value={item['locale_document_contents_elt']}>{item['name']}</MenuItem>
        );
      });

      return (
        <DialogContent dividers>
        <FormControl className="informationFormControl">
          <InputLabel id="dialog-select-label">{getValue("chooseBibleInformation")}</InputLabel>
          <Select
          labelId="demo-controlled-open-select-label"
          id="controlled-open-select"
          onChange={(event) => this.handleDropdownChange(event, this)}
          >
             {menuItems}
          </Select>

          <div className="informationContent" dangerouslySetInnerHTML={{__html: this.state.selectedInformation.replace(/(\n)/gm, "") }}></div>

          <Button variant="contained" color="primary" className="informationBtn" onClick={() => this.loadInformationDoc()}>
            {getValue("browse")}
          </Button>
        </FormControl>

        </DialogContent> 
      );
    }
    else if( this.state.dialogKey == "shareVerse" ) {
        var subject = this.props.books['chapters'][0]['name'];
        var body = "";

        for( var i=0; i<this.state.checked.length; i++ ) {
          if( i == 0 )
            subject += " " + this.getChapterNum(this.state.chapter) + ":" + (parseInt(this.state.checked[i])+1);
          else 
            subject += ", " + this.getChapterNum(this.state.chapter) + ":" + (parseInt(this.state.checked[i])+1);

          body += this.props.verses[this.state.checked[i]]['content']['0']['locale_contents_elt'] + "\n";

          if (this.state.commentary_flag) {
            body += this.props.verses[this.state.checked[i]]['commentary'][0]['locale_commentaries_elt']  + "\n";
          }
        }

        if( this.state.checked.length == 0 ) {
          return (
            <DialogContent dividers>
            <div style={{width: "200px"}}>
            <p style={{'text-align': 'center', 'font-weight': 'bold'}}>
              {getValue("chooseShareVerse")}</p>
            </div>
            </DialogContent>
          );
        }

        return this.shareDialog( subject, body );
        
    }
    else if( this.state.dialogKey == "shareChain" ) {
      var subject = getValue("chainReferenceShare") + this.props.books['chapters'][0]['name'] + 
                    this.getChapterNum(this.state.chapter) + ":" + this.state.selectedVerse['verse_num'];
      var body =  this.state.selectedVerse['content']['0']['locale_contents_elt'] + "\n\n";

      console.log(this.state.selectedVerse['verse_num']);

      if( this.props.chains ) {
        this.props.chains.map((chainItems) => {  
          chainItems['to_verses'].map((item, index) => {
              body += item['bible_book_name'] + item['chapter_num'] + ":" + item['verse_num'] + "\n";

          });
        });
      }

        return this.shareDialog( subject, body );
    }
    else if( this.state.dialogKey == "shareDictionary" ) {
       var subject = getValue("definition")+"： " + this.state.sharedDictionaryItem['locale_words_elt'] + " " + this.state.sharedDictionaryItem['bible_dictionary_origin'];
       var body = this.state.sharedDictionaryItem['locale_definitions_elt'];

      return this.shareDialog( subject, body.replace(/<br\s*\\?>/g, "\r\n").replace(/<BR\s*\\?>/g, "\r\n").replace("<BR>", "\r\n") );
    }

    else if( this.state.dialogKey == "version" ) {

      if( !this.state.is_both_lang_available_flag ) {
        return (
          <DialogContent dividers>
          <FormControl className="informationFormControl">
            <InputLabel id="dialog-select-label">{getValue("chooseBibleVersion")}</InputLabel>
               {(this.state.locale == "tc") ?
                (
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="controlled-open-select"
                    defaultValue="ABC"
                    onChange={(event) => this.handleVersionChange(event, this)}
                    >
                   <MenuItem value="tc">和合本</MenuItem>
                   <MenuItem value="tc_cmn">新標點和合本</MenuItem>
                   <MenuItem value="tc_rcuv">和合本修訂版</MenuItem>
                   <MenuItem value="en">KJV</MenuItem>
                   <MenuItem value="en_nrsv">NRSV</MenuItem>
                   <MenuItem value="en_nasb">NASB</MenuItem>
                  </Select>
                )
               :
                (
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="controlled-open-select"
                    defaultValue="ABC"
                    onChange={(event) => this.handleVersionChange(event, this)}
                    >
                   <MenuItem value="cn">和合本</MenuItem>
                   <MenuItem value="cn_rcuv">新标点和合本</MenuItem>
                   <MenuItem value="en">KJV</MenuItem>
                   <MenuItem value="en_nrsv">NRSV</MenuItem>
                   <MenuItem value="en_nasb">NASB</MenuItem>
                  </Select>

                )
               }
            <Button variant="contained" color="primary" className="informationBtn" onClick={() => this.loadVersion()}>
              {getValue("confirm")}
            </Button>
          </FormControl>

          </DialogContent> 
        );
      }
      
      else {
        return (
          <DialogContent dividers>
            <div className="dualLanguageVersionSelectionDialog" style={{ display: 'flex' }}>
              <FormControl className="dualLanguageFormControl">
                <InputLabel className="dialog-select-label">{getValue("chooseBibleVersionCh")}</InputLabel>
                
                {
                  (this.state.locale == "tc") ? (
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="controlled-open-select"
                      onChange={(event) => this.handleVersionChange(event, this)}
                      >
                         <MenuItem value="tc">和合本</MenuItem>
                         <MenuItem value="tc_cmn">新標點和合本</MenuItem>
                         <MenuItem value="tc_rcuv">和合本修訂版</MenuItem>
                      </Select>
                  ) : (
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="controlled-open-select"
                      onChange={(event) => this.handleVersionChange(event, this)}
                      >
                         <MenuItem value="cn">和合本</MenuItem>
                         <MenuItem value="cn_rcuv">新标点和合本</MenuItem>
                      </Select>
                  )
 
                }

              </FormControl>
              <FormControl className="dualLanguageFormControl">
                <InputLabel className="dialog-select-label">{getValue("chooseBibleVersionEn")}</InputLabel>
                <Select
                labelId="demo-controlled-open-select-label"
                id="controlled-open-select"
                onChange={(event) => this.handleSecondVersionChange(event, this)}
                >
                   <MenuItem value="en">KJV</MenuItem>
                   <MenuItem value="en_nrsv">NRSV</MenuItem>
                   <MenuItem value="en_nasb">NASB</MenuItem>
                </Select>
                
              </FormControl>

            </div>
            <Button variant="contained" color="primary" className="informationBtn" onClick={() => this.loadDualVersion()}>
                {getValue("confirm")}
            </Button>

          </DialogContent> 

          
        );
      }

      
    }
    else 
      return "";

    
  }

  handleCheckBoxOnChange(event) {
    var index = event.target.value;
    let verse = this.props.verses[index];

    var checkboxStatus = this.state.checked;

    if( !checkboxStatus.includes(index) )
      checkboxStatus.push(index);

    this.setState({checked: checkboxStatus});
  }

  handleDropdownChange(event) {
    this.setState({ selectedInformation: event.target.value });
  }

  handleVerseOnClick(verse, content, crIdx) {
    console.log(verse);
    if( ( this.state.dictionary_flag && verse['dictionary'].length > 0 ) || 
        ( this.state.chain_reference_flag && verse['chain_reference'].length > 0 && 
          verse['chain_reference'][0]['position'] )) {
      this.props.getBibleChainByVerse(verse['id'], 'tc');
      this.setState({ 
        shouldShowDialog: true, 
        selectedVerse: verse,
        selectedVerseContent: content, 
        selectedVerseCrIdx: crIdx, 
        selectedVerseDictionary: verse['dictionary'],
        dialogKey: "verse"
      });
      console.log(this.state.selectedVerseDictionary);  
    }    
  }

  handleSelectAllCheckboxes() {
    var checkboxStatus = [];

    for( var i=0; i<this.props.verses.length; i++ ) {
      checkboxStatus.push(i.toString());
    }

    this.setState({
      isMenuOpen: false,
      shouldDisplayCheckbox: true,
      checked: checkboxStatus
    })
  }

  handleVersionOnClick() {
    this.setState({ 
      shouldShowDialog: true, 
      dialogKey: "version"
    }); 
  }

  handleShareOnClick() {
    this.setState({ 
      shouldShowDialog: true, 
      dialogKey: "shareVerse"
    });
  }

  handleBookmarkOnClick() {
    if( this.state.checked.length > 0 ) {
      var verseIds = Array();
      for( var i=0; i<this.state.checked.length; i++ ) {
        verseIds.push(this.props.verses[this.state.checked[i]]['content'][0]['verse_id']);
      }

      let user = JSON.parse(sessionStorage.getItem('userInfo'));
      if( user ) {
        this.props.bookmarkVerses( verseIds);
      }

      this.setState({ 'isBookmarking': true });
    }
  }

  handleShareChainOnClick() {
    this.setState({ 
      shouldShowDialog: true, 
      dialogKey: "shareChain"
    });
  }

  handleInformationOnClick() {
    this.setState({ 
      shouldShowDialog: true, 
      dialogKey: "information"
    });  

  }

  loadInformationDoc() {



  }

  handleVersionChange(event) {
    this.setState({ content_verison: event.target.value });
  }

  handleSecondVersionChange(event) {
    this.setState({ second_content_version: event.target.value });
  }

  loadVersion() {
    this.getBibleVerseEvent( this.state.selectedChapter, true );

    if( this.state.content_verison != "tc" && this.state.content_verison != "sc" ) {
      this.setState({
        chain_reference_flag: false,
        dictionary_flag: false,
        dot_flag: false,
        shouldShowDialog: false, 
        isMenuOpen: false
      });
    }
    else {
      this.setState({ shouldShowDialog: false, isMenuOpen: false });  
    }
  }

  loadDualVersion() {
    this.getBibleVerseEvent( this.state.selectedChapter, true );
    this.getBibleVerseEvent( this.state.selectedChapter, false );
    this.setState({ shouldShowDialog: false, isMenuOpen: false });  
  }

  closeDialog() {
     this.setState({ 
       shouldShowDialog: false, 
       selectedInformation: "",
       content_verison: this.props.content_verison
     }); 
  }

  getChapterNum( chapterId ) {
    const chapters = this.props.books['chapters'] || [];
    let chapterArr = (chapters).reduce((chapterArr, chapter) => {
        (chapterArr[chapter["chapter_id"]] = chapterArr[chapter["chapter_id"]] || []).push(chapter);
        return chapterArr;
      },[]);

    let chapterNum = -1;

    (chapterArr).map((chapter) => {
      if(chapterId == chapter[0]['chapter_id']) 
        chapterNum = chapter[0]['chapter_num'];
    })

    return chapterNum;
  }

  getVersionMapping( locale ) {
    switch(locale) {
      case 'tc':
        return "CUV";
        break;
      case 'tc_cmn':
        return "CUNP";
        break;
      case 'tc_rcuv':
        return 'RCUV';
        break;
      case 'cn':
        return "CUV";
        break;
      case 'cn_rcuv':
        return "CUNP";
        break;
      case 'en':
        return 'KJV';
        break;
      case 'en_nrsv':
        return 'NRSV';
        break;
      case 'en_nasb':
        return 'NASB';
        break;
    }
  }

  generateVerses(verses, crIdx) {
    let generatedVerses = [];
    var i = 0;

    this.props.verses.map(verse => {

      var secondary_verse = (this.state.is_both_lang_available_flag && this.props.secondary_verses) ? this.props.secondary_verses[i] : "";

      if (verse['chapter_id'] == this.state.chapter) {
            // console.log(this.state.chapter);
            let spannableContent = this.spannableContent(verse, crIdx);
            let secondarySpannableContent = "";

            if( this.state.is_both_lang_available_flag && secondary_verse != "" )
              secondarySpannableContent = this.spannableContent(secondary_verse, crIdx);

            let tmpCrIdx = crIdx;
            crIdx = spannableContent[1];

            generatedVerses.push(





              <div className={( this.state.dictionary_flag || this.state.chain_reference_flag ) ? "verseContent clickable" : "verseContent"}
                   style={{display: "flex"}} onDoubleClick={() => this.handleVerseOnClick(verse, this.getChapterNum(this.state.chapter) + ":" + spannableContent[0], tmpCrIdx)}  >

              {(this.state.shouldDisplayCheckbox) ? 
              <FormControlLabel
                control={
                  <Checkbox  name="gilad" value={i} checked={this.state.checked.includes(i.toString()) ? true : false} onChange={(event) => this.handleCheckBoxOnChange(event, this)} />}
              /> : ""}

              <div style={{ display: 'flex', 'justify-content': 'space-between', 'width': '100%' }}>
                <div style={{'width': '100%' }}>
                <p style={{ marginLeft: '10px', 'font-size': this.state.size + 'px' }} dangerouslySetInnerHTML={{ __html: this.getChapterNum(this.state.chapter) + ":" + spannableContent[0] }} ></p>
                {
                  (this.state.commentary_flag) ? (
                     <div style={{ fontSize: this.state.size + 'px', marginLeft: '24px' }} dangerouslySetInnerHTML={{ __html: (verse['commentary'][0]) ? verse['commentary'][0]['locale_commentaries_elt'] : "" }}></div>
                  ) : ""
                }
                </div>

                {( this.state.is_both_lang_available_flag ) ? 
                <div style={{ 'width': '100%' }}>
                  <p style={{ marginLeft: '10px', 
                            'font-size': this.state.size + 'px', 
                            'border-left': 'solid 1px #CCC', 
                            margin: '0', padding: '20px 10px' }} dangerouslySetInnerHTML={{ __html: this.getChapterNum(this.state.chapter) + ":" + secondarySpannableContent[0] }} ></p>
                            </div> : ""

                }

                

                
              </div>
              

              
              </div>

            );

      }

      i++;
    })

    return generatedVerses;




  }

  render() {

    const {books, error, pending, verses, membershipPending } = this.props;
    var chapterArr = [];
    var bookName = "";
    var crIdx = 0;

    if( this.state.isBookmarking && !membershipPending ) {
      this.setState({ isBookmarking: false, isMenuOpen: false, shouldShowBookmarkCompleteSnackBar: true });
    }

    if (books) {
      const chapters = this.props.books['chapters'] || [];
      
      const maps = this.props.books['maps'] || [];

      console.log(verses);

      // console.log( chapters[0] );
      // ( chapters.length ) ? console.log(chapters[0]['name']) : "";

     
      bookName = (chapters.length) ? chapters[0]['name'].trim() : "";
      chapterArr = (chapters).reduce((chapterArr, chapter) => {
        (chapterArr[chapter["chapter_id"]] = chapterArr[chapter["chapter_id"]] || []).push(chapter);
        return chapterArr;
      },[]);

      if( chapters.length && this.state.selectedChapter == -1 ) {
        this.getBibleVerseEvent( chapters[1]['chapter_id'], true );
        this.setState({ selectedChapter: chapters[1]['chapter_id'] });
      }

      // this.menuItems = Menu(list, selected);

    }

    const menu = this.menuItems;

    return (
      <div>
        <Header />

        <Snackbar 
          open={this.state.shouldShowBookmarkCompleteSnackBar} 
          autoHideDuration={3000} 
          onClose={() => this.handleClose()}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
          <Alert onClose={() => this.handleClose()} severity="success">
           Bookmark Success !
          </Alert>
        </Snackbar>

        <div className="versesContainer">
        <div style={{"display":"flex", "position": "relative"}}>

          {(this.props.content_verison && this.state.is_both_lang_available_flag ) ?
            <h1>{bookName + "(" + this.getVersionMapping(this.props.content_verison) + "," + this.getVersionMapping(this.props.secondary_content_verison) + ")"}</h1> :
           (this.props.content_verison) ? 
           <h1>{bookName + "(" + this.getVersionMapping(this.props.content_verison) + ")"}</h1> :
            <h1>{bookName}</h1>
          }

          <div className="settingIconDiv">
          <SettingsIcon fontSize="medium" 
          onClick={this.toggleDrawer(true)} 
          />
          </div>
        </div>
        
        <CustomizedDialogs 
          shouldShow={this.state.shouldShowDialog} 
          mapTitle={this.dialogTitle()}
          dialogContent={this.dialogContent()} 
          closeDialog={() => this.closeDialog()}
        />

        <div className="buttonsWrapper">
        {
          (chapterArr).map((chapter) => {
            return (
              (this.state.selectedChapter == chapter[0]['chapter_id']) ?
              <div className="chapterBtn active" onClick={(e) => this.chapterBtnOnChange(chapter[0]['chapter_id'])}>{ chapter[0]['chapter_num'] }
              </div> : 
              <div className="chapterBtn" onClick={(e) => this.chapterBtnOnChange(chapter[0]['chapter_id'])}>{ chapter[0]['chapter_num'] }
              </div> 
              )
          })
        }
        </div>

        <Drawer anchor={'right'} 
                open={this.state.isMenuOpen} 
                onClose={this.toggleDrawer(false)}>
          {this.list()}
        </Drawer>

        {
          (pending) ? 
          <div className="loadingPortal">
            <div className="loadingArea">
              <CircularProgress />
              <p>Loading ... </p>
            </div>
          </div> : ""
        } 

        

        {
          (this.state.selectedSummary == "") ? "" :

            <div className="documentDialog">
                <CloseIcon style={{ marginRight: 10, display: 'block', marginLeft: 'auto' }} onClick={() => this.setState({ selectedSummary: "" }) } />
               <div dangerouslySetInnerHTML={{__html: this.props.books['summary'][0]['locale_summary_content_elt'] }}></div>
            </div>
        }

        { this.generateVerses(verses, crIdx) }

        </div>
      </div>
      );
  }


}

const mapStateToProps = (state) => {
  return {
    error: getBibleBooksError(state.bibleBookReducer),
    books: getBibleBooks(state.bibleBookReducer),
    verses: getBibleVerses(state.bibleBookReducer),
    secondary_verses: getBibleSecondaryVerses(state.bibleBookReducer),
    pending: getBibleBooksPending(state.bibleBookReducer),
    chains: getBibleChainByVerse(state.bibleBookReducer),
    content_verison: getBibleContentVersion(state.bibleBookReducer),
    secondary_content_verison: getBibleSecondaryContentVersion(state.bibleBookReducer),
    membershipPending: getMembershipPending(state.membershipReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getBibleBookById: getAllBibleBooksAction,
  getBibleVerse: getBibleVerseAction,
  getBibleChainByVerse: getBibleChainByVerseAction,
  bookmarkVerses: bookmarkVersesAction
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BibleBook));

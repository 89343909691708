import { getResourceCategoryPending, getResourceCategorySuccess, getResourceCategoryError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function getResourceCategories($type, $locale) {

    let api = APP_DOMAIN+"/getResourceCategory/"+$type+"/"+$locale;

    let user = JSON.parse(sessionStorage.getItem('userInfo'));

    if( $type == "magazine" && user ) {
      api = APP_DOMAIN+"/getMagazinesWithUid";
      return dispatch => {
            dispatch(getResourceCategoryPending());

            fetch(api, {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    token : user.token,
                    locale: $locale
                })
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if( res.code == "500" && res.message == "Login information is invalid" ) {
                    throw("Token invalid");
                }
                
                if(res.error) {
                    throw(res.error);
                }

                dispatch(getResourceCategorySuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(getResourceCategoryError(error));
            })
        }
    }
    else {
        return dispatch => {
            dispatch(getResourceCategoryPending());

            fetch(api, SecurityHeader())
            .then(res => {
                return res.json()
            })
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(getResourceCategorySuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(getResourceCategoryError(error));
            })
        }
    }

    
}


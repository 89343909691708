import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getLandingResourceAction from '../../redux/api/landingResourcesApi';
import collectMagazineAction from '../../redux/api/collectMagazineApi';
import { getLandingResource, getLandingResourceError, getLandingResourcePending } from '../../redux/reducer/landingResourceReducer';

import './Home.css'; 


import MagazineItem from '../resource/MagazineItem';
import VideoItem from '../resource/VideoItem';
import BookItem from './LandBookItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';
import book from '../../images/book.png';
import readingplan from '../../images/readingplan.png';
import info_bg from '../../images/info_bg.png';
import dictionary_banner from '../../images/dictionary_banner.png';

import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

class Landing extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      isCollecting: false
    };
    this._scroller = React.createRef();
  }

  componentWillMount() {

    const { getLandingResource } = this.props;
    getLandingResource(this.state.locale);

  }

  componentDidMount() { 
  }

  bibleCategoryTabClickEvent($cat_id) {
    this.setState({
      selectedCategoryId: $cat_id
    })
  }

  componentWillUpdate = (nextProps, nextState) =>{
     if(this.state.isScrolling !== nextState.isScrolling ) {
       this.toggleScrolling(nextState.isScrolling);
      }
  };

  toggleScrolling = (isEnable) => {
    if (isEnable) {
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    } else {
      window.removeEventListener('mousemove', this.onMouseMove);
    }
  };

  onScroll = (event) => {
  };

  onMouseMove = (event) => {
    const {clientX, scrollLeft, scrollTop, clientY} = this.state;
    this._scroller.current.scrollLeft = scrollLeft - clientX + event.clientX;
    this._scroller.current.scrollTop = scrollTop - clientY + event.clientY;
  };

  onMouseUp =  () => {
    this.setState({isScrolling: false, 
                   scrollLeft: 0, scrollTop: 0,
                   clientX: 0, clientY:0});
  };

  onMouseDown = (event) => {
    const {scrollLeft, scrollTop} = this._scroller.current;
    this.setState({isScrolling:true, scrollLeft, scrollTop, clientX:event.clientX, clientY:event.clientY});
  };


  toggleCollection(magazineId, shouldCollect) {
    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    if( user ) {
      this.props.collectMagazine( magazineId, shouldCollect );
    }
    this.setState({ isCollecting: true });

  }

  render() {
    const {res, error, pending} = this.props;
    var catArr = [];
    var vArr = [];
    var mArr = [];
    var docArr = [];
    var banner = {};

    if (res) {

      catArr = res["book"];
      vArr = res["video"];
      mArr = res['magazine'];
      docArr = res['doc'];
      banner = res['banner'];

      console.log(banner);
    }

    return (
      <div className="landingContainer">

        <Header 
          refresh={() => this.props.getLandingResource(this.state.locale)} 
          needRefresh={true}
        />

        <div ref={this._scroller} onMouseDown={this.onScroll}
        onScroll={this.onMouseMove} >

        </div>
        {
          (banner) ? 
          <div className="bannerContainer">
            <div className="bannerGradient">
              <a style={{ "display": "block" }} target="_blank" href={banner.link}>
                <img className="bannerSwiperItem" src={RESOURCE_URL + banner.image} />
              </a>
            </div>
            <div className="imageBookContainer">
              <img className="bookCoverItem" src={book} />
              
            </div>
          </div>
          : ""
        }

        <div>
          <div className="landingSubContainer">
            <h2>{getValue('bibleBook')}</h2>
            <div>
              <a href="/bible"><p>更多</p></a>
            </div>
          </div>
          <div className="booksContainer">
            {
              (catArr || []).slice(0,7).map((cat_book_item) => {
                  return (
                    <BookItem 
                      bookId={cat_book_item['book_id']}
                      bookName={cat_book_item['book_name']}
                      verse={cat_book_item['locale_contents_elt']}
                      hasCollected={cat_book_item['hasCollected']}
                      toggleCollection={(bookId, shouldCollect) => this.toggleCollection(bookId, shouldCollect)}
                      shouldShowCollectBtn={sessionStorage.getItem('userInfo') != null}
                    />
                  )
                })
            }
          </div>
        </div>


        <div>
          <div className="landingSubContainer">
            <h2>{getValue('magazine')}</h2>
            <div>
              <a href="/magazine"><p>更多</p></a>
            </div>
          </div>
          <div className="booksContainer">
            {
              (mArr || []).slice(0,3).map((cat_item) => {
                  return (
                    <MagazineItem 
                      id={cat_item['magazine_id']}
                      name={cat_item['magazine_name']}
                      desc={cat_item['locale_descriptions_elt']}
                      cover={RESOURCE_URL+cat_item['cover_image']}
                      url={RESOURCE_URL+cat_item['document_pdf']}
                      hasCollected={cat_item['hasCollected']}
                      toggleCollection={(magazineId, shouldCollect) => this.toggleCollection(magazineId, shouldCollect)}
                      shouldShowCollectBtn={sessionStorage.getItem('userInfo') != null}
                    />
                  )
                })
            }
          </div>
        </div>


        <div>
          <div className="landingSubContainer">
            <h2>{getValue('video')}</h2>
            <div>
              <a href="/video"><p>更多</p></a>
            </div>
          </div>
          <div className="booksContainer">
            {
              (vArr || []).reduce((videoArr, video) => {
                      (videoArr[video.video_id] = videoArr[video.video_id] || []).push(video);
                      return videoArr;
                  },[]).filter(item => { return true } ).sort((a,b) => {
                    return (a[0].upload_date < b[0].upload_date) ? 1 : -1;
                  }).slice(0,3).map((cat_items, idx) => {

                    console.log(idx);

                  const cat_item = cat_items[0];
                  const cat_item_second = cat_items[1] || {};

                  return (
                     <VideoItem 
                        id={cat_item['video_id']}
                        name={cat_item['video_name']}
                        desc={cat_item['locale_descriptions_elt']}
                        cover={RESOURCE_URL+cat_item['cover_image']}
                        url={cat_item['link']}
                        source_name={cat_item['video_source_name']}
                        second_url={(cat_item_second) ? cat_item_second['link'] : ""}
                        second_source_name={(cat_item_second) ? cat_item_second['video_source_name'] : ""}
                      />
                  )
                })
            }
          </div>
        </div>


        <div>
          <div className="landingSubContainer">
            <h2>{getValue('otherResource')}</h2>
          </div>
          <div className="booksContainer">
            
            <div className="otherResItem">
              <a href="/sdaDictionary">
                <img src={dictionary_banner} />
                <h2>{getValue('sdaDictionary')}</h2>
              </a>
            </div>
            <div className="otherResItem">
              <a href="/topic">
                <img src={info_bg} />
                <h2>{getValue('topic')}</h2>
              </a>
            </div>
            {
              ( sessionStorage.getItem('userInfo') ) ? 
              <div className="otherResItem">
                <a href="/discoverPlans">
                  <img src={readingplan} />
                  <h2>{getValue('biblePlan')}</h2>
                </a>
              </div>
              : ""
            }

          </div>
        </div>

      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {
      error: getLandingResourceError(state.landingResourceReducer),
      res: getLandingResource(state.landingResourceReducer),
      pending: getLandingResourcePending(state.landingResourceReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getLandingResource: getLandingResourceAction,
    collectMagazine: collectMagazineAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Landing));

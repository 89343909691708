import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getCollectedMagazinesAction from '../../redux/api/getCollectedMagazinesApi';
import collectMagazineAction from '../../redux/api/collectMagazineApi';
import { getMembershipPending, getCollectedMagazines, getMembershipError } from '../../redux/reducer/membershipReducer';
import '../home/Home.css'; 

import BookItem from '../home/BookItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';

import MagazineItem from '../resource/MagazineItem';

import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

class CollectedMagazines extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      isScrolling: false
    };
    this._scroller = React.createRef();
  }

  componentWillMount() {
    const { getCollectedMagazines } = this.props;
    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    if( user ) {
      this.props.getCollectedMagazines();  
    }
    
  }

  componentDidMount() {
     
  }

  cancelCollect( magazineId ) {
    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    if( user ) {
      this.props.collectMagazine(magazineId, false );
    }
    this.setState({ isCollecting: true });
  }

  render() {

    const {magazines, error, pending} = this.props;

    let resource_prefix = RESOURCE_URL;

    if( !pending && this.state.isCollecting ) {
      let user = JSON.parse(sessionStorage.getItem('userInfo'));
      if( user ) {
        this.props.getCollectedMagazines();  
      }
    }

    return (
      <div>

        <Header />
        <div ref={this._scroller} onMouseDown={this.onScroll}
        onScroll={this.onMouseMove}>
        <ul className="categoryMenu">
          <li className="active" >
              {getValue("resourceCollection")}
           </li>
        </ul>
        </div>

        <div className="booksContainer">
          {
              (this.props.magazines.map((magazine) => {

                console.log(magazine);

                return (
                  <MagazineItem 
                    id={magazine['magazine_id']}
                    name={magazine['magazine_name']}
                    desc={magazine['magazine_desc']}
                    cover={resource_prefix + magazine['cover_image']}
                    url={resource_prefix + magazine['document_pdf']}
                    shouldShowCloseBtn={true}
                    cancelCollect={(magazineId) => this.cancelCollect(magazineId)}
                  />
                )
              }))
          }
        </div>
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {
      error: getMembershipError(state.bibleBookCategoryReducer),
      // book_categories: getBibleBookCats(state.bibleBookCategoryReducer),
      magazines: getCollectedMagazines(state.membershipReducer),
      pending: getMembershipPending(state.bibleBookCategoryReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getCollectedMagazines: getCollectedMagazinesAction,
    collectMagazine: collectMagazineAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectedMagazines));

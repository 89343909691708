import { createPlanPending, createPlanSuccess, createPlanError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";

export default function createPlan( $planName, $planDesc, $numOfDays, $days ) {

    let api = APP_DOMAIN+"/createPlan";

    return dispatch => {
        dispatch(createPlanPending());

        let user = JSON.parse(sessionStorage.getItem('userInfo'));

        if( user ) {
            fetch(api, {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    token : user.token,
                    planName: $planName,
                    planDesc: $planDesc,
                    numOfDays: $numOfDays,
                    days: $days
                })
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if( res.code == "500" && res.message == "Login information is invalid" ) {
                    throw("Token invalid");
                }
                if(res.error) {
                    throw(res.error);
                }
                dispatch(createPlanSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(createPlanError(error));
            })
        }
    }
}
import React, { Component, useRef } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getResourceCategoriesAction from '../../redux/api/resourceCategoryApi';
import { getResourceCatsError, getResourceCats, getParentCats, getResourceCatsPending } from '../../redux/reducer/resourceCategoryReducer';
import './Egw.css'; 
import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

import EgwBookItem from './EgwBookItem';

// import VideoItem from './VideoItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';

import TableSortLabel from '@material-ui/core/TableSortLabel';

import * as qs from 'query-string';

class EgwBook extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      selectedParentCategoryId: -1,
      activeCategory: 0,
      sortDate: 0,
      sortName: 0,
      customLocale: "sc"
    };

    this.inputRef = React.createRef();


  }

  componentDidMount(){
    let sort = -1;

    const parsed = qs.parse(window.location.search);
    var cat = parsed.cat;
    this.setState({ sortDate: sort, sortName: 0, selectedCategoryId: (cat) ? cat : -1 });


    let customLocale = (this.state.locale == "tc" || this.state.locale == "cn") ? "cn" : "en";
    this.setState({ customLocale: customLocale });

    const { getResourceCategories } = this.props;
    getResourceCategories("egw",customLocale);


    let videoDate = sessionStorage.getItem('videoDate');
    if (videoDate) {
      this.setState({ sortDate: videoDate });
    }

    let videoName = sessionStorage.getItem('videoName');
    if (videoName) {
      this.setState({ sortName: videoName });
    }
    
  }

  componentDidUpdate() {
    if( this.inputRef.current )
      this.inputRef.current.scrollIntoView();
  }

  componentWillMount() {
    
  }


  bibleParentCategoryTabClickEvent($cat) {

    window.history.replaceState(null, "", "/egwBook?cat=" + ($cat['children'] && $cat['children'].split(',').length > 0 ? $cat['children'].split(',')[0] : -1));

    console.log($cat['children']);

    this.setState({
      selectedCategoryId: $cat['children'] && $cat['children'].split(',').length > 0 ? $cat['children'].split(',')[0] : -1,
      selectedParentCategoryId: $cat
    })

  }

  bibleCategoryTabClickEvent($cat_id) {
    this.setState({
      selectedCategoryId: $cat_id
    })

    window.history.replaceState(null, "", "/egwBook?cat=" + $cat_id);
  }


  handleDateSort() {
    let sort = (this.state.sortDate == 0) ? 1 : this.state.sortDate * -1;
    this.setState({ sortDate: sort, sortName: 0 });
    sessionStorage.setItem('videoDate',sort);
    sessionStorage.setItem('videoName',0);
  }

  handleNameSort() {
    let sort = (this.state.sortName == 0) ? 1: this.state.sortName * -1;
    this.setState({ sortDate: 0, sortName: sort });
    sessionStorage.setItem('videoName',sort);
    sessionStorage.setItem('videoDate',0);
  }

  render() {

    
    const {resource_categories, parent_categories, error, pending} = this.props;
    var catArr = [];
    var catTitleArr = [];

    if (resource_categories) {

      console.log(resource_categories);

      catArr = (resource_categories).sort((a,b) => {
        
        if (this.state.sortName != 0) {
           if (this.state.sortName == 1) {
              return (a['book_name'].localeCompare(b['book_name'])) ;
           }

           if (this.state.sortName == -1) {
              return (b['book_name'].localeCompare(a['book_name'])) ;
           } 
        }

      }).reduce((catArr, cat) => {
          (catArr[cat["category_id"]] = catArr[cat["category_id"]] || []).push(cat);
          return catArr;
      },[]);

      console.log(catArr);

      if( this.state.selectedCategoryId == -1 && resource_categories.length > 0 ) {
        this.setState({ 
          selectedCategoryId: parent_categories[0]['children'].split(',')[0],
          selectedParentCategoryId: parent_categories[0]
        });
      }

      if (this.state.selectedCategoryId != -1 && this.state.selectedParentCategoryId == -1 && resource_categories.length > 0 ) {
        this.setState({ selectedParentCategoryId: parent_categories.filter(item => { 
          if (item['children'] == null) return false
          return item['children'].indexOf(this.state.selectedCategoryId) !== -1 
        })[0] });
      }

      catTitleArr = resource_categories.reduce((catArr, cat) => {
          (catArr[cat["category_sort_order"]] = catArr[cat["category_sort_order"]] || []).push(cat);
          return catArr;
      },[]);

      console.log( parent_categories );

    }

    return (
      <div>

        <Header />

        <div ref={this._scroller} onMouseDown={this.onScroll}
        onScroll={this.onMouseMove}>

         <ul className="categoryMenu">
          {  
            /* Cat title tab */
            (parent_categories).map((cat, index) => {
              {/*console.log(cat);*/}
              return (
                (this.state.selectedParentCategoryId['category_id'] == cat['category_id']) ? 

                <li className="active" onClick={(e) => this.bibleParentCategoryTabClickEvent(cat)} >


                  {cat['category_name']}
                  

                </li> : 

                <li onClick={(e) => this.bibleParentCategoryTabClickEvent(cat)} >


                  {cat['category_name']}
                  

                </li>
              )
            })
          }
          </ul>
        
        { (this.state.selectedParentCategoryId != -1) ?

        <ul className="categoryMenu">
        {
          /* Cat title tab */
          (!this.state.selectedParentCategoryId['children']) ? "" :
          (
            this.state.selectedParentCategoryId['children'].split(",").map(child => {

              return (catArr).map((cat, index) => {

              
                if( child.toString() == index.toString()) {

                  return (
                    (this.state.selectedCategoryId == cat[0]['category_id']) ? 

                    <li className="active" onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_id'])} >


                      {cat[0]['category_name']}
                      

                    </li> : 

                    <li onClick={(e) => this.bibleCategoryTabClickEvent(cat[0]['category_id'])} >


                      {cat[0]['category_name']}
                      

                    </li>
                  ) 
                }


              })


            })
          )
          
          
        }
        </ul> : "" }


        </div>


        <div className="sortTable" >

          <TableSortLabel 
                active={(this.state.sortName != 0)}
                direction={(this.state.sortName == -1) ? 'desc' : 'asc'}
                onClick={() => this.handleNameSort() }
                > 
                <p className={(this.state.sortName   != 0) ? "active" : ""}>{getValue("resourceName")}</p>

          </TableSortLabel>
        </div>
        
        <div className="booksContainer">
          {
            /* book grid  */
            (this.state.selectedCategoryId > 0 && catArr.length > 0) ? (
              (catArr[this.state.selectedCategoryId] || []).map((item) => {

                return (

                   <div onClick={(e) => (window.location.href = "/egwBook/"+item['book_id']) }>
                      <EgwBookItem 
                        id={item['id']}
                        name={(this.state.customLocale == "tc" || this.state.customLocale == "cn") ? item['book_name'] : item['book_name_en']}
                        cover={item['thumbnail']}
                      />
                    </div> 


                )
              })
            ) : (
              /* show all  */
              <div></div>
            )
            
          }
        </div>

      </div>
    );
  }


}

const mapStateToProps = (state) => {
  console.log(state);
  return {
      error: getResourceCatsError(state.resourceCategoryReducer),
      resource_categories: getResourceCats(state.resourceCategoryReducer),
      parent_categories: getParentCats(state.resourceCategoryReducer),
      pending: getResourceCatsPending(state.resourceCategoryReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getResourceCategories: getResourceCategoriesAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EgwBook));

import * as Action from '../action';

const initialState = {
    pending: false,
    topics: [],
    search_topics: [],
    error: null
}

export default function topicReducer(state = initialState, action) {
    switch(action.type) {
        case Action.GET_TOPIC_PENDING: 
            return {
                ...state,
                pending: true
            }
        case Action.GET_TOPIC_SUCCESS:
            return {
                ...state,
                pending: false,
                topics: action.topics
            }
        case Action.GET_TOPIC_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case Action.GET_TOPIC_SEARCH_PENDING: 
            return {
                ...state,
                pending: true
            }
        case Action.GET_TOPIC_SEARCH_SUCCESS:
            return {
                ...state,
                pending: false,
                search_topics: action.search_topics
            }
        case Action.GET_TOPIC_SEARCH_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default: 
            return state;
    }
}

export const getTopics = state => state.topics;
export const getSearchTopics = state => state.search_topics;
export const getTopicsPending = state => state.pending;
export const getTopicsError = state => state.error;






import { logoutPending, logoutSuccess, logoutError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import CryptoJS from 'crypto-js';

export default function logout() {

    let logoutAPI = APP_DOMAIN+"/logout";

    return dispatch => {
        dispatch(logoutPending());

        let user = JSON.parse(sessionStorage.getItem('userInfo'));

        if( user ) {
            fetch(logoutAPI, {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    token : user.token
                })
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if( res.code == "500" && res.message == "Login information is invalid" ) {
                    throw("Token invalid");
                }
                
                if(res.error) {
                    throw(res.error);
                }

                if( res.code ) {
                    throw(res.message);
                }

                dispatch(logoutSuccess());
                return res;
            })
            .catch(error => {
                dispatch(logoutError(error));
            })
        }        
    }
}
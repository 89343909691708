import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getCollectedBiblesAction from '../../redux/api/getCollectedBiblesApi';
import collectBibleAction from '../../redux/api/collectBibleApi';
import { getMembershipPending, getCollectedBibles, getMembershipError } from '../../redux/reducer/membershipReducer';
import '../home/Home.css'; 

import { getValue, getLocalLocale } from "../../constant/Locale";

import BookItem from '../home/BookItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';


class CollectedBibles extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      isCollecting: false
    };
  }

  componentWillMount() {
    const { getBookmarkedVerses } = this.props;
    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    if( user ) {
      this.props.getCollectedBibles();  
    }
    
  }

  componentDidMount() {
     
  }

  cancelCollect( bookId ) {
    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    if( user ) {
      this.props.collectBible( bookId, false );
    }
    this.setState({ isCollecting: true });
  }

  render() {

    const {error, pending} = this.props;

    if( !pending && this.state.isCollecting ) {
      let user = JSON.parse(sessionStorage.getItem('userInfo'));
      if( user ) {
        this.props.getCollectedBibles();  
      }
    }

    return (
      <div>

        <Header />
        <div ref={this._scroller} onMouseDown={this.onScroll}
        onScroll={this.onMouseMove} >
          <ul className="categoryMenu"  >
            <li className="active" >
              {getValue("bibleFavourite")}
            </li>
          </ul>
        </div>
        <div className="booksContainer">
          {

            /* book grid  */
            (this.props.books).map((book) => {
                return (

                  <BookItem 
                    bookId={book['bookId']}
                    bookName={book['bookName']}
                    verse={ book['verseContent']}
                    shouldShowCloseBtn={true}
                    type={"collect"}
                    cancelCollect={(bookId) => this.cancelCollect(bookId)}
                  />
                )
              })
           
            
          }
        </div>
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {
      error: getMembershipError(state.bibleBookCategoryReducer),
      // book_categories: getBibleBookCats(state.bibleBookCategoryReducer),
      books: getCollectedBibles(state.membershipReducer),
      pending: getMembershipPending(state.bibleBookCategoryReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getCollectedBibles: getCollectedBiblesAction,
    collectBible: collectBibleAction
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectedBibles));

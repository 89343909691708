import { getBibleChainByVersePending, getBibleChainByVerseSuccess, getBibleChainByVerseError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function getBibleChainByVerse(verse_id, locale) {


    console.log(verse_id);
    console.log(locale);

    let api = APP_DOMAIN + "/getChainReferenceToVerseByVerseId/" + verse_id + "/" + locale;
    console.log(api);

    return dispatch => {
        dispatch(getBibleChainByVersePending());

        fetch(api, SecurityHeader())
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(getBibleChainByVerseSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(getBibleChainByVerseError(error));
        })
    }
}
import { getBibleBookNameAndChaptersPending, getBibleBookNameAndChaptersSuccess, getBibleBookNameAndChaptersError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function getBibleNamesWithChapters($locale) {

    let api = APP_DOMAIN+"/getBookNamesWithNoOfChapters";

    return dispatch => {
        dispatch(getBibleBookNameAndChaptersPending());

        fetch(api, SecurityHeader({
            locale: $locale
        }))
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(getBibleBookNameAndChaptersSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(getBibleBookNameAndChaptersError(error));
        })

    }
}
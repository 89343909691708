import * as Action from '../action';

const initialState = {
    pending: false,
    static_content: [],
    error: null
}

export default function staticContentReducer(state = initialState, action) {
    switch(action.type) {
        case Action.GET_STATIC_CONTENT_PENDING: 
            return {
                ...state,
                pending: true
            }
        case Action.GET_STATIC_CONTENT_SUCCESS:
            return {
                ...state,
                pending: false,
                static_content: action.res.web_content_locale_elt
            }
        case Action.GET_STATIC_CONTENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default: 
            return state;
    }
}

export const getStaticContent = state => state.static_content;
export const getStaticContentPending = state => state.pending;
export const getStaticContentError = state => state.error;






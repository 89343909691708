import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import './Resource.css'; 
import { RESOURCE_URL } from "../../constant/Constant";

// import VideoItem from './VideoItem';
import Header from '../common/Header';
import logo from '../../images/logo.png';
import banner from '../../images/banner.jpg';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import StartPlanPopup from './startPlanPopup';
import ShouldReceiveNotificationPopup from './receiveNotificationPopup';
import PlanItem from './PlanItem';

import getBiblePlansAction from '../../redux/api/getPlansAPI';
import getMyPlansAction from '../../redux/api/getMyPlansAPI';
import registerPlanAction from '../../redux/api/registerPlanAPI';
import getPlanDetailsAction from '../../redux/api/getPlanDetailsAPI';
import updatePlanNotificationAction from '../../redux/api/updatePlanNotificationAPI';
import updatePlanProgressAction from '../../redux/api/updateProgressAPI';
import removeRegisteredPlanAction from '../../redux/api/removeRegisteredPlanAPI';

import CustomizedDialogs from '../common/dialog.js';

import { getValue, getLocalLocale, getLocalPlanNameKey, getLocalPlanDescKey } from "../../constant/Locale";

import { getBiblePlans, getMyPlans, getPlanDetails } from '../../redux/reducer/membershipReducer';

import "./PlanPreview.css"

import Carousel from 'nuka-carousel';
import BookItem from '../home/BookItem';
import SettingsIcon from '@material-ui/icons/Settings';
import PlanSettingPopup from "./planSettingPopup";

import * as qs from 'query-string';

class PlanPreview extends Component {


  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      activeCategory: 0,
      sortDate: 0,
      sortName: 0,
      shouldShowDialog: false,
      dialogKey: '',
      currentShowing: 'biblePlans',
      dayIndex: 0
    };
  }

  componentWillMount() {
    
  }

  componentDidMount() {
    const parsed = qs.parse(window.location.search);
    var pid = parsed.pid;
    if( pid ) {
      this.props.getPlanDetails( pid );  
    }

    let user = JSON.parse(localStorage.getItem('userInfo'));

    if (! user) {
      window.location.href = "/?q=login";
    } else {
      if (!sessionStorage.getItem('userInfo')) {
        sessionStorage.setItem('userInfo',localStorage.getItem('userInfo'));
        window.location.reload(false);
      }
    }

    var index = parsed.index;
    if (index) {
      this.setState({ dayIndex: index });
    }
    
  }


  bibleCategoryTabClickEvent($cat_id) {
    this.setState({
      // selectedCategoryId: $cat_id
    })
  }

  redirectPlan() {
    setTimeout(
      function() {
        window.location.href = "/discoverPlans";
      }, 1000
    );
  }

  removeRegisteredPlan(plan, selectedDate) {
    this.props.removeRegisteredPlan(plan['planId'],this.redirectPlan());
    
  }

  handleNotificationChange() {
    if( this.props.planDetails["plan"] ) {
      var planId = this.props.planDetails["plan"][0]['planId'];
      this.props.updatePlanNotification(planId);
      this.props.getPlanDetails( planId );
    }
  }

  dialogContent() {
    const parsed = qs.parse(window.location.search);
    var index = parsed.index;
    if( !index )
      index = 1;

    return (
      <PlanSettingPopup 
        coverIdx={index}
        planName={ this.props.planDetails["plan"] ? this.props.planDetails["plan"][0][getLocalPlanNameKey()] : "" }
        planDesc={ this.props.planDetails["plan"] ? this.props.planDetails["plan"][0][getLocalPlanDescKey()] : "" }
        startDate={ this.props.planDetails["plan"] ? this.props.planDetails["plan"][0]['startDate'] : "" }
        numOfDays={ this.props.planDetails["plan"] ? this.props.planDetails["plan"][0]['numOfDays'] : "" }
        handleNotificationChange={() => this.handleNotificationChange()}
        notificationStatus={ this.props.planDetails["plan"] ? this.props.planDetails["plan"][0]['requireNotification'] : ""  }
        close={() => this.setState({shouldShowDialog: false})}
        plan={this.props.planDetails["plan"] ? this.props.planDetails["plan"][0] : null }
        createPlan={(plan, selectedDate) => this.removeRegisteredPlan(plan, selectedDate)}
      />
    );
  }

  dateStr(startDate, index) {
    var d = new Date(Date.parse(startDate));
     d.setDate(d.getDate()+index);
     var localeDateStr = d.toLocaleDateString("en-US");
     var dateStr = localeDateStr.split("/")[1] + "/" +localeDateStr.split("/")[0];
     return dateStr;
  }

  hasPassedToday(startDate, index) {
    var d = new Date(Date.parse(startDate));
    d.setDate(d.getDate()+index);
    var today = new Date();
    var todayLocaleDateStr = today.toLocaleDateString("en-US");
    var t = new Date(todayLocaleDateStr);
    var diffDays = parseInt((d - t) / (1000 * 60 * 60 * 24), 10); 
    return (diffDays > 0);
  }

  loadBibleOfDay(index) {
    if( this.props.planDetails["plan"] ) {
      var startDate = this.props.planDetails["plan"][0]["startDate"];
      if( !this.hasPassedToday(startDate, index) ) {
        this.setState({
          dayIndex: index
        })
      }
    }
  }

  generateDays() {
    var dayArr = Array();
    if( this.props.planDetails["plan"] ) {
      var numOfDays = this.props.planDetails["plan"][0]["numOfDays"];
      var startDate = this.props.planDetails["plan"][0]["startDate"];

      var today = new Date();
      var todayLocaleDateStr = today.toLocaleDateString("en-US");
      var todayStr = todayLocaleDateStr.split("/")[1] + "/" +todayLocaleDateStr.split("/")[0];
      var reachToday = false;


      var rows = new Array(numOfDays).fill(0).map( ( zero, index ) => {
        dayArr.push(
          <div className={ todayStr == this.dateStr(startDate, index) ? "dateBox today" :
                           this.hasPassedToday(startDate, index) ? "dateBox future" : "dateBox" } 
                           onClick={() => this.loadBibleOfDay(index)}>
            <p>
              <span className="date">{this.dateStr(startDate, index) }</span> <br />
              第{index+1}天
            </p>
          </div>
        );
        
      });
      
    }
    return dayArr;
  }

  getRemainingDays() {
    if( this.props.planDetails["plan"] ) {
      var startDate = this.props.planDetails["plan"][0]["startDate"];
      var numOfDays = this.props.planDetails["plan"][0]["numOfDays"];
      var d = new Date(Date.parse(startDate));

      var today = new Date();
      var todayLocaleDateStr = today.toLocaleDateString("en-US");
      var t = new Date(todayLocaleDateStr);

      var diffDays = parseInt((t - d) / (1000 * 60 * 60 * 24), 10); 

      if( t - d >= 0 ) {
        return numOfDays - (diffDays + 1);
      }
      else if( d.toLocaleDateString("en-US") == todayLocaleDateStr ) {
        return numOfDays - 1;
      }

    }
  }

  getHeaderClass() {
    const parsed = qs.parse(window.location.search);
    var index = parsed.index;
    if( !index )
      index = 1;

    return "header banner" + index;
  }

  getOverallProgress() {
    if( this.props.planDetails['plan'] ) {
      var progress = this.props.planDetails['plan'][0]['progress'];
      var progressArr = progress.split(",");

      var numOfOnes = 0;
      for( var i=0; i<progressArr.length; i++ ) {
        if( parseInt(progressArr[i]) == 1 ) {
          numOfOnes++;
        }
      }

      var result = numOfOnes / progressArr.length;

      return result.toFixed(0) + "%";
    }
  }

  isChapterRead( chapterIndex ) {
    var currentDay = this.state.dayIndex;
    var noOfChapters = 0;
    for( var i=0; i<currentDay; i++ ) {
      var day = this.props.planDetails["details"][i];
      noOfChapters += parseInt(day['noOfChapters']);
    }

    var progress = this.props.planDetails['plan'][0]['progress'];
    var progressArr = progress.split(",");

    if( parseInt(progressArr[noOfChapters + chapterIndex]) == 1 ) {
      return true;
    }
    else {
      return false;
    }
  }

  handleCheckboxChange(chapterIndex) {
    var currentDay = this.state.dayIndex;
    var noOfChapters = 0;
    for( var i=0; i<currentDay; i++ ) {
      var day = this.props.planDetails["details"][i];
      noOfChapters += parseInt(day['noOfChapters']);
    }

    //call api
    this.props.updatePlanProgress( this.props.planDetails['plan'][0]['planId'], noOfChapters + chapterIndex);
    this.props.getPlanDetails( this.props.planDetails['plan'][0]['planId'] ); 
  }

  handleSelectCheckbox(chapterIndex) {
    var currentDay = this.state.dayIndex;
    var noOfChapters = 0;
    for( var i=0; i<currentDay; i++ ) {
      var day = this.props.planDetails["details"][i];
      noOfChapters += parseInt(day['noOfChapters']);
    }

    //call api
    this.props.updatePlanProgress( this.props.planDetails['plan'][0]['planId'], noOfChapters + chapterIndex, true);
  }

  getBookItems() {
    var bookItems = Array();
    var index = this.state.dayIndex;
    if( this.props.planDetails["details"] ) {
      var day = this.props.planDetails["details"][index];
      var noOfChapters = day['noOfChapters'];

      for( var i=0; i<noOfChapters; i++ ) {
        bookItems.push(
          <BookItem 
            bookId={ day["bookIDs"].split(",")[i] }
            bookName={ day["bookNames"].split("|")[i] }
            verse={ day["firstVerses"].split("|")[i] }
            hasCollected={false}
            chapter={ day["chapters"].split(",")[i] }
            // toggleCollection={(bookId, shouldCollect) => this.toggleCollection(bookId, shouldCollect)}
            shouldShowCheckbox={true}
            shouldChecked={ this.isChapterRead(i) }
            index={i}
            shouldShowCollectBtn={false}
            handleCheckboxChange={(chapterIndex) => this.handleCheckboxChange(chapterIndex)}
            handleSelectCheckbox={(chapterIndex) => this.handleSelectCheckbox(chapterIndex)}
          />
        );
      }
    }
    return bookItems;
  }

  render() {

    const {getBiblePlans} = this.props;

    return (
      <div>

        <Header />

        <CustomizedDialogs 
            // shouldShow={this.state.shouldShowLoginRegisterDialog && sessionStorage.getItem('userInfo') == null } 
            shouldShow={ this.state.shouldShowDialog }

            mapTitle={""}
            dialogContent={
              this.dialogContent()
            } 
            closeDialog={() => this.setState({shouldShowDialog: false}) }
          />

          <div className="container">
            <div className={this.getHeaderClass()}></div>
            <div className="overlay"></div>
            <div className="remainingDays">
              <p>{getValue("remaining")}<span>{this.getRemainingDays()}</span>天</p>
            </div>
            <div className="progress">
              {this.getOverallProgress()}
            </div>
            <div className="planName">
              <p>{getValue("readPlan")}: {this.props.planDetails["plan"] ? this.props.planDetails["plan"][0][getLocalPlanNameKey()] : ""}</p>
            </div>
            <SettingsIcon className="settingIcon" fontSize="large" onClick={() => this.setState({shouldShowDialog: true})} />
          </div>
          <div className="dayContainer container" style={{ marginTop: '20px' }}>
              {this.generateDays()}
          </div>

          <div className="planBookContainer">
            {this.getBookItems()}
          </div>
          

          <div >

          </div>

          <div className="separator">
          </div>
        
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  console.log(state);
  return {
      // error: getResourceCatsError(state.resourceCategoryReducer),
      // resource_categories: getResourceCats(state.resourceCategoryReducer),
      // pending: getResourceCatsPending(state.resourceCategoryReducer)
      // biblePlans: getBiblePlans(state.membershipReducer),
      // myBiblePlans: getMyPlans(state.membershipReducer)
      planDetails: getPlanDetails(state.membershipReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    // getBiblePlans: getBiblePlansAction,
    // getMyPlans: getMyPlansAction,
    // registerPlan: registerPlanAction
    getPlanDetails: getPlanDetailsAction,
    updatePlanNotification: updatePlanNotificationAction,
    updatePlanProgress: updatePlanProgressAction,
    removeRegisteredPlan: removeRegisteredPlanAction

}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PlanPreview));

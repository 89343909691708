import { getLandingResourcePending, getLandingResourceSuccess, getLandingResourceError } from '../action';
import { APP_DOMAIN } from "../../constant/Constant";
import { SecurityHeader } from "../../constant/Security";

export default function getLandingResources($locale) {


    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    var token = "null";
    if (user) {
        token = user.token;
    }
    let api = APP_DOMAIN+"/getLandingResources/"+$locale+"/"+token;



    return dispatch => {
        dispatch(getLandingResourcePending());

        fetch(api, SecurityHeader())
        .then(res => {
            return res.json()
        })
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            dispatch(getLandingResourceSuccess(res));
            return res;
        })
        .catch(error => {
            dispatch(getLandingResourceError(error));
        })
    }

    
}

